import { useMemo } from "react";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import { useQueryBuilder } from "../components/grid/component/useQueryBuilder";
import useResolvedRoute from "../components/routing/useResolvedRoute";
import { SubListProps } from "../components/grid/SubList";
import { OrganicMaterialProductionSummary } from "./organicMaterialProductionTypes";
import { defaultOrganicMaterialProductionSortOption } from "./useOrganicMaterialProductionListView";
import { GridColumn } from "../components/grid/component/Grid";
import { buildBooleanColumn, buildNumberColumn, buildTextColumn } from "../components/grid/ColumnProvider";
import useAddOrganicMaterialProduction from "./useAddOrganicMaterialProduction";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

const columns: GridColumn<OrganicMaterialProductionSummary>[] = [
    buildTextColumn({ key: "systemId", title: "organicMaterialProduction.list.systemId_column", minWidth: 134 }),
    buildTextColumn({
        key: "certifiedOrganicMaterialName",
        title: "organicMaterialProduction.list.certifiedOrganicMaterial_column",
        minWidth: 250,
    }),
    buildTextColumn({ key: "harvestYear", title: "organicMaterialProduction.list.harvestYear_column", minWidth: 160 }),
    buildTextColumn({
        key: "town",
        title: "organicMaterialProduction.list.town_column",
        minWidth: 100,
    }),
    buildNumberColumn({
        key: "sizeOfProductionArea",
        title: "organicMaterialProduction.list.sizeOfProductionArea_column",
        minWidth: 130,
        fixedDecimal: true,
    }),
    buildNumberColumn({
        key: "productionEstimate",
        title: "organicMaterialProduction.list.productionEstimate_column",
        minWidth: 120,
        fixedDecimal: true,
    }),
    buildNumberColumn({
        key: "productionSold",
        title: "organicMaterialProduction.list.productionSold_column",
        minWidth: 120,
        fixedDecimal: true,
    }),
    buildBooleanColumn({
        key: "usdaNopCompliant",
        title: "organicMaterialProduction.list.usdaNopCompliant_column",
        minWidth: 221,
        visible: false,
    }),
    buildTextColumn({
        key: "postCode",
        title: "organicMaterialProduction.list.postCode_column",
        minWidth: 130,
        visible: false,
    }),
    buildTextColumn({
        key: "stateProvinceName",
        title: "organicMaterialProduction.list.stateProvince_column",
        minWidth: 170,
        visible: false,
    }),
    buildTextColumn({
        key: "addressDetails1",
        title: "organicMaterialProduction.list.addressDetails1_column",
        minWidth: 180,
        visible: false,
    }),
    buildTextColumn({
        key: "addressDetails2",
        title: "organicMaterialProduction.list.addressDetails2_column",
        minWidth: 180,
        visible: false,
    }),
    buildTextColumn({
        key: "addressDetails3",
        title: "organicMaterialProduction.list.addressDetails3_column",
        minWidth: 180,
        visible: false,
    }),
];

export default function useOrganicMaterialProductionSubList(readOnly: boolean, organicMaterialProducerId?: string) {
    const { getResolvedId, getResolvedEditUrl } = useResolvedRoute();
    const organicMaterialProductionScId = getResolvedId("organicMaterialProductionSc");

    const { query, columnOptions, forceReload } = useQueryBuilder<OrganicMaterialProductionSummary>(
        `/certificate-service/organic-material-productions`,
        {
            sort: defaultOrganicMaterialProductionSortOption,
            filterActive: false,
            organicMaterialProductionScId: organicMaterialProductionScId,
        },
    );

    const showDetailsAction = useShowDetailsAction(
        (item: OrganicMaterialProductionSummary) => getResolvedEditUrl("organicMaterialProduction", item.id),
        query.parameter,
    );

    const deleteAction = useDeleteAction(
        (item: OrganicMaterialProductionSummary) =>
            `/certificate-service/organic-material-production-scs/${organicMaterialProductionScId}/omcrTo/${item.id}`,
        forceReload,
        "updateOperationLevelOrganisation",
    );

    const itemActions = useGridRowContextMenuActions(showDetailsAction, readOnly ? undefined : deleteAction);

    const onAddListItem = useAddOrganicMaterialProduction();

    return useMemo<UseOrganicMaterialProductionSubListProps>(
        () => ({
            query,
            columns: columns,
            uniqueDataKey: "id",
            onItemDoubleClick: showDetailsAction,
            header: "organicMaterialProduction.list.header",
            itemActions,
            columnOptions,
            tableName: "organicMaterialProductionScOrganicMaterialProduction",
            onAddListItem:
                !readOnly && organicMaterialProducerId
                    ? () => onAddListItem(organicMaterialProducerId, forceReload)
                    : undefined,
        }),
        [
            columnOptions,
            forceReload,
            readOnly,
            itemActions,
            onAddListItem,
            organicMaterialProducerId,
            query,
            showDetailsAction,
        ],
    );
}

export interface UseOrganicMaterialProductionSubListProps extends SubListProps<OrganicMaterialProductionSummary> {
    onAddListItem?: () => void;
}
