import useExternalIdEdit from "../../details/useExternalIdEdit";
import useResolvedRoute from "../../../components/routing/useResolvedRoute";

export default function useExternalFacilityIdEdit() {
    const { getResolvedId } = useResolvedRoute();


    return useExternalIdEdit({
        i18nKey: "externalFacilityId",
        routeEnumKey: getResolvedId("externalFacilityId")
            ? "externalFacilityId"
            : "facilityExternalFacilityId",
        parentType: "FACILITY",
        parentId: getResolvedId("facility"),
    });
}
