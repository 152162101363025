import { SortOption } from "../components/grid/component/useQueryBuilder";
import { OrganicMaterialProductionDetails, OrganicMaterialProductionSummary } from "./organicMaterialProductionTypes";
import useListView from "../hooks/useListView";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import useResolvedRoute from "../components/routing/useResolvedRoute";
import { useMemo } from "react";
import { EntityListViewGridProps } from "../components/entity/EntityListView";
import useIsAuthorised from "../auth/useIsAuthorised";
import { GridColumn } from "../components/grid/component/Grid";
import { buildBooleanColumn, buildNumberColumn, buildTextColumn } from "../components/grid/ColumnProvider";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

export const defaultOrganicMaterialProductionSortOption: SortOption<
    OrganicMaterialProductionSummary | OrganicMaterialProductionDetails
> = {
    field: "systemId",
    direction: "ASC",
};

const columns: GridColumn<OrganicMaterialProductionSummary>[] = [
    buildTextColumn({ key: "systemId", title: "organicMaterialProduction.list.systemId_column", minWidth: 134 }),
    buildTextColumn({
        key: "organicMaterialProducerBusinessName",
        title: "organicMaterialProduction.list.organicMaterialProducer_column",
        minWidth: 257,
    }),
    buildTextColumn({
        key: "certifiedOrganicMaterialName",
        title: "organicMaterialProduction.list.certifiedOrganicMaterial_column",
        minWidth: 249,
    }),
    buildTextColumn({ key: "harvestYear", title: "organicMaterialProduction.list.harvestYear_column", minWidth: 153 }),
    buildTextColumn({
        key: "town",
        title: "organicMaterialProduction.list.town_column",
        minWidth: 124,
    }),
    buildNumberColumn({
        key: "sizeOfProductionArea",
        title: "organicMaterialProduction.list.sizeOfProductionArea_column",
        minWidth: 140,
        fixedDecimal: true,
    }),
    buildNumberColumn({
        key: "productionEstimate",
        title: "organicMaterialProduction.list.productionEstimate_column",
        minWidth: 140,
        fixedDecimal: true,
    }),
    buildNumberColumn({
        key: "productionSold",
        title: "organicMaterialProduction.list.productionSold_column",
        minWidth: 140,
        fixedDecimal: true,
    }),
    buildBooleanColumn({
        key: "usdaNopCompliant",
        title: "organicMaterialProduction.list.usdaNopCompliant_column",
        minWidth: 221,
        visible: false,
    }),
    buildTextColumn({
        key: "postCode",
        title: "organicMaterialProduction.list.postCode_column",
        minWidth: 128,
        visible: false,
    }),
    buildTextColumn({
        key: "stateProvinceName",
        title: "organicMaterialProduction.list.stateProvince_column",
        minWidth: 167,
        visible: false,
    }),
    buildTextColumn({
        key: "addressDetails1",
        title: "organicMaterialProduction.list.addressDetails1_column",
        minWidth: 180,
        visible: false,
    }),
    buildTextColumn({
        key: "addressDetails2",
        title: "organicMaterialProduction.list.addressDetails2_column",
        minWidth: 180,
        visible: false,
    }),
    buildTextColumn({
        key: "addressDetails3",
        title: "organicMaterialProduction.list.addressDetails3_column",
        minWidth: 180,
        visible: false,
    }),
    buildTextColumn({
        key: "stateProvinceCountryAreaName",
        title: "organicMaterialProduction.list.countryArea_column",
        minWidth: 155,
        visible: false,
    }),
];

export default function useOrganicMaterialProductionListView() {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedEditUrl } = useResolvedRoute();

    const {
        queryBuilder: { forceReload, columnOptions, query, queryCommands },
    } = useListView<OrganicMaterialProductionSummary>({
        navigationContextLabel: "organicMaterialProduction.breadcrumb",
        baseQuery: "/certificate-service/organic-material-productions",
        defaultSortOption: defaultOrganicMaterialProductionSortOption,
    });

    const showDetailsAction = useShowDetailsAction(
        (item: OrganicMaterialProductionSummary) => getResolvedEditUrl("organicMaterialProduction", item.id),
        query.parameter,
    );

    const deleteAction = useDeleteAction(
        (item: OrganicMaterialProductionSummary) => `/certificate-service/organic-material-productions/${item.id}`,
        () => forceReload(),
        "updateOperationLevelOrganisation",
    );

    const actions = useGridRowContextMenuActions(showDetailsAction, deleteAction);

    return useMemo<EntityListViewGridProps<OrganicMaterialProductionSummary>>(() => {
        return {
            query,
            columns: columns,
            uniqueDataKey: "id",
            onItemDoubleClick: showDetailsAction,
            tableName: "organicMaterialProduction",
            columnOptions,
            itemActions: actions,
            queryCommands,
            bulkDeleteAccessRight: () => hasAccessRight("updateOperationLevelOrganisation"),
        };
    }, [query, showDetailsAction, hasAccessRight, columnOptions, actions, queryCommands]);
}
