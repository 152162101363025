import useResolvedRoute from "../components/routing/useResolvedRoute";
import { buildTextColumn } from "../components/grid/ColumnProvider";
import useBuyingRelationSubList from "./useBuyingRelationSubList";

export default function useSellersSubList(readOnly: boolean) {
    const { getResolvedId } = useResolvedRoute();

    const firstColumn = buildTextColumn({
        key: "sellerSupplyChainOperatorBusinessName",
        title: "buyingRelation.list.seller_column",
        minWidth: 124,
    });

    const { ...sellersListOptions } = useBuyingRelationSubList({
        firstColumn,
        baseQueryParamKey: "buyerSupplyChainOperatorId",
        baseQueryParamValue: getResolvedId("supplyChainOperator"),
        header: "buyingRelation.list.sellers_header",
        tableName: "sellers",
        readOnly,
    });

    return sellersListOptions;
}
