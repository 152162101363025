import { buildTextColumn } from "../components/grid/ColumnProvider";
import { LabelGradeDetails, LabelGradeSummary } from "./labelGradeTypes";
import { useMemo } from "react";
import useIsAuthorised from "../auth/useIsAuthorised";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import { SortOption, useQueryBuilder } from "../components/grid/component/useQueryBuilder";
import { GridColumn } from "../components/grid/component/Grid";
import useResolvedRoute from "../components/routing/useResolvedRoute";
import { SubListProps } from "../components/grid/SubList";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

export const defaultLabelGradeSortOption: SortOption<LabelGradeSummary | LabelGradeDetails> = {
    field: "labelGrade",
    direction: "ASC",
};

const columns: GridColumn<LabelGradeSummary>[] = [
    buildTextColumn({ key: "labelGrade", title: "labelGrade.list.labelGrade_column", minWidth: 146 }),
    buildTextColumn({ key: "systemId", title: "labelGrade.list.systemId_column", minWidth: 134 }),
    buildTextColumn({ key: "typeOfMaterial", title: "labelGrade.list.typeOfMaterial_column", minWidth: 311 }),
    buildTextColumn({ key: "minPercentage", title: "labelGrade.list.minPercentage_column", minWidth: 129 }),
];

export default function useLabelGradeSubList() {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedEditUrl, getResolvedCreateUrl, getResolvedId } = useResolvedRoute();
    const standardId = getResolvedId("standard");
    const standardVersionId = getResolvedId("standardVersion");

    const { query, columnOptions, forceReload } = useQueryBuilder<LabelGradeSummary>(
        `/certificate-service/standards/${standardId}/standard-versions/${standardVersionId}/label-grades`,
        {
            sort: defaultLabelGradeSortOption,
            filterActive: false,
        },
    );

    const showDetailsAction = useShowDetailsAction(
        (item: LabelGradeSummary) => getResolvedEditUrl("labelGrade", item.id),
        query.parameter,
    );

    const deleteAction = useDeleteAction(
        (item: LabelGradeSummary) =>
            `/certificate-service/standards/${standardId}/standard-versions/${standardVersionId}/label-grades/${item.id}`,
        () => forceReload(),
        "updateMasterData",
    );

    const contextMenuActions = useGridRowContextMenuActions(showDetailsAction, deleteAction);

    return useMemo<SubListProps<LabelGradeSummary>>(
        () => ({
            query,
            columns,
            columnOptions,
            uniqueDataKey: "id",
            onItemDoubleClick: showDetailsAction,
            header: "labelGrade.list.header",
            itemActions: contextMenuActions,
            createUrl: hasAccessRight("updateMasterData") ? getResolvedCreateUrl("labelGrade") : undefined,
            tableName: "labelGrades",
        }),
        [query, columnOptions, showDetailsAction, contextMenuActions, hasAccessRight, getResolvedCreateUrl],
    );
}
