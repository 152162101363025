import { joinClassNames } from "../../utils/StringUtils";
import "./supplyChainOperatorFacilities.css";
import { SupplyChainOperatorDetailViewTabProps } from "./SupplyChainOperatorDetailView";
import InfinityScrollSubList from "../../components/grid/InfinityScrollSubList";
import useFacilitySubList from "../../facility/useFacilitySubList";
import FacilityPickList from "../../components/Input/pickList/typedPicklist/FacilityPickList";

export default function SupplyChainOperatorFacilities({
                                                          form,
                                                          readOnlyProps,
                                                          supplyChainOperatorId,
                                                      }: SupplyChainOperatorDetailViewTabProps) {
    const facilityListOptions = useFacilitySubList(readOnlyProps.isSubEntityReadOnly);

    return (
        <div className={joinClassNames("supplyChainOperatorFacilities", "formGrid")}>
            <FacilityPickList
                control={form.form.control}
                readOnly={readOnlyProps.isMainFacilityReadOnly}
                className="mainFacility"
                name="mainFacility"
                label="supplyChainOperator.detail.mainFacility_input"
                supplyChainOperatorId={supplyChainOperatorId}
            />
            <InfinityScrollSubList {...facilityListOptions} className="facilities" />
        </div>
    );
}
