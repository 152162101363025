import useExternalIdEdit from "../../details/useExternalIdEdit";
import useResolvedRoute from "../../../components/routing/useResolvedRoute";

export default function useExternalOrganicMaterialProducerIdEdit() {
    const { getResolvedId } = useResolvedRoute();

    return useExternalIdEdit({
        i18nKey: "externalOrganicMaterialProducerId",
        routeEnumKey: getResolvedId("externalOrganicMaterialProducerId")
            ? "externalOrganicMaterialProducerId"
            : "organicMaterialProducerExternalOrganicMaterialProducerId",
        parentType: "ORGANIC_MATERIAL_PRODUCER",
        parentId: getResolvedId("organicMaterialProducer"),
    });
}
