import { useMemo } from "react";
import { GridColumn } from "../components/grid/component/Grid";
import useListView from "../hooks/useListView";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import useResolvedRoute, { getCreateUrl, routeEnum } from "../components/routing/useResolvedRoute";
import { I18nKey } from "../i18n/useGtbTranslation";
import { EntityListViewGridProps } from "../components/entity/EntityListView";
import { ExternalIdDetails, ExternalIdSummary, typeOfExternalIdParentEntity } from "./externalIdTypes";
import { SortOption } from "../components/grid/component/useQueryBuilder";
import { buildDataColumn, buildTextColumn } from "../components/grid/ColumnProvider";
import useIsAuthorised from "../auth/useIsAuthorised";
import { EnumEntityType } from "../components/Input/pickList/typedEnumPicklist/enumEntityType";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

export const defaultExternalIdSortOption: SortOption<ExternalIdSummary | ExternalIdDetails> = {
    field: "parentBusinessName",
    direction: "ASC",
};

export default function useExternalIdListView({
                                                  i18nKey,
                                                  routeEnumKey,
                                                  tableName,
                                                  parentEntityType,
                                              }: UseExternalIdListViewProps) {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedEditUrl } = useResolvedRoute();

    const columns = useMemo<GridColumn<ExternalIdSummary>[]>(
        () => [
            buildTextColumn({
                key: "parentBusinessName",
                title: `${i18nKey}.list.businessName_column` as I18nKey,
                minWidth: 174,
            }),
            buildDataColumn<ExternalIdSummary, EnumEntityType>({
                key: "type",
                title: `${i18nKey}.list.type_column` as I18nKey,
                minWidth: 50,
                itemId: "id",
                itemLabel: "name",
                url: "/certificate-service/references/type-of-external-ids",
            }),
            buildTextColumn({
                key: "externalId",
                title: `${i18nKey}.list.externalId_column` as I18nKey,
                minWidth: 124,
            }),
            buildTextColumn({
                key: "certificationBodyBusinessName",
                title: `${i18nKey}.list.certificationBody_column` as I18nKey,
                minWidth: 176,
            }),
        ],
        [i18nKey],
    );

    const {
        queryBuilder: { forceReload, columnOptions, query, queryCommands },
    } = useListView<ExternalIdSummary>({
        navigationContextLabel: `${i18nKey}.breadcrumb` as I18nKey,
        baseQuery: `/certificate-service/external-ids/${parentEntityType}`,
        defaultSortOption: defaultExternalIdSortOption,
    });

    const showDetailsAction = useShowDetailsAction(
        (item) => getResolvedEditUrl(routeEnumKey, item.id),
        query.parameter,
    );

    const deleteAction = useDeleteAction(
        (item: ExternalIdSummary) => `/certificate-service/external-ids/${parentEntityType}/${item.id}`,
        () => forceReload(),
        "updateOperationLevelOrganisation",
    );

    const actions = useGridRowContextMenuActions(showDetailsAction, deleteAction);

    return useMemo<EntityListViewGridProps<ExternalIdSummary>>(() => {
        return {
            query,
            columns,
            uniqueDataKey: "id",
            onItemDoubleClick: showDetailsAction,
            createUrl: hasAccessRight("updateOperationLevelOrganisation") ? getCreateUrl(routeEnumKey) : undefined,
            tableName: tableName,
            columnOptions,
            itemActions: actions,
            queryCommands,
            bulkDeleteAccessRight: () => hasAccessRight("updateOperationLevelOrganisation"),
        };
    }, [
        query,
        columns,
        showDetailsAction,
        hasAccessRight,
        routeEnumKey,
        tableName,
        columnOptions,
        actions,
        queryCommands,
    ]);
}

export interface UseExternalIdListViewProps {
    i18nKey: I18nKey;
    routeEnumKey: routeEnum;
    tableName: string;
    parentEntityType: typeOfExternalIdParentEntity;
}
