import { OrganisationReadOnlyProps } from "../../organisation/details/organisationDetailViewProps";
import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";
import EntityDetailView from "../../components/entity/EntityDetailView";
import Tabs, { TabKey, TabProps } from "../../components/tabs/Tabs";
import { TypedDetailViewProps } from "../../components/detailView/DetailView";
import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import { OrganicMaterialProducerCreateDetails, OrganicMaterialProducerDetails } from "../organicMaterialProducerTypes";
import OrganicMaterialProducerBasicData from "./OrganicMaterialProducerBasicData";
import OrganicMaterialProducerOtherData from "./OrganicMaterialProducerOtherData";
import OrganicMaterialProducerAddressData from "./OrganicMaterialProducerAddressData";
import { externalIdComponentTypeEnum } from "../../externalId/externalIdTypes";
import { useMemo } from "react";
import OrganicMaterialProducerNewRelation from "./OrganicMaterialProducerNewRelation";
import { insertIf } from "../../utils/arrayUtils";
import OrganicMaterialProducerScopeCertificates from "./OrganicMaterialProducerScopeCertificates";

export default function OrganicMaterialProducerDetailView({
                                                              detailViewProps,
                                                              form,
                                                              readOnlyProps,
                                                              typeOfRegistrationId,
                                                              externalIdComponentType,
                                                              isNewRelationTabVisible,
                                                              requiresLegalNameInEnglish,
                                                              subListVisible,
                                                              ...rest
                                                          }: OrganicMaterialProducerDetailViewProps<OrganicMaterialProducerDetails | OrganicMaterialProducerCreateDetails>) {
    useHelpContext("organic-material-producer-details");

    const newRelationTabs = useMemo<OmpTab[]>(
        () => [
            {
                heading: "organicMaterialProducer.detail.newRelation_tab",
                element: <OrganicMaterialProducerNewRelation form={form} readOnlyProps={readOnlyProps} />,
            },
        ],
        [form, readOnlyProps],
    );

    return (
        <EntityDetailView {...rest} detailViewProps={detailViewProps} form={form}>
            <Tabs<OrganicMaterialProducerDetails | OrganicMaterialProducerCreateDetails, OrganicMaterialProducerTabs>
                tabs={[
                    {
                        heading: "organicMaterialProducer.detail.basicData_tab",
                        element: (
                            <OrganicMaterialProducerBasicData
                                form={form}
                                readOnlyProps={readOnlyProps}
                                requiresLegalNameInEnglish={requiresLegalNameInEnglish}
                            />
                        ),
                    },
                    {
                        heading: "organicMaterialProducer.detail.addressData_tab",
                        element: (
                            <OrganicMaterialProducerAddressData
                                form={form}
                                readOnlyProps={readOnlyProps}
                                typeOfRegistrationId={typeOfRegistrationId}
                            />
                        ),
                    },
                    {
                        heading: "organicMaterialProducer.detail.otherData_tab",
                        element: (
                            <OrganicMaterialProducerOtherData
                                form={form}
                                readOnlyProps={readOnlyProps}
                                externalIdComponentType={externalIdComponentType}
                            />
                        ),
                    },

                    ...insertIf<OmpTab>(isNewRelationTabVisible, ...newRelationTabs),
                    ...insertIf<OmpTab>(subListVisible, {
                        heading: "organicMaterialProducer.detail.scopeCertificates_tab",
                        element: <OrganicMaterialProducerScopeCertificates />,
                    }),
                ]}
                errors={form.form.formState.errors}
                errorToTab={{
                    id: "internal",
                    countryArea: "organicMaterialProducer.detail.addressData_tab",
                    stateProvince: "organicMaterialProducer.detail.addressData_tab",
                    systemId: "internal",
                    businessName: "organicMaterialProducer.detail.basicData_tab",
                    website: "organicMaterialProducer.detail.basicData_tab",
                    organisationalEmail: "organicMaterialProducer.detail.basicData_tab",
                    organisationalPhoneNumber: "organicMaterialProducer.detail.basicData_tab",
                    startDate: "organicMaterialProducer.detail.basicData_tab",
                    endDate: "organicMaterialProducer.detail.basicData_tab",
                    legalNameInEnglish: "organicMaterialProducer.detail.basicData_tab",
                    town: "organicMaterialProducer.detail.addressData_tab",
                    postCode: "organicMaterialProducer.detail.addressData_tab",
                    addressDetails1: "organicMaterialProducer.detail.addressData_tab",
                    addressDetails2: "organicMaterialProducer.detail.addressData_tab",
                    addressDetails3: "organicMaterialProducer.detail.addressData_tab",
                    latitude: "organicMaterialProducer.detail.addressData_tab",
                    longitude: "organicMaterialProducer.detail.addressData_tab",
                    organisationStatus: "organicMaterialProducer.detail.basicData_tab",
                    legalName: "organicMaterialProducer.detail.basicData_tab",
                    numberOfFarmers: "organicMaterialProducer.detail.otherData_tab",
                    fixedRegistrationId: "organicMaterialProducer.detail.addressData_tab",
                    cbInternalId: "organicMaterialProducer.detail.otherData_tab",
                    supplyChainOperator: "organicMaterialProducer.detail.newRelation_tab",
                }}
            />
        </EntityDetailView>
    );
}
type OmpTab = TabProps<OrganicMaterialProducerTabs>;

type OrganicMaterialProducerTabs = TabKey<
    | "organicMaterialProducer.detail.basicData_tab"
    | "organicMaterialProducer.detail.addressData_tab"
    | "organicMaterialProducer.detail.otherData_tab"
    | "organicMaterialProducer.detail.newRelation_tab"
    | "organicMaterialProducer.detail.scopeCertificates_tab"
>;

export interface OrganicMaterialProducerDetailViewProps<OrganicMaterialProducer extends OrganicMaterialProducerDetails>
    extends TypedDetailViewProps<OrganicMaterialProducer> {
    readOnlyProps: OrganicMaterialProducerReadOnlyProps;
    typeOfRegistrationId?: string;
    externalIdComponentType: externalIdComponentTypeEnum;
    isNewRelationTabVisible: boolean;
    requiresLegalNameInEnglish: boolean;
    subListVisible: boolean;
}

export interface OrganicMaterialProducerDetailViewTabProps<
    OrganicMaterialProducer extends OrganicMaterialProducerDetails
> {
    form: UseGtbFormReturn<OrganicMaterialProducer>;
    readOnlyProps: OrganicMaterialProducerReadOnlyProps;
    typeOfRegistrationId?: string;
    externalIdComponentType?: externalIdComponentTypeEnum;
    isNewRelationTabVisible?: boolean;
    requiresLegalNameInEnglish?: boolean;
}

export interface OrganicMaterialProducerReadOnlyProps extends OrganisationReadOnlyProps {
    isLegalNameReadOnly: boolean;
    isFixedRegistrationIdReadOnly: boolean;
    isNumberOfFarmersReadOnly: boolean;
    isCbInternalIdReadOnly: boolean;
    isLegalNameInEnglishReadOnly: boolean;
    isSubEntityReadOnly: boolean;
}
