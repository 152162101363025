import { TypedDetailViewProps } from "../../components/detailView/DetailView";
import { AuthorisationRoleDetails } from "../authorisationRoleTypes";
import { viewTypeType } from "../../hooks/details/useDetails";
import AuthorisationRoleBasicData from "./AuthorisationRoleBasicData";
import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";
import EntityDetailView from "../../components/entity/EntityDetailView";
import Tabs, { TabKey } from "../../components/tabs/Tabs";

export default function AuthorisationRoleDetailView({
                                                        detailViewProps,
                                                        isReadOnly,
                                                        viewType,
                                                        form,
                                                        enableOrganisationTypeSelection,
                                                        ...rest
                                                    }: AuthorisationRoleDetailViewProps) {
    useHelpContext("authorisation-role-details");
    return (
        <EntityDetailView {...rest} detailViewProps={detailViewProps} form={form}>
            <Tabs<AuthorisationRoleDetails, TabKey<"authorisationRole.detail.data_tab">>
                tabs={[
                    {
                        heading: "authorisationRole.detail.data_tab",
                        element: (
                            <AuthorisationRoleBasicData
                                form={form}
                                readOnly={isReadOnly}
                                enableOrganisationTypeSelection={enableOrganisationTypeSelection}
                                viewType={viewType}
                            />
                        ),
                    },
                ]}
                errors={form.form.formState.errors}
                errorToTab={{
                    id: "internal",
                    name: "authorisationRole.detail.data_tab",
                    type: "authorisationRole.detail.data_tab",
                    level: "authorisationRole.detail.data_tab",
                    canReduceOtpPrompting: "authorisationRole.detail.data_tab",
                    createUserAccount: "authorisationRole.detail.data_tab",
                    readUserAccount: "authorisationRole.detail.data_tab",
                    updateUserAccount: "authorisationRole.detail.data_tab",
                    deleteUserAccount: "authorisationRole.detail.data_tab",
                    createAuthorisationRole: "authorisationRole.detail.data_tab",
                    readAuthorisationRole: "authorisationRole.detail.data_tab",
                    updateAuthorisationRole: "authorisationRole.detail.data_tab",
                    deleteAuthorisationRole: "authorisationRole.detail.data_tab",
                    createStandardBody: "authorisationRole.detail.data_tab",
                    readStandardBody: "authorisationRole.detail.data_tab",
                    updateStandardBody: "authorisationRole.detail.data_tab",
                    deleteStandardBody: "authorisationRole.detail.data_tab",
                    createCertificationLevelOrganisation: "authorisationRole.detail.data_tab",
                    readCertificationLevelOrganisation: "authorisationRole.detail.data_tab",
                    updateCertificationLevelOrganisation: "authorisationRole.detail.data_tab",
                    deleteCertificationLevelOrganisation: "authorisationRole.detail.data_tab",
                    createOperationLevelOrganisation: "authorisationRole.detail.data_tab",
                    readOperationLevelOrganisation: "authorisationRole.detail.data_tab",
                    updateOperationLevelOrganisation: "authorisationRole.detail.data_tab",
                    deleteOperationLevelOrganisation: "authorisationRole.detail.data_tab",
                    relationTypesInMenuSystem: "authorisationRole.detail.data_tab",
                    createProductConversion: "authorisationRole.detail.data_tab",
                    readProductConversion: "authorisationRole.detail.data_tab",
                    updateProductConversion: "authorisationRole.detail.data_tab",
                    deleteProductConversion: "authorisationRole.detail.data_tab",
                    createMasterData: "authorisationRole.detail.data_tab",
                    readMasterData: "authorisationRole.detail.data_tab",
                    updateMasterData: "authorisationRole.detail.data_tab",
                    deleteMasterData: "authorisationRole.detail.data_tab",
                    createScopeCertificate: "authorisationRole.detail.data_tab",
                    readScopeCertificate: "authorisationRole.detail.data_tab",
                    updateScopeCertificate: "authorisationRole.detail.data_tab",
                    deleteScopeCertificate: "authorisationRole.detail.data_tab",
                    createTransactionCertificate: "authorisationRole.detail.data_tab",
                    readTransactionCertificate: "authorisationRole.detail.data_tab",
                    updateTransactionCertificate: "authorisationRole.detail.data_tab",
                    deleteTransactionCertificate: "authorisationRole.detail.data_tab",
                    createPreGtbTc: "authorisationRole.detail.data_tab",
                    readPreGtbTc: "authorisationRole.detail.data_tab",
                    updatePreGtbTc: "authorisationRole.detail.data_tab",
                    deletePreGtbTc: "authorisationRole.detail.data_tab",
                    dataListsInMenuSystem: "authorisationRole.detail.data_tab",
                    manageEventDetection: "authorisationRole.detail.data_tab",
                    manageSystemParameter: "authorisationRole.detail.data_tab",
                    useBulkReport: "authorisationRole.detail.data_tab",
                    useApplicationLog: "authorisationRole.detail.data_tab",
                    useUploadLog: "authorisationRole.detail.data_tab",
                    viewTiles: "authorisationRole.detail.data_tab",
                    useMailTemplate: "authorisationRole.detail.data_tab",
                    createTimeBasedMail: "authorisationRole.detail.data_tab",
                    readTimeBasedMail: "authorisationRole.detail.data_tab",
                    updateTimeBasedMail: "authorisationRole.detail.data_tab",
                    deleteTimeBasedMail: "authorisationRole.detail.data_tab",
                    grantPriorConsent: "authorisationRole.detail.data_tab",
                }}
            />
        </EntityDetailView>
    );
}

export interface AuthorisationRoleDetailViewProps extends TypedDetailViewProps<AuthorisationRoleDetails> {
    isReadOnly: boolean;
    enableOrganisationTypeSelection: boolean;
    viewType: viewTypeType;
}
