import useDetailsCreate from "../../hooks/details/useDetailsCreate";
import { yupResolver } from "@hookform/resolvers/yup";
import { Nullable } from "../../utils/typeUtils";
import {
    organisationDefaultInitialData,
    useOrganisationDefaultReadOnlyProps,
} from "../../organisation/details/organisationDetailViewProps";
import { OrganicMaterialProducerCreateDetails, OrganicMaterialProducerDetails } from "../organicMaterialProducerTypes";
import { OrganicMaterialProducerDetailViewProps, OrganicMaterialProducerReadOnlyProps } from "./OrganicMaterialProducerDetailView";
import useTypeOfRegistrationId from "../../countryArea/useTypeOfRegistrationId";
import useResolveDuplicates from "../../hooks/details/useResolveDuplicates";
import useAcceptOrganicMaterialProducerDuplicate from "./useAcceptOrganicMaterialProducerDuplicate";
import { useMemo, useState } from "react";
import useAuth from "../../auth/useAuth";
import { validateString } from "../../utils/yupExtension";
import useRequiresLegalNameInEnglish from "../../organisation/useRequiresLegalNameInEnglish";
import { buildOrganicMaterialProducerValidationSchema } from "./organicMaterialProducerValidationSchema";

export default function useOrganicMaterialProducerCreate(): OrganicMaterialProducerDetailViewProps<OrganicMaterialProducerCreateDetails> {
    const {
        currentUser: { authorisationRole, organisation },
    } = useAuth();
    const [requiresLegalNameInEnglish, setRequiresLegalNameInEnglish] = useState(false);

    const initialData = useMemo<Nullable<OrganicMaterialProducerCreateDetails>>(
        () => ({
            ...organisationDefaultInitialData,
            legalName: null,
            businessName: null,
            fixedRegistrationId: null,
            numberOfFarmers: null,
            cbInternalId: null,
            legalNameInEnglish: null,
            supplyChainOperator: authorisationRole?.type === "SUPPLY_CHAIN_OPERATOR" ? organisation : null,
        }),
        [authorisationRole?.type, organisation],
    );

    const acceptDuplicate = useAcceptOrganicMaterialProducerDuplicate();
    const duplicateResolver = useResolveDuplicates<OrganicMaterialProducerDetails>(acceptDuplicate);

    const isNewRelationTabVisible = useMemo(
        () => authorisationRole?.type === "CERTIFICATION_BODY" || authorisationRole?.type === "STANDARD_BODY",
        [authorisationRole?.type],
    );

    const { detailViewProps, form, title } = useDetailsCreate<OrganicMaterialProducerDetails>({
        title: "organicMaterialProducer.detail.newRecord_title",
        frontendRoute: "organicMaterialProducer",
        resolveErrors: duplicateResolver,
        initialData,
        resolver: yupResolver(
            buildOrganicMaterialProducerValidationSchema(requiresLegalNameInEnglish).shape({
                supplyChainOperator: isNewRelationTabVisible ? validateString().isRequired() : validateString(),
            }),
        ),
        saveUrl: "/certificate-service/organic-material-producers",
    });

    const { typeOfRegistrationId, isFixedRegistrationIdReadOnly } = useTypeOfRegistrationId(form, true);

    const defaultReadOnlyProps = useOrganisationDefaultReadOnlyProps(false);

    const readOnlyProps = useMemo<OrganicMaterialProducerReadOnlyProps>(
        () => ({
            ...defaultReadOnlyProps,
            isFixedRegistrationIdReadOnly: isFixedRegistrationIdReadOnly,
            isNumberOfFarmersReadOnly: false,
            isCbInternalIdReadOnly: false,
            isLegalNameInEnglishReadOnly: false,
            isSubEntityReadOnly: true,
        }),
        [defaultReadOnlyProps, isFixedRegistrationIdReadOnly],
    );

    useRequiresLegalNameInEnglish(form, setRequiresLegalNameInEnglish);

    return {
        title,
        detailViewProps,
        form,
        requiresLegalNameInEnglish,
        readOnlyProps,
        createRoute: "organicMaterialProducer",
        typeOfRegistrationId,
        externalIdComponentType: "NONE",
        isNewRelationTabVisible,
        subListVisible: false,
    };
}
