import { FormEventHandler, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatchBeforeLeave, useGtbNavigate } from "../routing/GtbRouter";
import GtbButton from "../GtbButton";
import useGtbTranslation from "../../i18n/useGtbTranslation";
import {
    UseFormAfterSubmitAction,
    UseFormErrorResolver,
    UseFormSubmitCallback,
} from "../../hooks/formHandling/useFormSubmit";
import { FieldValues } from "react-hook-form/dist/types";
import { backendUrlType, useMutation } from "../../hooks/useAxios";
import { HistoryParams } from "../../history/useHistoryDialog";
import useUnsavedChangesDialog from "./useUnsavedChangesDialog";
import useErrorDialog from "../dialog/useErrorDialog";
import { error, info } from "../../utils/notification/notification";
import useResolvedRoute, { routeEnum } from "../routing/useResolvedRoute";

function useDetailView<ItemType extends FieldValues>(
    {
        onSubmit,
        afterSubmitAction,
        isLoading,
        isDirty,
        reloadData,
        resolveErrors,
        cloneUrl,
    }: UseDetailViewProps<ItemType>,
    route?: routeEnum
): UseDetailViewReturn {
    const translation = useGtbTranslation();
    const { setBeforeLeaveAction, unsetBeforeLeaveAction } = useDispatchBeforeLeave();
    const { showDialog, closeDialog } = useErrorDialog();
    const { showDialog: showUnsavedChangesDialog, closeDialog: closeUnsavedChangesDialog } = useUnsavedChangesDialog();
    const navigate = useGtbNavigate(true);
    const { getResolvedEditUrl } = useResolvedRoute();
    const [isCloning, setIsCloning] = useState(false);
    const { runQuery: cloneItem } = useMutation({ url: "", method: "post" });

    const handleSubmit = useCallback(
        (afterSubmit?: UseFormAfterSubmitAction<ItemType>) => {
            if (!(!isLoading && onSubmit)) {
                return;
            }
            onSubmit((item) => afterSubmit?.(item), resolveErrors);
        },
        [isLoading, onSubmit, resolveErrors]
    );

    const _clone = useCallback(() => {
        setIsCloning(true);
        cloneItem({ url: cloneUrl })
            .catch(() => error(translation("error.detailView.internalApplicationError_toast")))
            .then(({ id }) => {
                navigate(getResolvedEditUrl(route!, id));
                info(translation("components.detailView.clonedSuccessfully_toast"));
            })
            .finally(() => setIsCloning(false));
    }, [cloneItem, cloneUrl, getResolvedEditUrl, navigate, route, translation]);

    const clone = useCallback(() => {
        if (!route) {
            return;
        }
        if (isDirty) {
            showUnsavedChangesDialog(
                () => {
                    closeUnsavedChangesDialog();
                    _clone();
                },
                async () => {
                    closeUnsavedChangesDialog();
                    handleSubmit(_clone);
                }
            );
            return;
        }
        _clone();
    }, [_clone, closeUnsavedChangesDialog, handleSubmit, isDirty, route, showUnsavedChangesDialog]);

    const updateBeforeLeaveAction = useCallback(
        () =>
            setBeforeLeaveAction((e) => {
                showUnsavedChangesDialog(
                    () => {
                        closeUnsavedChangesDialog();
                        e.proceedNavigation();
                    },
                    async () => {
                        closeUnsavedChangesDialog();
                        handleSubmit(() => e.proceedNavigation());
                    }
                );
            }),
        [closeUnsavedChangesDialog, handleSubmit, setBeforeLeaveAction, showUnsavedChangesDialog]
    );

    const openConfirmDialogBeforeLeave = useRef(updateBeforeLeaveAction);

    useEffect(() => {
        openConfirmDialogBeforeLeave.current = updateBeforeLeaveAction;
    }, [updateBeforeLeaveAction]);

    useEffect(() => {
        if (isDirty) {
            openConfirmDialogBeforeLeave.current();
        } else if (!isDirty) {
            unsetBeforeLeaveAction();
        }
    }, [isDirty, unsetBeforeLeaveAction]);

    const onFormSubmit = useCallback(() => {
        handleSubmit(afterSubmitAction);
    }, [afterSubmitAction, handleSubmit]);

    const undoChanges = useCallback(() => {
        if (isDirty) {
            showDialog({
                title: "components.detailView.undoChanges_dialogTitle",
                message: "components.detailView.undoChanges_dialogText",
                footer: (
                    <>
                        <GtbButton
                            variant="secondary"
                            onClick={async () => {
                                closeDialog();
                                reloadData();
                            }}
                        >
                            {translation("components.dialog.yes_button")}
                        </GtbButton>
                        <GtbButton onClick={closeDialog}>{translation("components.dialog.no_button")}</GtbButton>
                    </>
                ),
            });
        } else {
            reloadData();
        }
    }, [isDirty, showDialog, translation, closeDialog, reloadData]);
    return useMemo(() => {
        return {
            onSubmit: onSubmit ? onFormSubmit : undefined,
            isLoading: isLoading || isCloning,
            undoChanges,
            clone: route && cloneUrl ? clone : undefined,
        };
    }, [clone, cloneUrl, isCloning, isLoading, onFormSubmit, onSubmit, route, undoChanges]);
}

export default useDetailView;

export interface UseDetailViewProps<ItemType extends FieldValues> {
    onSubmit?: UseFormSubmitCallback<ItemType>;
    afterSubmitAction?: UseFormAfterSubmitAction<ItemType>;
    resolveErrors?: UseFormErrorResolver<ItemType>;
    isLoading: boolean;
    isDirty?: boolean;
    reloadData: () => void;
    noteRootUrl?: backendUrlType;
    goToMapUrl?: string;
    cloneUrl?: backendUrlType;
    historyParams?: HistoryParams;
}

export interface UseDetailViewReturn {
    onSubmit?: FormEventHandler<HTMLFormElement>;
    isLoading?: boolean;
    undoChanges?: () => void;
    clone?: Function;
}
