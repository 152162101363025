import useIsAuthorised from "../auth/useIsAuthorised";
import { SortOption } from "../components/grid/component/useQueryBuilder";
import { useMemo } from "react";
import { GridColumn } from "../components/grid/component/Grid";
import useListView from "../hooks/useListView";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import { buildBooleanColumn, buildDataColumn, buildDateColumn, buildTextColumn } from "../components/grid/ColumnProvider";
import useResolvedRoute, { getCreateUrl } from "../components/routing/useResolvedRoute";
import { CertificationRelationDetails, CertificationRelationSummary } from "./certificationRelationTypes";
import { EntityListViewGridProps } from "../components/entity/EntityListView";
import { EnumEntityType } from "../components/Input/pickList/typedEnumPicklist/enumEntityType";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

export const defaultCertificationRelationSortOption: SortOption<
    CertificationRelationSummary | CertificationRelationDetails
> = {
    field: "certificationBodyBusinessName",
    direction: "ASC",
};

const columns: GridColumn<CertificationRelationSummary>[] = [
    buildTextColumn({
        key: "certificationBodyBusinessName",
        title: "certificationRelation.list.certificationBody_column",
        minWidth: 201,
    }),
    buildTextColumn({
        key: "supplyChainOperatorBusinessName",
        title: "certificationRelation.list.supplyChainOperator_column",
        minWidth: 201,
    }),
    buildTextColumn({
        key: "standardAcronym",
        title: "certificationRelation.list.standard_column",
        minWidth: 126,
    }),
    buildDataColumn<CertificationRelationSummary, EnumEntityType>({
        key: "accessLevel",
        title: "certificationRelation.list.accessLevel_column",
        minWidth: 123,
        url: "/certificate-service/references/certification-relation-access-levels",
        itemId: "id",
        itemLabel: "name",
    }),
    buildTextColumn({
        key: "cbLicenceNumber",
        title: "certificationRelation.list.cbLicenceNumber_column",
        minWidth: 222,
        visible: false,
    }),
    buildTextColumn({
        key: "recertificationEmail",
        title: "certificationRelation.list.recertificationEmail_column",
        minWidth: 222,
        visible: false,
    }),
    buildBooleanColumn({
        key: "certificationBodyCc",
        title: "certificationRelation.list.certificationBodyCc_column",
        minWidth: 50,
        visible: false,
    }),
    buildDateColumn({
        key: "startDate",
        title: "certificationRelation.list.startDate_column",
        minWidth: 130,
        visible: false,
    }),
    buildDateColumn({
        key: "endDate",
        title: "certificationRelation.list.endDate_column",
        minWidth: 124,
        visible: false,
    }),
];

export default function useCertificationRelationListView() {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedEditUrl } = useResolvedRoute();

    const {
        queryBuilder: { forceReload, columnOptions, query, queryCommands },
    } = useListView<CertificationRelationSummary>({
        navigationContextLabel: "certificationRelation.breadcrumb",
        baseQuery: "/certificate-service/certification-relations",
        defaultSortOption: defaultCertificationRelationSortOption,
    });

    const showDetailsAction = useShowDetailsAction(
        (item: CertificationRelationSummary) => getResolvedEditUrl("certificationRelation", item.id),
        query.parameter,
    );

    const deleteAction = useDeleteAction(
        (item: CertificationRelationSummary) => `/certificate-service/certification-relations/${item.id}`,
        () => forceReload(),
        "updateOperationLevelOrganisation",
    );

    const actions = useGridRowContextMenuActions(showDetailsAction, deleteAction);

    return useMemo<EntityListViewGridProps<CertificationRelationSummary>>(() => {
        return {
            query,
            columns,
            uniqueDataKey: "id",
            onItemDoubleClick: showDetailsAction,
            createUrl: hasAccessRight("updateOperationLevelOrganisation")
                ? getCreateUrl("certificationRelation")
                : undefined,
            tableName: "certificationRelation",
            columnOptions,
            itemActions: actions,
            queryCommands,
            bulkDeleteAccessRight: () => hasAccessRight("updateOperationLevelOrganisation"),
        };
    }, [query, showDetailsAction, hasAccessRight, columnOptions, actions, queryCommands]);
}
