import useExternalIdSubList from "../useExternalIdSubList";
import useResolvedRoute from "../../components/routing/useResolvedRoute";

export default function useExternalSupplyChainOperatorIdSubList(isReadOnly: boolean) {
    const { getResolvedId } = useResolvedRoute();
    const supplyChainOperatorId = getResolvedId("supplyChainOperator")!;

    return useExternalIdSubList({
        tableName: "supplyChainOperatorExternalSupplyChainOperatorIds",
        i18nKey: "externalSupplyChainOperatorId",
        routeEnumKey: "supplyChainOperatorExternalSupplyChainOperatorId",
        parentIdParamValue: supplyChainOperatorId,
        parentType: "SUPPLY_CHAIN_OPERATOR",
        isReadOnly,
    });
}
