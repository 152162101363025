import { yupResolver } from "@hookform/resolvers/yup";
import { buyingRelationValidationSchema } from "./buyingRelationValidationSchema";
import useResolvedRoute, { routeEnum } from "../../components/routing/useResolvedRoute";
import { useMemo } from "react";
import useDomainEntityDetailsEdit from "../../hooks/details/useDomainEntityDetailsEdit";
import { BuyingRelationDetails } from "../buyingRelationTypes";
import { defaultBuyingRelationSortOption } from "../useBuyingRelationListView";
import { BuyingRelationReadOnlyProps } from "./BuyingRelationDetailView";
import useIsAuthorised from "../../auth/useIsAuthorised";
import useAuth from "../../auth/useAuth";

export default function useBuyingRelationEdit() {
    const { hasAccessRight } = useIsAuthorised();
    const {
        currentUser: { authorisationRole },
    } = useAuth();
    const { getResolvedId } = useResolvedRoute();
    const buyingRelationId = getResolvedId("buyingRelation");

    const { detailViewProps, form, initialData, isReadOnly, title, recordNavigationProps } =
        useDomainEntityDetailsEdit<BuyingRelationDetails>({
            baseUrl: "/certificate-service/buying-relations/" + buyingRelationId,
            resolver: yupResolver(buyingRelationValidationSchema),
            frontendRoute: "buyingRelation",
            recordNavigationDefaultSort: defaultBuyingRelationSortOption,
            titleBuilder: (item) =>
                `${item.sellerSupplyChainOperatorBusinessName} ${item.buyerSupplyChainOperatorBusinessName}`,
            canSave: item => hasAccessRight("updateOperationLevelOrganisation", [item.buyerStatus, item.sellerStatus], true),
            historyColumns: [
                {
                    identifier: "sellerSupplyChainOperator",
                    title: "buyingRelation.history.seller_column",
                },
                {
                    identifier: "buyerSupplyChainOperator",
                    title: "buyingRelation.history.buyer_column",
                },
                {
                    identifier: "standardAcronym" as keyof BuyingRelationDetails,
                    title: "buyingRelation.history.standard_column",
                },
                {
                    identifier: "accessLevel",
                    title: "buyingRelation.history.accessLevel_column",
                },
                {
                    identifier: "confirmationMethod",
                    title: "buyingRelation.history.confirmationMethod_column",
                },
                {
                    identifier: "startDate",
                    title: "buyingRelation.history.startDate_column",
                },
                {
                    identifier: "endDate",
                    title: "buyingRelation.history.endDate_column",
                },
            ],
        });

    const readOnlyProps: BuyingRelationReadOnlyProps = {
        isAccessLevelReadOnly:
            isReadOnly || !(authorisationRole.level === 2 && authorisationRole.type === "STANDARD_BODY"),
        isConfirmationMethodReadOnly:
            isReadOnly || authorisationRole.level > 3 || authorisationRole.type === "SUPPLY_CHAIN_OPERATOR",
        isEndDateReadOnly:
            isReadOnly || authorisationRole.level > 3 || authorisationRole.type === "SUPPLY_CHAIN_OPERATOR",
        isSellerReadOnly: true,
        isStandardReadOnly: true,
        isStartDateReadOnly:
            isReadOnly ||
            (authorisationRole && authorisationRole.level > 3) ||
            authorisationRole?.type === "SUPPLY_CHAIN_OPERATOR",
        isBuyerReadOnly: isReadOnly || authorisationRole?.type !== "STANDARD_BODY",
    };

    const createRoute = useMemo<routeEnum | undefined>(() => {
        return !isReadOnly ? "buyingRelation" : undefined;
    }, [isReadOnly]);

    return {
        detailViewProps,
        form,
        title,
        initialData,
        readOnlyProps,
        recordNavigationProps,
        createRoute,
    };
}
