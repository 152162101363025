import useResolvedRoute from "../components/routing/useResolvedRoute";
import { buildTextColumn } from "../components/grid/ColumnProvider";
import useBuyingRelationSubList from "./useBuyingRelationSubList";

export default function useBuyersSubList(readOnly: boolean) {
    const { getResolvedId, getResolvedCreateUrl } = useResolvedRoute();

    const firstColumn = buildTextColumn({
        key: "buyerSupplyChainOperatorBusinessName",
        title: "buyingRelation.list.buyer_column",
        minWidth: 166,
    });

    const { ...buyersListOptions } = useBuyingRelationSubList({
        firstColumn,
        baseQueryParamKey: "sellerSupplyChainOperatorId",
        baseQueryParamValue: getResolvedId("supplyChainOperator"),
        header: "buyingRelation.list.buyers_header",
        tableName: "buyers",
        createUrl: getResolvedCreateUrl("supplyChainOperatorBuyingRelation"),
        sortOption: {
            field: "buyerSupplyChainOperatorBusinessName",
            direction: "ASC",
        },
        readOnly,
    });

    return buyersListOptions;
}
