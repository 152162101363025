import useDetailsCreate from "../../hooks/details/useDetailsCreate";
import { yupResolver } from "@hookform/resolvers/yup";
import { Nullable } from "../../utils/typeUtils";
import { ProductCategoryDetails } from "../productCategoryTypes";
import { productCategoryValidationSchema } from "./productCategoryValidationSchema";
import { getInitialTranslatableValue } from "../../i18n/languageTypes";
import { ProductCategoryDetailViewProps } from "./ProductCategoryDetailView";

const initialData: Nullable<ProductCategoryDetails> = {
    code: null,
    id: null,
    name: getInitialTranslatableValue(),
    pickListSequence: null,
    startDate: null,
    endDate: null,
};

export default function useProductCategoryCreate(): ProductCategoryDetailViewProps {
    const { detailViewProps, form, title } = useDetailsCreate<ProductCategoryDetails>({
        title: "productCategory.detail.newRecord_title",
        frontendRoute: "productCategory",
        initialData,
        resolver: yupResolver(productCategoryValidationSchema),
        saveUrl: "/certificate-service/product-categories",
    });

    return {
        title,
        detailViewProps,
        form,
        isReadOnly: false,
        createRoute: "productCategory",
    };
}
