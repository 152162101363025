import { TypedDetailViewProps } from "../../components/detailView/DetailView";
import "../../components/spinner.css";
import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";
import EntityDetailView from "../../components/entity/EntityDetailView";
import Tabs, { TabKey } from "../../components/tabs/Tabs";
import React from "react";
import OrganicMaterialPurchaseBasicData, { OrganicMaterialPurchaseBasicDataProps } from "./OrganicMaterialPurchaseBasicData";
import { OrganicMaterialPurchaseDetails } from "../organicMaterialPurchaseTypes";

export default function OrganicMaterialPurchaseDetailView({
                                                              detailViewProps,
                                                              form,
                                                              readOnly,
                                                              visibility,
                                                              frozen,
                                                              gmoScreeningReportUrl,
                                                              onCertifiedMaterialChange,
                                                              subListVisible,
                                                              refetchInitialVolume,
                                                              initialAvailableQuantityIsSet,
                                                              ...rest
                                                          }: OrganicMaterialPurchaseDetailViewProps) {
    useHelpContext("organic-material-purchase-details");

    return (
        <EntityDetailView {...rest} detailViewProps={detailViewProps} form={form}>
            <Tabs<OrganicMaterialPurchaseDetails, OrganicMaterialPurchaseTabs>
                tabs={[
                    {
                        heading: "organicMaterialPurchase.detail.basicData_tab",
                        element: (
                            <OrganicMaterialPurchaseBasicData
                                form={form}
                                readOnly={readOnly}
                                visibility={visibility}
                                frozen={frozen}
                                gmoScreeningReportUrl={gmoScreeningReportUrl}
                                onCertifiedMaterialChange={onCertifiedMaterialChange}
                                subListVisible={subListVisible}
                                refetchInitialVolume={refetchInitialVolume}
                                initialAvailableQuantityIsSet={initialAvailableQuantityIsSet}
                            />
                        ),
                    },
                ]}
                errors={form.form.formState.errors}
                errorToTab={{
                    id: "internal",
                    systemId: "internal",
                    frozen: "internal",
                    buyerStatus: "internal",
                    buyer: "organicMaterialPurchase.detail.basicData_tab",
                    certifiedOrganicMaterial: "organicMaterialPurchase.detail.basicData_tab",
                    availableQuantity: "organicMaterialPurchase.detail.basicData_tab",
                    initialQuantity: "organicMaterialPurchase.detail.basicData_tab",
                    gmoScreeningReport: "organicMaterialPurchase.detail.basicData_tab",
                    screeningResult: "organicMaterialPurchase.detail.basicData_tab",
                    rejected: "organicMaterialPurchase.detail.basicData_tab",
                    initialAvailableQuantity: "organicMaterialPurchase.detail.basicData_tab",
                }}
            />
        </EntityDetailView>
    );
}

type OrganicMaterialPurchaseTabs = TabKey<"organicMaterialPurchase.detail.basicData_tab">;

export interface OrganicMaterialPurchaseDetailViewProps
    extends TypedDetailViewProps<OrganicMaterialPurchaseDetails>,
        OrganicMaterialPurchaseBasicDataProps {
}
