import { yupResolver } from "@hookform/resolvers/yup";
import useIsAuthorised from "../../auth/useIsAuthorised";
import { ProcessCategoryDetails } from "../processCategoryTypes";
import { processCategoryValidationSchema } from "./processCategoryValidationSchema";
import { defaultProcessCategorySortOption } from "../useProcessCategoryListView";
import useResolvedRoute, { routeEnum } from "../../components/routing/useResolvedRoute";
import { useMemo } from "react";
import useGtbTranslation from "../../i18n/useGtbTranslation";
import useDomainEntityDetailsEdit from "../../hooks/details/useDomainEntityDetailsEdit";
import { buildTranslatedHistoryColumns } from "../../i18n/languageUtils";
import useLanguage from "../../i18n/LanguageContextProvider";
import { ProcessCategoryDetailViewProps } from "./ProcessCategoryDetailView";

export default function useProcessCategoryEdit(): ProcessCategoryDetailViewProps {
    const translation = useGtbTranslation();
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedId } = useResolvedRoute();
    const currentLanguage = useLanguage();
    const processCategoryId = getResolvedId("processCategory");

    const { detailViewProps, form, initialData, isReadOnly, title, recordNavigationProps } =
        useDomainEntityDetailsEdit<ProcessCategoryDetails>({
            baseUrl: "/certificate-service/process-categories/" + processCategoryId,
            frontendRoute: "processCategory",
            recordNavigationDefaultSort: defaultProcessCategorySortOption,
            titleBuilder: (item) => item.name[currentLanguage],
            resolver: yupResolver(processCategoryValidationSchema),
            canSave: hasAccessRight("updateMasterData"),
            historyColumns: [
                { identifier: "code", title: "processCategory.history.code_column" },
                ...buildTranslatedHistoryColumns<ProcessCategoryDetails>("processCategory.history.name_column", "name"),
                {
                    identifier: "pickListSequence",
                    title: "processCategory.history.pickListSequence_column",
                },
                { identifier: "startDate", title: "processCategory.history.startDate_column" },
                { identifier: "endDate", title: "processCategory.history.endDate_column" },
            ],
        });

    const createRoute = useMemo<routeEnum | undefined>(() => {
        return hasAccessRight("createMasterData") ? "processCategory" : undefined;
    }, [hasAccessRight]);

    return {
        detailViewProps,
        form,
        isReadOnly,
        title,
        subtitle: translation({ key: "processCategory.detail.subtitle", options: { code: initialData?.["code"] } }),
        recordNavigationProps,
        createRoute,
    };
}
