import { yupResolver } from "@hookform/resolvers/yup";
import { useMemo } from "react";
import useGtbTranslation, { I18nKey } from "../../i18n/useGtbTranslation";
import { ExternalIdDetails, typeOfExternalIdParentEntity } from "../externalIdTypes";
import useResolvedRoute, { routeEnum } from "../../components/routing/useResolvedRoute";
import useDomainEntityDetailsEdit from "../../hooks/details/useDomainEntityDetailsEdit";
import useIsAuthorised from "../../auth/useIsAuthorised";
import { ExternalIdReadOnlyProps } from "./ExternalIdDetailView";
import { externalIdValidationSchema } from "../externalIdValidationSchema";
import { defaultExternalIdSortOption } from "../useExternalIdListView";
import { TFuncKey } from "i18next";

export default function useExternalIdEdit({
                                              routeEnumKey,
                                              i18nKey,
                                              parentType,
                                              parentId,
                                          }: UseExternalIdEditProps) {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedId } = useResolvedRoute();
    const externalIdId = getResolvedId(routeEnumKey);
    const translation = useGtbTranslation();

    const { detailViewProps, form, initialData, isReadOnly, title, recordNavigationProps } =
        useDomainEntityDetailsEdit<ExternalIdDetails>({
            baseUrl: `/certificate-service/external-ids/${parentType}/${externalIdId}`,
            resolver: yupResolver(externalIdValidationSchema),
            titleBuilder: (item: ExternalIdDetails) => `${item.externalId}`,
            canSave: item => hasAccessRight("updateOperationLevelOrganisation", [item.parentStatus]),
            recordNavigationDefaultSort: defaultExternalIdSortOption,
            recordNavigationDefaultQueryParams: { parentId },
            frontendRoute: routeEnumKey,
            historyColumns: [
                {
                    identifier: "parentBusinessName",
                    title: `${i18nKey}.history.parent_column` as I18nKey,
                },
                {
                    identifier: "type",
                    title: `${i18nKey}.history.type_column` as I18nKey,
                },
                {
                    identifier: "certificationBody",
                    title: `${i18nKey}.history.certificationBody_column` as I18nKey,
                },
                {
                    identifier: "externalId",
                    title: `${i18nKey}.history.externalId_column` as I18nKey,
                },
            ],
        });

    const readOnlyProps: ExternalIdReadOnlyProps = {
        isParentEntityReadOnly: true,
        isTypeReadOnly: true,
        isCertificationBodyReadOnly: true,
        isExternalIdReadOnly: isReadOnly,
    };

    const createRoute = useMemo<routeEnum | undefined>(() => {
        return !isReadOnly ? routeEnumKey : undefined;
    }, [isReadOnly, routeEnumKey]);

    return {
        detailViewProps,
        form,
        title,
        subtitle: translation({
            key: `${i18nKey}.detail.subtitle` as TFuncKey,
            options: { parentBusinessName: initialData?.parentBusinessName },
        }),
        initialData,
        readOnlyProps,
        recordNavigationProps,
        createRoute,
    };
}

export interface UseExternalIdEditProps {
    i18nKey: I18nKey;
    routeEnumKey: routeEnum;
    parentType: typeOfExternalIdParentEntity;
    parentId?: string;
}
