import useScProductDetails from "./useScProductDetails";
import { ScProductDetails } from "../scProductTypes";
import { defaultScProductSortOption } from "../useScProductSubList";
import { ScProductDetailViewProps } from "./ScProductDetailView";
import useIsAuthorised from "../../../../auth/useIsAuthorised";
import { useCallback, useMemo } from "react";
import useDomainEntityDetailsEdit from "../../../../hooks/details/useDomainEntityDetailsEdit";
import useResolvedRoute from "../../../../components/routing/useResolvedRoute";
import { scopeCertificateReadOnlyByStatus } from "../../../scopeCertificateTypes";
import { scopeCertificateStatusType } from "../../../../auth/roleTypes";

export default function useScProductEdit(): ScProductDetailViewProps {
    const { hasAccessRight } = useIsAuthorised();

    const { resolver, scopeCertificateId, labelGradesVisible, labelGradesLoading } = useScProductDetails();
    const { getResolvedId } = useResolvedRoute();
    const scProductId = getResolvedId("scProduct");

    const canSave = useCallback(
        (item: ScProductDetails) => {
            if (item) {
                return (
                    hasAccessRight("updateScopeCertificate", [item.scopeCertificateStatus]) &&
                    !scopeCertificateReadOnlyByStatus(item.scopeCertificateStatus)
                );
            }
            return false;
        },
        [hasAccessRight]
    );

    const { detailViewProps, form, isReadOnly, title, recordNavigationProps, initialData } =
        useDomainEntityDetailsEdit<ScProductDetails>({
            baseUrl: `/certificate-service/scope-certificates/${scopeCertificateId}/sc-products/${scProductId}`,
            frontendRoute: "scProduct",
            recordNavigationDefaultSort: defaultScProductSortOption,
            titleBuilder: (item) => item.scProductNumber,
            resolver,
            canSave,
            historyColumns: [
                { identifier: "scProductNumber", title: "scProduct.history.scProductNumber_column" },
                {
                    identifier: "cbScProductReferenceNumber",
                    title: "scProduct.history.cbScProductReferenceNumber_column",
                },
                { identifier: "productCategory", title: "scProduct.history.productCategory_column" },
                { identifier: "productCategoryPrint", title: "scProduct.history.productCategoryPrint_column" },
                { identifier: "productDetail", title: "scProduct.history.productDetail_column" },
                { identifier: "productDetailPrint", title: "scProduct.history.productDetailPrint_column" },
                { identifier: "labelGrade", title: "scProduct.history.labelGrade_column" },
            ],
        });

    const scopeCertificateStatus = useMemo<scopeCertificateStatusType | undefined>(
        () => initialData?.scopeCertificateStatus,
        [initialData?.scopeCertificateStatus]
    );

    return {
        labelGradesVisible,
        scopeCertificateId,
        detailViewProps: {
            ...detailViewProps,
            cloneUrl: isReadOnly
                ? undefined
                : `/certificate-service/scope-certificates/${scopeCertificateId}/sc-products/${scProductId}/clone`,
            isLoading: detailViewProps.isLoading || labelGradesLoading,
        },
        form,
        readOnly: isReadOnly,
        title,
        recordNavigationProps,
        subListVisible: true,
        createRoute: !isReadOnly ? "scProduct" : undefined,
        scopeCertificateStatus,
    };
}
