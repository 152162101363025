import { useMemo } from "react";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import { SortOption, useQueryBuilder } from "../components/grid/component/useQueryBuilder";
import useResolvedRoute from "../components/routing/useResolvedRoute";
import { SubListProps } from "../components/grid/SubList";
import { defaultCertificationRelationSortOption } from "./useCertificationRelationListView";
import { CertificationRelationDetails, CertificationRelationSummary } from "./certificationRelationTypes";
import { GridColumn } from "../components/grid/component/Grid";
import { buildBooleanColumn, buildDataColumn, buildDateColumn, buildTextColumn } from "../components/grid/ColumnProvider";
import { EnumEntityType } from "../components/Input/pickList/typedEnumPicklist/enumEntityType";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

export const certificationRelationSubListColumns: GridColumn<CertificationRelationSummary>[] = [
    buildTextColumn({
        key: "standardAcronym",
        title: "certificationRelation.list.standard_column",
        minWidth: 126,
    }),
    buildDataColumn<CertificationRelationSummary, EnumEntityType>({
        key: "accessLevel",
        title: "certificationRelation.list.accessLevel_column",
        minWidth: 123,
        url: "/certificate-service/references/certification-relation-access-levels",
        itemId: "id",
        itemLabel: "name",
    }),
    buildDateColumn({
        key: "startDate",
        title: "certificationRelation.list.startDate_column",
        minWidth: 130,
    }),
    buildDateColumn({
        key: "endDate",
        title: "certificationRelation.list.endDate_column",
        minWidth: 124,
    }),
    buildTextColumn({
        key: "cbLicenceNumber",
        title: "certificationRelation.list.cbLicenceNumber_column",
        minWidth: 222,
        visible: false,
    }),
    buildTextColumn({
        key: "recertificationEmail",
        title: "certificationRelation.list.recertificationEmail_column",
        minWidth: 222,
        visible: false,
    }),
    buildBooleanColumn({
        key: "certificationBodyCc",
        title: "certificationRelation.list.certificationBodyCc_column",
        minWidth: 50,
        visible: false,
    }),
];
export default function useCertificationRelationSubList({
                                                            columns,
                                                            baseQueryParamKey,
                                                            baseQueryParamValue,
                                                            tableName,
                                                            createUrl,
                                                            sortOption = defaultCertificationRelationSortOption,
                                                            allowDelete = true,
                                                        }: UseCertificationRelationsSubListProps) {
    const { getResolvedEditUrl } = useResolvedRoute();

    const { query, columnOptions, forceReload } = useQueryBuilder<CertificationRelationSummary>(
        "/certificate-service/certification-relations",
        {
            sort: sortOption,
            filterActive: false,
            [baseQueryParamKey]: baseQueryParamValue,
        },
    );

    const showDetailsAction = useShowDetailsAction(
        (item: CertificationRelationSummary) => getResolvedEditUrl("certificationRelation", item.id),
        query.parameter,
    );

    const deleteAction = useDeleteAction(
        (item: CertificationRelationSummary) => `/certificate-service/certification-relations/${item.id}`,
        () => forceReload(),
        "updateOperationLevelOrganisation",
    );

    const contextMenuActions = useGridRowContextMenuActions(showDetailsAction, allowDelete ? deleteAction : undefined);

    return useMemo<SubListProps<CertificationRelationSummary>>(
        () => ({
            query,
            columns,
            uniqueDataKey: "id",
            onItemDoubleClick: showDetailsAction,
            header: "supplyChainOperator.detail.certificationRelations_tab",
            itemActions: contextMenuActions,
            columnOptions,
            createUrl,
            tableName,
        }),
        [columnOptions, columns, contextMenuActions, createUrl, query, showDetailsAction, tableName],
    );
}

export interface UseCertificationRelationsSubListProps {
    columns: GridColumn<CertificationRelationSummary>[];
    baseQueryParamKey: "certificationBodyId" | "supplyChainOperatorId";
    baseQueryParamValue?: string;
    tableName: string;
    allowDelete?: boolean;
    createUrl: SubListProps<CertificationRelationSummary>["createUrl"];
    sortOption?: SortOption<CertificationRelationSummary | CertificationRelationDetails>;
}
