import { yupResolver } from "@hookform/resolvers/yup";
import useIsAuthorised from "../../auth/useIsAuthorised";
import { ProductDetailDetails } from "../productDetailTypes";
import { productDetailValidationSchema } from "./productDetailValidationSchema";
import { defaultProductDetailSortOption } from "../useProductDetailListView";
import useResolvedRoute, { routeEnum } from "../../components/routing/useResolvedRoute";
import { useMemo } from "react";
import useGtbTranslation from "../../i18n/useGtbTranslation";
import useDomainEntityDetailsEdit from "../../hooks/details/useDomainEntityDetailsEdit";
import { buildTranslatedHistoryColumns } from "../../i18n/languageUtils";
import useLanguage from "../../i18n/LanguageContextProvider";
import { ProductDetailDetailViewProps } from "./ProductDetailDetailView";

export default function useProductDetailEdit(): ProductDetailDetailViewProps {
    const translation = useGtbTranslation();
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedId } = useResolvedRoute();
    const currentLanguage = useLanguage();
    const productDetailId = getResolvedId("productDetail");

    const { detailViewProps, form, initialData, isReadOnly, title, recordNavigationProps } =
        useDomainEntityDetailsEdit<ProductDetailDetails>({
            baseUrl: "/certificate-service/product-details/" + productDetailId,
            frontendRoute: "productDetail",
            recordNavigationDefaultSort: defaultProductDetailSortOption,
            titleBuilder: (item) => item.name[currentLanguage],
            resolver: yupResolver(productDetailValidationSchema),
            canSave: hasAccessRight("updateMasterData"),
            historyColumns: [
                { identifier: "code", title: "productDetail.history.code_column" },
                ...buildTranslatedHistoryColumns<ProductDetailDetails>("productDetail.history.name_column", "name"),
                { identifier: "pickListSequence", title: "productDetail.history.pickListSequence_column" },
                { identifier: "startDate", title: "productDetail.history.startDate_column" },
                { identifier: "endDate", title: "productDetail.history.endDate_column" },
            ],
        });

    const createRoute = useMemo<routeEnum | undefined>(() => {
        return hasAccessRight("createMasterData") ? "productDetail" : undefined;
    }, [hasAccessRight]);

    return {
        detailViewProps,
        form,
        isReadOnly,
        title,
        subtitle: translation({ key: "productDetail.detail.subtitle", options: { code: initialData?.["code"] } }),
        recordNavigationProps,
        createRoute,
    };
}
