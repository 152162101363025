import useOrganicMaterialOriginSubList from "./organicMaterialOrigin/useOrganicMaterialOriginSubList";
import InfinityScrollSubList from "../../components/grid/InfinityScrollSubList";

export default function OrganicMaterialOrigins({
                                                   isFrozen,
                                                   isReadOnly,
                                                   initialAvailableQuantityIsSet,
                                                   afterDeletion,
                                               }: {
    isFrozen: boolean;
    isReadOnly: boolean;
    initialAvailableQuantityIsSet: boolean;
    afterDeletion: Function;
}) {
    const organicMaterialOriginsSubList = useOrganicMaterialOriginSubList(
        isFrozen,
        isReadOnly,
        initialAvailableQuantityIsSet,
        afterDeletion,
    );
    return <InfinityScrollSubList {...organicMaterialOriginsSubList} className="organicMaterialOrigin" />;
}
