import { SortOption } from "../components/grid/component/useQueryBuilder";
import { GridColumn } from "../components/grid/component/Grid";
import { buildDateColumn, buildNumberColumn, buildTextColumn } from "../components/grid/ColumnProvider";
import { OrganicMaterialProductionScDetails, OrganicMaterialProductionScSummary } from "./organicMaterialProductionScTypes";
import useIsAuthorised from "../auth/useIsAuthorised";
import useResolvedRoute, { getCreateUrl } from "../components/routing/useResolvedRoute";
import useListView from "../hooks/useListView";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import { useMemo } from "react";
import { EntityListViewGridProps } from "../components/entity/EntityListView";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

export const defaultOrganicMaterialProductionScSortOption: SortOption<
    OrganicMaterialProductionScSummary | OrganicMaterialProductionScDetails
> = {
    field: "scNumber",
    direction: "ASC",
};

const columns: GridColumn<OrganicMaterialProductionScSummary>[] = [
    buildTextColumn({
        key: "scNumber",
        title: "organicMaterialProductionSc.list.scNumber_column",
        minWidth: 142,
    }),
    buildTextColumn({
        key: "organicMaterialProducerBusinessName",
        title: "organicMaterialProductionSc.list.organicMaterialProducer_column",
        minWidth: 257,
    }),
    buildTextColumn({
        key: "standardAcronym",
        title: "organicMaterialProductionSc.list.standard_column",
        minWidth: 126,
    }),
    buildTextColumn({
        key: "issuingBody",
        title: "organicMaterialProductionSc.list.issuingBody_column",
        minWidth: 155,
    }),
    buildDateColumn({ key: "validUntil", title: "organicMaterialProductionSc.list.validUntil_column", minWidth: 132 }),
    buildNumberColumn({
        key: "totalFarmAreaUnderSc",
        title: "organicMaterialProductionSc.list.farmArea_column",
        minWidth: 167,
        fixedDecimal: true,
    }),
    buildDateColumn({
        key: "validFrom",
        title: "organicMaterialProductionSc.list.validFrom_column",
        minWidth: 134,
        visible: false,
    }),
];

export default function useOrganicMaterialProductionScListView() {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedEditUrl } = useResolvedRoute();

    const {
        queryBuilder: { forceReload, columnOptions, query, queryCommands },
    } = useListView<OrganicMaterialProductionScSummary>({
        navigationContextLabel: "organicMaterialProductionSc.breadcrumb",
        baseQuery: "/certificate-service/organic-material-production-scs",
        defaultSortOption: defaultOrganicMaterialProductionScSortOption,
    });

    const showDetailsAction = useShowDetailsAction(
        (item: OrganicMaterialProductionScSummary) => getResolvedEditUrl("organicMaterialProductionSc", item.id),
        query.parameter,
    );

    const deleteAction = useDeleteAction(
        (item: OrganicMaterialProductionScSummary) => `/certificate-service/organic-material-production-scs/${item.id}`,
        forceReload,
        "updateOperationLevelOrganisation",
    );

    const actions = useGridRowContextMenuActions(showDetailsAction, deleteAction);

    return useMemo<EntityListViewGridProps<OrganicMaterialProductionScSummary>>(() => {
        return {
            query,
            columns: columns,
            uniqueDataKey: "id",
            onItemDoubleClick: showDetailsAction,
            createUrl: hasAccessRight("updateOperationLevelOrganisation")
                ? getCreateUrl("organicMaterialProductionSc")
                : undefined,
            tableName: "organicMaterialProductionSc",
            columnOptions,
            itemActions: actions,
            queryCommands,
            bulkDeleteAccessRight: () => hasAccessRight("updateOperationLevelOrganisation"),
        };
    }, [query, showDetailsAction, hasAccessRight, columnOptions, actions, queryCommands]);
}
