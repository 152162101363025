import { TypedDetailViewProps } from "../../components/detailView/DetailView";
import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import "../../components/spinner.css";
import { FacilityDetails } from "../facilityTypes";
import FacilityBasicData from "../../facility/details/FacilityBasicData";
import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";
import EntityDetailView from "../../components/entity/EntityDetailView";
import Tabs, { TabKey } from "../../components/tabs/Tabs";
import { OrganisationReadOnlyProps } from "../../organisation/details/organisationDetailViewProps";
import FacilityAddressData from "./FacilityAddressData";
import FacilityOtherData from "./FacilityOtherData";
import { externalIdComponentTypeEnum } from "../../externalId/externalIdTypes";

export default function FacilityDetailView({
                                               detailViewProps,
                                               form,
                                               readOnlyProps,
                                               externalIdComponentType,
                                               typeOfRegistrationId,
                                               supplyChainOperatorLegalName,
                                               supplyChainOperator,
                                               requiresLegalNameInEnglish,
                                               ...rest
                                           }: FacilityDetailViewProps) {
    useHelpContext("facility-details");

    return (
        <EntityDetailView {...rest} detailViewProps={detailViewProps} form={form}>
            <Tabs<
                    FacilityDetails,
                    TabKey<
                        | "facility.detail.basicData_tab"
                        | "facility.detail.addressData_tab"
                        | "facility.detail.otherData_tab"
                    >
                >
                tabs={[
                    {
                        heading: "facility.detail.basicData_tab",
                        element: (
                            <FacilityBasicData
                                form={form}
                                readOnlyProps={readOnlyProps}
                                supplyChainOperatorLegalName={supplyChainOperatorLegalName}
                                supplyChainOperator={supplyChainOperator}
                                requiresLegalNameInEnglish={requiresLegalNameInEnglish}
                            />
                        ),
                    },
                    {
                        heading: "facility.detail.addressData_tab",
                        element: (
                            <FacilityAddressData
                                form={form}
                                readOnlyProps={readOnlyProps}
                                typeOfRegistrationId={typeOfRegistrationId}
                            />
                        ),
                    },
                    {
                        heading: "facility.detail.otherData_tab",
                        element: (
                            <FacilityOtherData
                                form={form}
                                readOnlyProps={readOnlyProps}
                                externalIdComponentType={externalIdComponentType}
                            />
                        ),
                    },
                ]}
                errors={form.form.formState.errors}
                errorToTab={{
                    id: "internal",
                    countryArea: "facility.detail.addressData_tab",
                    stateProvince: "facility.detail.addressData_tab",
                    systemId: "internal",
                    businessName: "facility.detail.basicData_tab",
                    website: "facility.detail.basicData_tab",
                    organisationalEmail: "facility.detail.basicData_tab",
                    organisationalPhoneNumber: "facility.detail.basicData_tab",
                    startDate: "facility.detail.basicData_tab",
                    endDate: "facility.detail.basicData_tab",
                    town: "facility.detail.addressData_tab",
                    postCode: "facility.detail.addressData_tab",
                    addressDetails1: "facility.detail.addressData_tab",
                    addressDetails2: "facility.detail.addressData_tab",
                    addressDetails3: "facility.detail.addressData_tab",
                    latitude: "facility.detail.addressData_tab",
                    longitude: "facility.detail.addressData_tab",
                    organisationStatus: "facility.detail.basicData_tab",
                    legalName: "facility.detail.basicData_tab",
                    legalNameInEnglish: "facility.detail.basicData_tab",
                    fixedRegistrationId: "facility.detail.addressData_tab",
                    independentLegalEntity: "facility.detail.basicData_tab",
                    numberOfEmployees: "facility.detail.otherData_tab",
                    cbInternalId: "facility.detail.otherData_tab",
                }}
            />
        </EntityDetailView>
    );
}

export interface FacilityDetailViewProps extends TypedDetailViewProps<FacilityDetails> {
    readOnlyProps: FacilityReadOnlyProps;
    externalIdComponentType: externalIdComponentTypeEnum;
    typeOfRegistrationId?: string;
    supplyChainOperatorLegalName?: string;
    supplyChainOperator?: string;
    requiresLegalNameInEnglish: boolean;
}

export interface FacilityDetailViewTabProps {
    form: UseGtbFormReturn<FacilityDetails>;
    readOnlyProps: FacilityReadOnlyProps;
    externalIdComponentType?: externalIdComponentTypeEnum;
    typeOfRegistrationId?: string;
    supplyChainOperatorLegalName?: string;
    supplyChainOperator?: string;
    requiresLegalNameInEnglish?: boolean;
}

export interface FacilityReadOnlyProps extends OrganisationReadOnlyProps {
    isLegalNameReadOnly: boolean;
    isPublicContactPersonNameReadOnly: boolean;
    isPublicContactPersonEmailReadOnly: boolean;
    isOrganisationalContactPersonNameReadOnly: boolean;
    isOrganisationalContactPersonEmailReadOnly: boolean;
    isFixedRegistrationIdReadOnly: boolean;
    isMainFacilityReadOnly: boolean;
    isBrandNamesReadOnly: boolean;
    isMembershipReadOnly: boolean;
    isIndependentLegalEntityReadOnly: boolean;
    isNumberOfEmployeesReadOnly: boolean;
    isCbInternalIdReadOnly: boolean;
    isLegalNameInEnglishReadOnly: boolean;
    isSubEntityReadOnly: boolean;
}
