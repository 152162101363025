import useResolvedRoute from "../components/routing/useResolvedRoute";
import { buildTextColumn } from "../components/grid/ColumnProvider";
import useSubcontractingRelationSubList from "./useSubcontractingRelationSubList";

export default function usePrincipalsSubList(readOnly: boolean) {
    const { getResolvedId } = useResolvedRoute();

    const firstColumn = buildTextColumn({
        key: "principalSupplyChainOperatorBusinessName",
        title: "subcontractingRelation.list.principal_column",
        minWidth: 124,
    });

    const { ...principalsListOptions } = useSubcontractingRelationSubList({
        firstColumn,
        baseQueryParamKey: "subcontractorSupplyChainOperatorId",
        baseQueryParamValue: getResolvedId("supplyChainOperator"),
        header: "subcontractingRelation.list.principals_header",
        tableName: "principals",
        readOnly,
    });

    return principalsListOptions;
}
