import { Nullable } from "../../utils/typeUtils";
import { SupplyingRelationDetails } from "../supplyingRelationTypes";
import useDetailsCreate from "../../hooks/details/useDetailsCreate";
import { yupResolver } from "@hookform/resolvers/yup";
import { supplyingRelationValidationSchema } from "./supplyingRelationValidationSchema";
import { SupplyingRelationDetailViewProps, SupplyingRelationReadOnlyProps } from "./SupplyingRelationDetailView";
import { useMemo } from "react";
import useAuth from "../../auth/useAuth";
import useResolvedRoute from "../../components/routing/useResolvedRoute";

export default function useSupplyingRelationCreate(): SupplyingRelationDetailViewProps {
    const {
        currentUser: { authorisationRole, organisation },
    } = useAuth();
    const { getResolvedId } = useResolvedRoute();

    const currentSco = useMemo(
        () =>
            getResolvedId("supplyChainOperator") ??
            (authorisationRole.type === "SUPPLY_CHAIN_OPERATOR" ? organisation : null),
        [authorisationRole.type, getResolvedId, organisation],
    );

    const isAccessLevelWriteable = useMemo(
        () => authorisationRole.type === "STANDARD_BODY" && authorisationRole.level === 2,
        [authorisationRole.level, authorisationRole.type],
    );
    const isAuthRoleReadOnly = useMemo(() => authorisationRole.level > 2, [authorisationRole]);

    const initialData = useMemo<Nullable<SupplyingRelationDetails>>(() => {
        return {
            id: null,
            supplyChainOperator: currentSco,
            organicMaterialProducer: null,
            accessLevel: "UNCONFIRMED",
            startDate: new Date(),
            endDate: null,
            supplyChainOperatorBusinessName: null,
            organicMaterialProducerBusinessName: null,
            supplyChainOperatorStatus: null,
        };
    }, [currentSco]);

    const { detailViewProps, form, title } = useDetailsCreate<SupplyingRelationDetails>({
        title: "supplyingRelation.detail.newRecord_title",
        frontendRoute: "supplyingRelation",
        initialData,
        resolver: yupResolver(supplyingRelationValidationSchema),
        saveUrl: "/certificate-service/supplying-relations",
    });

    const readOnlyProps = useMemo<SupplyingRelationReadOnlyProps>(
        () => ({
            readOnly: false,
            scoReadOnly: !!currentSco,
            accessLevelReadOnly: !isAccessLevelWriteable,
            ompReadOnly: false,
            endDateReadOnly: isAuthRoleReadOnly,
        }),
        [currentSco, isAccessLevelWriteable, isAuthRoleReadOnly],
    );

    return {
        title,
        detailViewProps,
        form,
        createRoute: "supplyingRelation",
        readOnlyProps,
    };
}
