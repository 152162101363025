import { useMemo } from "react";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import { SortOption, useQueryBuilder } from "../components/grid/component/useQueryBuilder";
import useResolvedRoute from "../components/routing/useResolvedRoute";
import { SubListProps } from "../components/grid/SubList";
import { SubcontractingRelationDetails, SubcontractingRelationSummary } from "./subcontractingRelationTypes";
import { defaultSubcontractingRelationSortOption } from "./useSubcontractingRelationListView";
import { GridColumn } from "../components/grid/component/Grid";
import { buildDataColumn, buildDateColumn, buildTextColumn } from "../components/grid/ColumnProvider";
import { I18nKey } from "../i18n/useGtbTranslation";
import { EnumEntityType } from "../components/Input/pickList/typedEnumPicklist/enumEntityType";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

const commonColumns: GridColumn<SubcontractingRelationSummary>[] = [
    buildTextColumn({
        key: "standardAcronym",
        title: "subcontractingRelation.list.standard_column",
        minWidth: 123,
    }),
    buildDataColumn<SubcontractingRelationSummary, EnumEntityType>({
        key: "accessLevel",
        title: "subcontractingRelation.list.accessLevel_column",
        minWidth: 137,
        url: "/certificate-service/references/subcontracting-relation-access-levels",
        itemId: "id",
        itemLabel: "name",
    }),
    buildDataColumn<SubcontractingRelationSummary, EnumEntityType>({
        key: "confirmationMethod",
        title: "subcontractingRelation.list.confirmationMethod_column",
        minWidth: 202,
        itemId: "id",
        itemLabel: "name",
        url: "/certificate-service/references/confirmation-methods",
    }),
    buildDateColumn({
        key: "startDate",
        title: "subcontractingRelation.list.startDate_column",
        minWidth: 130,
    }),
    buildDateColumn({
        key: "endDate",
        title: "subcontractingRelation.list.endDate_column",
        minWidth: 124,
    }),
];

export default function useSubcontractingRelationSubList({
                                                             firstColumn,
                                                             baseQueryParamKey,
                                                             baseQueryParamValue,
                                                             header,
                                                             tableName,
                                                             createUrl,
                                                             sortOption = defaultSubcontractingRelationSortOption,
                                                             readOnly,
                                                         }: UseSubcontractingRelationsSubListProps) {
    const { getResolvedEditUrl } = useResolvedRoute();

    const columns = useMemo<GridColumn<SubcontractingRelationSummary>[]>(() => {
        return [firstColumn, ...commonColumns];
    }, [firstColumn]);

    const { query, columnOptions, forceReload } = useQueryBuilder<SubcontractingRelationSummary>(
        "/certificate-service/subcontracting-relations",
        {
            sort: sortOption,
            filterActive: false,
            [baseQueryParamKey]: baseQueryParamValue,
        },
    );

    const showDetailsAction = useShowDetailsAction(
        (item: SubcontractingRelationSummary) => getResolvedEditUrl("subcontractingRelation", item.id),
        query.parameter,
    );

    const deleteAction = useDeleteAction(
        (item: SubcontractingRelationSummary) => `/certificate-service/subcontracting-relations/${item.id}`,
        () => forceReload(),
        "updateOperationLevelOrganisation",
    );

    const contextMenuActions = useGridRowContextMenuActions(showDetailsAction, readOnly ? undefined : deleteAction);

    return useMemo<SubListProps<SubcontractingRelationSummary>>(
        () => ({
            query,
            columns,
            uniqueDataKey: "id",
            onItemDoubleClick: showDetailsAction,
            header,
            itemActions: contextMenuActions,
            columnOptions,
            createUrl: readOnly ? undefined : createUrl,
            tableName: tableName,
        }),
        [
            columnOptions,
            columns,
            contextMenuActions,
            createUrl,
            header,
            query,
            showDetailsAction,
            tableName,
            readOnly,
        ],
    );
}

export interface UseSubcontractingRelationsSubListProps {
    firstColumn: GridColumn<SubcontractingRelationSummary>;
    baseQueryParamKey: "principalSupplyChainOperatorId" | "subcontractorSupplyChainOperatorId";
    baseQueryParamValue?: string;
    header: I18nKey;
    tableName: string;
    sortOption?: SortOption<SubcontractingRelationSummary | SubcontractingRelationDetails>;
    createUrl?: string;
    readOnly: boolean;
}
