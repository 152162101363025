import useExternalIdSubList from "../useExternalIdSubList";
import useResolvedRoute from "../../components/routing/useResolvedRoute";

export default function useExternalOrganicMaterialProducerIdSubList(isReadOnly: boolean) {
    const { getResolvedId } = useResolvedRoute();
    const organicMaterialProducerId = getResolvedId("organicMaterialProducer")!;

    return useExternalIdSubList({
        tableName: "organicMaterialProducerExternalOrganicMaterialProducerIds",
        i18nKey: "externalOrganicMaterialProducerId",
        routeEnumKey: "organicMaterialProducerExternalOrganicMaterialProducerId",
        parentType: "ORGANIC_MATERIAL_PRODUCER",
        parentIdParamValue: organicMaterialProducerId,
        isReadOnly,
    });
}
