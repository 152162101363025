import { yupResolver } from "@hookform/resolvers/yup";
import useIsAuthorised from "../../auth/useIsAuthorised";
import { AccreditationRelationDetails } from "../accreditationRelationTypes";
import { accreditationRelationValidationSchema } from "./accreditationRelationValidationSchema";
import useResolvedRoute, { routeEnum } from "../../components/routing/useResolvedRoute";
import { useMemo } from "react";
import useDomainEntityDetailsEdit from "../../hooks/details/useDomainEntityDetailsEdit";
import { defaultAccreditationRelationSortOption } from "../useAccreditationRelationListView";
import { AccreditationRelationDetailViewProps, AccreditationRelationReadOnlyProps } from "./AccreditationRelationDetailView";
import { useQuery } from "../../hooks/useAxios";
import { CertificationBodyReference } from "../../certificationBody/certificationBodyTypes";

export default function useAccreditationRelationEdit(): AccreditationRelationDetailViewProps {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedId } = useResolvedRoute();
    const certificationBodyId = getResolvedId("certificationBody");
    const accreditationRelationRoute = useMemo(
        () => (certificationBodyId ? "certificationBodyAccreditationRelation" : "accreditationRelation"),
        [certificationBodyId],
    );

    const { data: certificationBody } = useQuery<CertificationBodyReference>({
        url: "/certificate-service/references/certification-bodies/" + certificationBodyId,
        enabled: !!certificationBodyId,
    });

    const accreditationRelationId = getResolvedId(accreditationRelationRoute);

    const { detailViewProps, form, isReadOnly, title, recordNavigationProps } =
        useDomainEntityDetailsEdit<AccreditationRelationDetails>({
            baseUrl: "/certificate-service/accreditation-relations/" + accreditationRelationId,
            resolver: yupResolver(accreditationRelationValidationSchema),
            frontendRoute: accreditationRelationRoute,
            recordNavigationDefaultSort: defaultAccreditationRelationSortOption,
            titleBuilder: (item) => `${item.accreditationNumber}`,
            canSave: hasAccessRight("updateCertificationLevelOrganisation", certificationBody ? [certificationBody.organisationStatus] : undefined),
            historyColumns: [
                {
                    identifier: "certificationBodyBusinessName" as keyof AccreditationRelationDetails,
                    title: "accreditationRelation.history.certificationBody_column",
                },
                {
                    identifier: "accreditationBodyBusinessName" as keyof AccreditationRelationDetails,
                    title: "accreditationRelation.history.accreditationBody_column",
                },
                {
                    identifier: "standardVersion",
                    title: "accreditationRelation.history.standardVersion_column",
                },
                {
                    identifier: "accreditationNumber",
                    title: "accreditationRelation.history.accreditationNumber_column",
                },
                {
                    identifier: "startDate",
                    title: "accreditationRelation.history.startDate_column",
                },
                {
                    identifier: "endDate",
                    title: "accreditationRelation.history.endDate_column",
                },
            ],
        });

    const readOnlyProps = useMemo<AccreditationRelationReadOnlyProps>(
        () => ({
            readOnly: isReadOnly,
            certificationBodyReadOnly: true,
        }),
        [isReadOnly],
    );

    const createRoute = useMemo<routeEnum | undefined>(() => {
        return hasAccessRight("updateCertificationLevelOrganisation", certificationBody ? [certificationBody.organisationStatus] : undefined) ? accreditationRelationRoute : undefined;
    }, [hasAccessRight, certificationBody, accreditationRelationRoute]);

    return {
        detailViewProps,
        form,
        title,
        readOnlyProps,
        recordNavigationProps,
        createRoute,
    };
}
