import { UseGtbFormReturn } from "../../../../../../../hooks/formHandling/useGtbForm";
import { ScFacilityDetails } from "./scFacilityTypes";
import { joinClassNames } from "../../../../../../../utils/StringUtils";
import GtbTextField from "../../../../../../../components/Input/GtbTextField";
import FacilityPickList from "../../../../../../../components/Input/pickList/typedPicklist/FacilityPickList";
import "./scFacilityBasicData.css";

export interface ScFacilityBasicDataProps {
    form: UseGtbFormReturn<ScFacilityDetails>;
    scopeCertificateId: string;
    readOnly: boolean;
}

export default function ScFacilityBasicData({ form, scopeCertificateId, readOnly }: ScFacilityBasicDataProps) {
    return (
        <div className={joinClassNames("scopeCertificateFacilityBasicData", "formGrid")}>
            <GtbTextField
                label="scFacility.detail.supplyChainOperator_input"
                {...form.registerWithErrors("scopeCertificateSupplyChainOperatorBusinessName")}
                className="supplyChainOperator"
                readOnly
            />
            <GtbTextField
                label="scFacility.detail.scProductNumber_input"
                {...form.registerWithErrors("scProductScProductNumber")}
                className="scProductNumber"
                readOnly
            />
            <GtbTextField
                label="scFacility.detail.productCategoryPrint_input"
                {...form.registerWithErrors("scProcessCategoryScProductProductCategoryPrint")}
                className="productCategory"
                readOnly
            />
            <GtbTextField
                label="scFacility.detail.productDetailPrint_input"
                {...form.registerWithErrors("scProcessCategoryScProductProductDetailPrint")}
                className="productDetail"
                readOnly
            />
            <GtbTextField
                label="scFacility.detail.labelGrade_input"
                {...form.registerWithErrors("scProductLabelGradeLabelGrade")}
                className="labelGrade"
                readOnly
            />
            <GtbTextField
                label="scFacility.detail.processCategoryPrint_input"
                {...form.registerWithErrors("scProcessCategoryProcessCategoryPrint")}
                className="processCategory"
                readOnly
            />
            <FacilityPickList
                label="scFacility.detail.facility_input"
                control={form.form.control}
                selectedItemLabel={(facility) =>
                    `${facility.supplyChainOperatorBusinessName} - ${facility.businessName} (${facility.systemId})`
                }
                itemLabel={(facility) =>
                    `${facility.supplyChainOperatorBusinessName} - ${facility.businessName} (${facility.systemId})`
                }
                flags={{ scopeCertificateId }}
                name="facility"
                readOnly={readOnly}
                className="facility"
            />
        </div>
    );
}
