import useExternalIdEdit from "../../details/useExternalIdEdit";
import useResolvedRoute from "../../../components/routing/useResolvedRoute";

export default function useExternalSupplyChainOperatorIdEdit() {
    const { getResolvedId } = useResolvedRoute();

    return useExternalIdEdit({
        i18nKey: "externalSupplyChainOperatorId",
        routeEnumKey: getResolvedId("externalSupplyChainOperatorId")
            ? "externalSupplyChainOperatorId"
            : "supplyChainOperatorExternalSupplyChainOperatorId",
        parentType: "SUPPLY_CHAIN_OPERATOR",
        parentId: getResolvedId("supplyChainOperator"),
    });
}
