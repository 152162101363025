import { buildDateColumn, buildTextColumn } from "../components/grid/ColumnProvider";
import { useMemo } from "react";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import { SortOption, useQueryBuilder } from "../components/grid/component/useQueryBuilder";
import { GridColumn } from "../components/grid/component/Grid";
import useResolvedRoute from "../components/routing/useResolvedRoute";
import { SubListProps } from "../components/grid/SubList";
import { ScoLicenceDetails, ScoLicenceSummary } from "./scoLicenceTypes";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

export const defaultScoLicenceSortOption: SortOption<ScoLicenceSummary | ScoLicenceDetails> = {
    field: "standardOfficialName",
    direction: "ASC",
};

const columns: GridColumn<ScoLicenceSummary>[] = [
    buildTextColumn({ key: "standardOfficialName", title: "scoLicence.list.standard_column", minWidth: 126 }),
    buildTextColumn({ key: "licenceNumber", title: "scoLicence.list.licenceNumber_column", minWidth: 179 }),
    buildDateColumn({
        key: "startDate",
        title: "scoLicence.list.startDate_column",
        minWidth: 130,
    }),
    buildDateColumn({
        key: "endDate",
        title: "scoLicence.list.endDate_column",
        minWidth: 124,
    }),
    buildDateColumn({
        key: "bannedUntil",
        title: "scoLicence.list.bannedUntil_column",
        minWidth: 155,
    }),
];

export default function useScoLicenceSubList(isReadOnly: boolean) {
    const { getResolvedEditUrl, getResolvedCreateUrl, getResolvedId } = useResolvedRoute();
    const supplyChainOperatorId = getResolvedId("supplyChainOperator");

    const { query, columnOptions, forceReload } = useQueryBuilder<ScoLicenceSummary>(
        `/certificate-service/sco-licences`,
        {
            sort: defaultScoLicenceSortOption,
            filterActive: false,
            supplyChainOperatorId,
        },
    );

    const showDetailsAction = useShowDetailsAction(
        (item: ScoLicenceSummary) => getResolvedEditUrl("scoLicenceNumber", item.id),
        query.parameter,
    );

    const deleteAction = useDeleteAction(
        (item: ScoLicenceSummary) => `/certificate-service/sco-licences/${item.id}`,
        () => forceReload(),
        "updateOperationLevelOrganisation",
    );

    const contextMenuActions = useGridRowContextMenuActions(showDetailsAction, isReadOnly ? undefined : deleteAction);

    return useMemo<SubListProps<ScoLicenceSummary>>(
        () => ({
            query,
            columns,
            uniqueDataKey: "id",
            onItemDoubleClick: showDetailsAction,
            header: "scoLicence.list.header",
            itemActions: contextMenuActions,
            columnOptions,
            createUrl: !isReadOnly ? getResolvedCreateUrl("scoLicenceNumber") : undefined,
            tableName: "scoLicences",
        }),
        [columnOptions, contextMenuActions, getResolvedCreateUrl, isReadOnly, query, showDetailsAction],
    );
}
