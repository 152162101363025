import { AdditionalMaterialDetails } from "../additionalMaterialTypes";
import { useMemo } from "react";
import { Nullable } from "../../../utils/typeUtils";
import useDetailsCreate from "../../../hooks/details/useDetailsCreate";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { additionalMaterialValidationSchema } from "./additionalMaterialValidationSchema";
import { useQuery } from "../../../hooks/useAxios";
import { MaterialContentRuleDetails } from "../../materialContentRuleTypes";
import useResolvedRoute from "../../../components/routing/useResolvedRoute";
import { VersionReference } from "../../../standard/version/versionTypes";
import { LabelGradeReference } from "../../../labelGrade/labelGradeTypes";
import { AdditionalMaterialDetailViewProps } from "./AdditionalMaterialDetailView";

const initialData: Nullable<AdditionalMaterialDetails> = {
    id: null,
    rawMaterial: null,
    maximumPercentage: null,
    rawMaterialName: null,
    rawMaterialCode: null,
    materialContentRule: null,
    materialContentRuleCertifiedMaterialName: null,
    materialContentRuleCertifiedMaterialCode: null,
    toStringRepresentation: null,
};

export default function useAdditionalMaterialCreate(): AdditionalMaterialDetailViewProps {
    const { getResolvedId } = useResolvedRoute();
    const materialContentRuleId = getResolvedId("materialContentRule");
    const standardId = getResolvedId("standard");
    const standardVersionId = getResolvedId("standardVersion");
    const labelGradeId = getResolvedId("labelGrade");

    const { data: standardVersion, isLoading: isVersionLoading } = useQuery<VersionReference>({
        url: "/certificate-service/references/standard-versions/" + standardVersionId,
    });

    const { data: materialContentRule, isLoading: isMaterialContentRuleLoading } = useQuery<MaterialContentRuleDetails>(
        {
            url: `/certificate-service/standards/${standardId}/standard-versions/${standardVersionId}/label-grades/${labelGradeId}/material-content-rules/${materialContentRuleId}`,
        },
    );

    const { data: labelGrade, isLoading: isLabelGradeLoading } = useQuery<LabelGradeReference>({
        url: "/certificate-service/references/label-grades/" + labelGradeId,
    });

    const { detailViewProps, form, title } = useDetailsCreate<AdditionalMaterialDetails>({
        title: "additionalMaterial.detail.newRecord_title",
        frontendRoute: "additionalMaterial",
        initialData,
        resolver: yupResolver(additionalMaterialValidationSchema),
        saveUrl: `/certificate-service/standards/${standardId}/standard-versions/${standardVersionId}/label-grades/${labelGradeId}/material-content-rules/${materialContentRuleId}/additional-materials`,
    });

    return useMemo(() => {
        return {
            detailViewProps: {
                ...detailViewProps,
                isLoading:
                    detailViewProps.isLoading ||
                    isMaterialContentRuleLoading ||
                    isVersionLoading ||
                    isLabelGradeLoading,
            },
            form,
            title,
            materialContentRule,
            readOnly: false,
            createRoute: "additionalMaterial",
            standard: standardId,
            standardVersion: standardVersion
                ? `${standardVersion.standardAcronym} ${standardVersion.versionNumber}`
                : "",
            labelGrade,
        };
    }, [
        detailViewProps,
        form,
        isLabelGradeLoading,
        isMaterialContentRuleLoading,
        isVersionLoading,
        labelGrade,
        materialContentRule,
        standardId,
        standardVersion,
        title,
    ]);
}
