import useExternalIdSubList from "../useExternalIdSubList";
import useResolvedRoute from "../../components/routing/useResolvedRoute";

export default function useExternalFacilityIdSubList(readOnly: boolean) {
    const { getResolvedId } = useResolvedRoute();
    const facilityId = getResolvedId("facility")!;

    return useExternalIdSubList({
        tableName: "facilityExternalFacilityIds",
        i18nKey: "externalFacilityId",
        routeEnumKey: "facilityExternalFacilityId",
        parentType: "FACILITY",
        parentIdParamValue: facilityId,
        isReadOnly: readOnly,
    });
}
