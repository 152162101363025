import useDomainEntityDetailsEdit from "../../hooks/details/useDomainEntityDetailsEdit";
import useResolvedRoute from "../../components/routing/useResolvedRoute";
import { OrganicMaterialProductionDetails } from "../organicMaterialProductionTypes";
import { defaultOrganicMaterialProductionSortOption } from "../useOrganicMaterialProductionListView";
import { yupResolver } from "@hookform/resolvers/yup";
import { organicMaterialProductionValidationSchema } from "./organicMaterialProductionValidationSchema";
import { useMemo } from "react";
import { OrganicMaterialProductionReadOnlyProps } from "./OrganicMaterialProductionDetailView";
import useIsAuthorised from "../../auth/useIsAuthorised";
import useConformanceCheck from "../../conformance/useConformanceCheck";

export default function useOrganicMaterialProductionEdit() {
    const { getResolvedId } = useResolvedRoute();
    const organicMaterialProductionId = getResolvedId("organicMaterialProduction");
    const { hasAccessRight } = useIsAuthorised();
    const handleConformanceCheck = useConformanceCheck<OrganicMaterialProductionDetails>();

    const { detailViewProps, form, initialData, isReadOnly, title, recordNavigationProps } =
        useDomainEntityDetailsEdit<OrganicMaterialProductionDetails>({
            baseUrl: "/certificate-service/organic-material-productions/" + organicMaterialProductionId,
            frontendRoute: "organicMaterialProduction",
            recordNavigationDefaultSort: defaultOrganicMaterialProductionSortOption,
            titleBuilder: (item) => item.systemId,
            resolver: yupResolver(organicMaterialProductionValidationSchema),
            canSave: item => hasAccessRight("updateOperationLevelOrganisation", [item.organicMaterialProducerStatus]),
            historyColumns: [
                { identifier: "systemId", title: "organicMaterialProduction.history.systemId_column" },
                {
                    identifier: "organicMaterialProducer",
                    title: "organicMaterialProduction.history.organicMaterialProducer_column",
                },
                {
                    identifier: "certifiedOrganicMaterial",
                    title: "organicMaterialProduction.history.certifiedOrganicMaterial_column",
                },
                { identifier: "usdaNopCompliant", title: "organicMaterialProduction.history.usdaNopCompliant_column" },
                {
                    identifier: "sizeOfProductionArea",
                    title: "organicMaterialProduction.history.sizeOfProductionArea_column",
                },
                {
                    identifier: "productionEstimate",
                    title: "organicMaterialProduction.history.productionEstimate_column",
                },
                { identifier: "productionSold", title: "organicMaterialProduction.history.productionSold_column" },
                {
                    identifier: "startOfHarvestYear",
                    title: "organicMaterialProduction.history.startOfHarvestYear_column",
                },
                { identifier: "town", title: "organicMaterialProduction.history.town_column" },
                { identifier: "postCode", title: "organicMaterialProduction.history.postCode_column" },
                { identifier: "stateProvince", title: "organicMaterialProduction.history.stateProvince_column" },
                { identifier: "addressDetails1", title: "organicMaterialProduction.history.addressDetails1_column" },
                { identifier: "addressDetails2", title: "organicMaterialProduction.history.addressDetails2_column" },
                { identifier: "addressDetails3", title: "organicMaterialProduction.history.addressDetails3_column" },
            ],
            resolveErrors: (error, item, retrySaveAction) => handleConformanceCheck(error, item, retrySaveAction),
        });

    const readOnlyProps = useMemo<OrganicMaterialProductionReadOnlyProps>(() => {
        const hasAnyProductionSold = initialData ? initialData.productionSold > 0 : false;
        const isFrozen = !!initialData?.frozen;
        return {
            isCertifiedOrganicMaterialReadOnly: true,
            isUsdaNopCompliantReadOnly: isReadOnly || isFrozen,
            isSizeOfProductionAreaReadOnly: isReadOnly || hasAnyProductionSold,
            isProductionEstimateReadOnly: isReadOnly || hasAnyProductionSold,
            isProductionSoldReadOnly: true,
            isHarvestYearReadOnly: isReadOnly || hasAnyProductionSold,
            isTownReadOnly: isReadOnly,
            isPostCodeReadOnly: isReadOnly,
            isCountryAreaReadOnly: isReadOnly || isFrozen,
            isAddressDetails1ReadOnly: isReadOnly,
            isAddressDetails2ReadOnly: isReadOnly,
            isAddressDetails3ReadOnly: isReadOnly,
            isProductionScReadOnly: isReadOnly,
        };
    }, [initialData, isReadOnly]);

    return {
        detailViewProps,
        form,
        readOnlyProps,
        title,
        recordNavigationProps,
        subListVisible: true,
    };
}
