import { yupResolver } from "@hookform/resolvers/yup";
import useIsAuthorised from "../../auth/useIsAuthorised";
import { certificationBodyValidationSchema } from "./certificationBodyValidationSchema";
import { useCallback, useMemo } from "react";
import useResolvedRoute, { routeEnum } from "../../components/routing/useResolvedRoute";
import useGtbTranslation from "../../i18n/useGtbTranslation";
import useDomainEntityDetailsEdit from "../../hooks/details/useDomainEntityDetailsEdit";
import { CertificationBodyDetails } from "../certificationBodyTypes";
import { defaultCertificationBodySortOption } from "../useCertificationBodyListView";
import { CertificationBodyDetailViewProps, CertificationBodyReadOnlyProps } from "./CertificationBodyDetailView";
import { useOrganisationDefaultReadOnlyProps } from "../../organisation/details/organisationDetailViewProps";

export default function useCertificationBodyEdit(): CertificationBodyDetailViewProps {
    const translation = useGtbTranslation();
    const { hasAccessRight, isRoleLevelAtLeast } = useIsAuthorised();
    const { getResolvedId } = useResolvedRoute();
    const certificationBodyId = getResolvedId("certificationBody");

    const canSave = useCallback(
        (item: CertificationBodyDetails) => {
            if (item) {
                return (
                    hasAccessRight("updateCertificationLevelOrganisation", [item.organisationStatus]) ||
                    hasAccessRight("grantPriorConsent")
                );
            }
            return false;
        },
        [hasAccessRight]
    );

    const { detailViewProps, form, initialData, title, recordNavigationProps } =
        useDomainEntityDetailsEdit<CertificationBodyDetails>({
            baseUrl: "/certificate-service/certification-bodies/" + certificationBodyId,
            frontendRoute: "certificationBody",
            titleBuilder: (item) => item.businessName,
            recordNavigationDefaultSort: defaultCertificationBodySortOption,
            resolver: yupResolver(certificationBodyValidationSchema),
            canSave,
            historyColumns: [
                { identifier: "systemId", title: "certificationBody.history.systemId_column" },
                { identifier: "legalName", title: "certificationBody.history.legalName_column" },
                { identifier: "businessName", title: "certificationBody.history.businessName_column" },
                { identifier: "addressDetails1", title: "certificationBody.history.addressDetail1_column" },
                { identifier: "addressDetails2", title: "certificationBody.history.addressDetail2_column" },
                { identifier: "addressDetails3", title: "certificationBody.history.addressDetail3_column" },
                { identifier: "latitude", title: "certificationBody.history.latitude_column" },
                { identifier: "longitude", title: "certificationBody.history.longitude_column" },
                { identifier: "postCode", title: "certificationBody.history.postcode_column" },
                { identifier: "town", title: "certificationBody.history.town_column" },
                { identifier: "stateProvince", title: "certificationBody.history.stateProvince_column" },
                {
                    identifier: "organisationalEmail",
                    title: "certificationBody.history.organisationalEmailAddress_column",
                },
                { identifier: "website", title: "certificationBody.history.website_column" },
                {
                    identifier: "organisationalPhoneNumber",
                    title: "certificationBody.history.organisationalPhoneNumber_column",
                },
                { identifier: "organisationStatus", title: "certificationBody.history.status_column" },
                { identifier: "startDate", title: "certificationBody.history.startDate_column" },
                { identifier: "endDate", title: "certificationBody.history.endDate_column" },
                { identifier: "authorisedPerson", title: "certificationBody.history.authorisedPerson_column" },
                { identifier: "scTemplate", title: "certificationBody.history.scTemplate_column" },
                { identifier: "tcTemplate", title: "certificationBody.history.tcTemplate_column" },
                { identifier: "tlsCertificate", title: "certificationBody.history.tlsCertificate_column" },
                { identifier: "tcNumberBlockSize", title: "certificationBody.history.tcNumberBlockSize_column" },
            ],
        });

    const isReadOnly = useMemo<boolean>(() => {
        if (initialData) {
            return !hasAccessRight("updateCertificationLevelOrganisation", [initialData.organisationStatus]);
        }

        return false;
    }, [hasAccessRight, initialData]);

    const defaultReadOnlyProps = useOrganisationDefaultReadOnlyProps(isReadOnly);

    const readOnlyProps = useMemo<CertificationBodyReadOnlyProps>(() => {
        const isCertificationDataReadOnly = !isRoleLevelAtLeast(3);
        return {
            ...defaultReadOnlyProps,
            isAuthorisedPersonReadOnly: isReadOnly || isCertificationDataReadOnly,
            isScTemplateReadOnly: isReadOnly || isCertificationDataReadOnly,
            isTcTemplateReadOnly: isReadOnly || isCertificationDataReadOnly,
            isTcNumberBlockSizeReadOnly: isReadOnly,
            isAccreditationReadOnly: isReadOnly,
            isCertificationLicencesReadOnly: isReadOnly,
            isPriorConsentReadOnly: !hasAccessRight("grantPriorConsent"),
        };
    }, [defaultReadOnlyProps, hasAccessRight, isReadOnly, isRoleLevelAtLeast]);

    const createRoute = useMemo<routeEnum | undefined>(() => {
        return hasAccessRight("createCertificationLevelOrganisation") ? "certificationBody" : undefined;
    }, [hasAccessRight]);

    const tlsTabVisible = useMemo(() => isRoleLevelAtLeast(3), [isRoleLevelAtLeast]);

    return {
        detailViewProps,
        form,
        readOnlyProps,
        title,
        subtitle: translation({
            key: "certificationBody.detail.subtitle",
            options: { systemId: initialData?.["systemId"] },
        }),
        recordNavigationProps,
        createRoute,
        urlProps: {
            scTemplateUrl: "/certificate-service/certification-bodies/" + certificationBodyId + "/sc-template",
            tcTemplateUrl: "/certificate-service/certification-bodies/" + certificationBodyId + "/tc-template",
        },
        subListVisible: true,
        tlsTabVisible,
        isTcNumberBlockSizeVisible: isRoleLevelAtLeast(1),
    };
}
