import { useMemo } from "react";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import { useQueryBuilder } from "../components/grid/component/useQueryBuilder";
import useResolvedRoute from "../components/routing/useResolvedRoute";
import { SubListProps } from "../components/grid/SubList";
import { OrganicMaterialPurchaseSummary } from "./organicMaterialPurchaseTypes";
import { defaultOrganicMaterialPurchaseSortOption } from "./useOrganicMaterialPurchaseListView";
import { GridColumn } from "../components/grid/component/Grid";
import { buildDataColumn, buildNumberColumn, buildTextColumn } from "../components/grid/ColumnProvider";
import { EnumEntityType } from "../components/Input/pickList/typedEnumPicklist/enumEntityType";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

const columns: GridColumn<OrganicMaterialPurchaseSummary>[] = [
    buildTextColumn({
        key: "systemId",
        title: "organicMaterialPurchase.list.systemId_column",
        minWidth: 134,
    }),
    buildTextColumn({
        key: "certifiedOrganicMaterialName",
        title: "organicMaterialPurchase.list.certifiedOrganicMaterial_column",
        minWidth: 251,
    }),
    buildNumberColumn({
        key: "availableQuantity",
        title: "organicMaterialPurchase.list.availableQuantity_column",
        minWidth: 227,
        fixedDecimal: true,
    }),
    buildNumberColumn({
        key: "initialQuantity",
        title: "organicMaterialPurchase.list.initialQuantity_column",
        minWidth: 199,
        fixedDecimal: true,
    }),
    buildNumberColumn({
        key: "initialAvailableQuantity",
        title: "organicMaterialPurchase.list.initialAvailableQuantity_column",
        minWidth: 375,
        fixedDecimal: true,
    }),
    buildDataColumn<OrganicMaterialPurchaseSummary, EnumEntityType>({
        key: "screeningResult",
        title: "organicMaterialPurchase.list.screeningResult_column",
        minWidth: 182,
        url: "/certificate-service/references/screening-results",
        itemId: "id",
        itemLabel: "name",
        visible: false,
    }),
    buildDataColumn<OrganicMaterialPurchaseSummary, EnumEntityType>({
        key: "rejected",
        title: "organicMaterialPurchase.list.rejected_column",
        minWidth: 123,
        url: "/certificate-service/references/rejected",
        itemId: "id",
        itemLabel: "name",
        visible: false,
    }),
];

export default function useOrganicMaterialPurchaseSubList(isReadOnly: boolean) {
    const { getResolvedId, getResolvedEditUrl, getResolvedCreateUrl } = useResolvedRoute();

    const { query, columnOptions, forceReload } = useQueryBuilder<OrganicMaterialPurchaseSummary>(
        `/certificate-service/organic-material-purchases`,
        {
            sort: defaultOrganicMaterialPurchaseSortOption,
            filterActive: false,
            buyerId: getResolvedId("supplyChainOperator"),
        },
    );

    const showDetailsAction = useShowDetailsAction(
        (item: OrganicMaterialPurchaseSummary) => getResolvedEditUrl("organicMaterialPurchase", item.id),
        query.parameter,
    );

    const deleteAction = useDeleteAction(
        (item: OrganicMaterialPurchaseSummary) => `/certificate-service/organic-material-purchases/${item.id}`,
        () => forceReload(),
        "updateOperationLevelOrganisation",
    );

    const contextMenuActions = useGridRowContextMenuActions(showDetailsAction, isReadOnly ? undefined : deleteAction);

    return useMemo<SubListProps<OrganicMaterialPurchaseSummary>>(
        () => ({
            query,
            columns: columns,
            uniqueDataKey: "id",
            onItemDoubleClick: showDetailsAction,
            header: "organicMaterialPurchase.list.header",
            itemActions: contextMenuActions,
            columnOptions,
            createUrl: !isReadOnly
                ? getResolvedCreateUrl("supplyChainOperatorOrganicMaterialPurchase")
                : undefined,
            tableName: "supplyChainOperatorOrganicMaterialPurchase",
        }),
        [columnOptions, contextMenuActions, getResolvedCreateUrl, isReadOnly, query, showDetailsAction],
    );
}
