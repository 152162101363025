import { useMemo } from "react";
import {
    OrganicMaterialTransactionCertificateDetails,
    OrganicMaterialTransactionCertificateSummary,
} from "./organicMaterialTransactionCertificateTypes";
import { GridColumn } from "../../../../../components/grid/component/Grid";
import { buildTextColumn } from "../../../../../components/grid/ColumnProvider";
import { SortOption, useQueryBuilder } from "../../../../../components/grid/component/useQueryBuilder";
import useResolvedRoute from "../../../../../components/routing/useResolvedRoute";
import { useDeleteAction, useShowDetailsAction } from "../../../../../utils/defaultActions";
import useGridRowContextMenuActions from "../../../../../components/grid/component/cell/useGridRowContextMenuActions";
import { SubListProps } from "../../../../../components/grid/SubList";

const columns: GridColumn<OrganicMaterialTransactionCertificateSummary>[] = [
    buildTextColumn({
        key: "tcNumber",
        title: "organicMaterialTransactionCertificate.list.tcNumber_column",
        minWidth: 141,
    }),
    buildTextColumn({
        key: "tcType",
        title: "organicMaterialTransactionCertificate.list.type_column",
        minWidth: 95,
    }),
    buildTextColumn({
        key: "standardAcronym",
        title: "organicMaterialTransactionCertificate.list.standard_column",
        minWidth: 128,
    }),
];

export const defaultOrganicMaterialTransactionCertificateSortOption: SortOption<
    OrganicMaterialTransactionCertificateSummary | OrganicMaterialTransactionCertificateDetails
> = {
    field: "tcNumber",
    direction: "ASC",
};

export default function useOrganicMaterialTransactionCertificateSubList(isReadOnly: boolean) {
    const { getResolvedId, getResolvedEditUrl, getResolvedCreateUrl } = useResolvedRoute();
    const organicMaterialPurchaseId = getResolvedId("organicMaterialPurchase");
    const organicMaterialOriginId = getResolvedId("organicMaterialOrigin");

    const { query, columnOptions, forceReload } = useQueryBuilder<OrganicMaterialTransactionCertificateSummary>(
        `/certificate-service/organic-material-purchases/${organicMaterialPurchaseId}/organic-material-origins/${organicMaterialOriginId}/organic-material-tcs`,
        {
            sort: defaultOrganicMaterialTransactionCertificateSortOption,
            filterActive: false,
        },
    );

    const showDetailsAction = useShowDetailsAction(
        (item: OrganicMaterialTransactionCertificateSummary) =>
            getResolvedEditUrl("organicMaterialTransactionCertificate", item.id),
        query.parameter,
    );

    const deleteAction = useDeleteAction(
        (item: OrganicMaterialTransactionCertificateSummary) =>
            `/certificate-service/organic-material-purchases/${organicMaterialPurchaseId}/organic-material-origins/${organicMaterialOriginId}/organic-material-tcs/${item.id}`,
        () => forceReload(),
        "updateOperationLevelOrganisation",
    );

    const contextMenuActions = useGridRowContextMenuActions(showDetailsAction, isReadOnly ? undefined : deleteAction);

    return useMemo<SubListProps<OrganicMaterialTransactionCertificateSummary>>(
        () => ({
            query,
            columns: columns,
            uniqueDataKey: "id",
            onItemDoubleClick: showDetailsAction,
            header: "organicMaterialTransactionCertificate.list.header",
            itemActions: contextMenuActions,
            columnOptions,
            createUrl: !isReadOnly
                ? getResolvedCreateUrl("organicMaterialTransactionCertificate")
                : undefined,
            tableName: "organicMaterialTransactionCertificate",
        }),
        [columnOptions, contextMenuActions, getResolvedCreateUrl, isReadOnly, query, showDetailsAction],
    );
}
