import React from "react";
import OrganicMaterialOriginBasicData, { OrganicMaterialOriginBasicDataProps } from "./OrganicMaterialOriginBasicData";
import { OrganicMaterialOriginDetails } from "../organicMaterialOriginTypes";
import { useHelpContext } from "../../../../help/context-sensitivity/useHelpContext";
import EntityDetailView from "../../../../components/entity/EntityDetailView";
import Tabs, { TabKey } from "../../../../components/tabs/Tabs";
import { TypedDetailViewProps } from "../../../../components/detailView/DetailView";

export default function OrganicMaterialOriginDetailView({
                                                            detailViewProps,
                                                            form,
                                                            readOnly,
                                                            organicMaterialPurchaseId,
                                                            subListVisible,
                                                            ...rest
                                                        }: OrganicMaterialOriginDetailViewProps) {
    useHelpContext("organic-material-origin-details");

    return (
        <EntityDetailView {...rest} detailViewProps={detailViewProps} form={form}>
            <Tabs<OrganicMaterialOriginDetails, OrganicMaterialOriginTabs>
                tabs={[
                    {
                        heading: "organicMaterialOrigin.detail.basicData_tab",
                        element: (
                            <OrganicMaterialOriginBasicData
                                form={form}
                                readOnly={readOnly}
                                organicMaterialPurchaseId={organicMaterialPurchaseId}
                                subListVisible={subListVisible}
                            />
                        ),
                    },
                ]}
                errors={form.form.formState.errors}
                errorToTab={{
                    id: "internal",
                    buyerLegalName: "organicMaterialOrigin.detail.basicData_tab",
                    buyerStatus: "internal",
                    certifiedOrganicMaterialName: "organicMaterialOrigin.detail.basicData_tab",
                    organicMaterialProducerId: "organicMaterialOrigin.detail.basicData_tab",
                    organicMaterialProduction: "organicMaterialOrigin.detail.basicData_tab",
                    transferredQuantity: "organicMaterialOrigin.detail.basicData_tab",
                    organicMaterialPurchaseSystemId: "internal",
                    organicMaterialProductionSystemId: "internal",
                    organicMaterialProductionDisplayName: "internal",
                    organicMaterialPurchaseId: "internal",
                    frozen: "internal",
                    transferredQuantityFrozen: "internal",
                }}
            />
        </EntityDetailView>
    );
}

type OrganicMaterialOriginTabs = TabKey<"organicMaterialOrigin.detail.basicData_tab">;

export interface OrganicMaterialOriginDetailViewProps
    extends TypedDetailViewProps<OrganicMaterialOriginDetails>,
        OrganicMaterialOriginBasicDataProps {
}
