export interface AccessRights {
    createUserAccount: AccessRight;
    readUserAccount: AccessRight;
    updateUserAccount: AccessRight;
    deleteUserAccount: AccessRight;
    createAuthorisationRole: AccessRight;
    readAuthorisationRole: AccessRight;
    updateAuthorisationRole: AccessRight;
    deleteAuthorisationRole: AccessRight;
    createStandardBody: StateSpecificAccessRight<standardBodyStates>;
    readStandardBody: StateSpecificAccessRight<standardBodyStates>;
    updateStandardBody: StateSpecificAccessRight<standardBodyStates>;
    deleteStandardBody: StateSpecificAccessRight<standardBodyStates>;
    createCertificationLevelOrganisation: StateSpecificAccessRight<certificationLevelOrganisation>;
    readCertificationLevelOrganisation: StateSpecificAccessRight<certificationLevelOrganisation>;
    updateCertificationLevelOrganisation: StateSpecificAccessRight<certificationLevelOrganisation>;
    deleteCertificationLevelOrganisation: StateSpecificAccessRight<certificationLevelOrganisation>;
    createOperationLevelOrganisation: StateSpecificAccessRight<operationLevelOrganisationStates>;
    readOperationLevelOrganisation: StateSpecificAccessRight<operationLevelOrganisationStates>;
    updateOperationLevelOrganisation: StateSpecificAccessRight<operationLevelOrganisationStates>;
    deleteOperationLevelOrganisation: StateSpecificAccessRight<operationLevelOrganisationStates>;
    relationTypesInMenuSystem: AccessRight;
    createProductConversion: AccessRight;
    readProductConversion: AccessRight;
    updateProductConversion: AccessRight;
    deleteProductConversion: AccessRight;
    createMasterData: AccessRight;
    readMasterData: AccessRight;
    updateMasterData: AccessRight;
    deleteMasterData: AccessRight;
    createScopeCertificate: StateSpecificAccessRight<scopeCertificateStates>;
    readScopeCertificate: StateSpecificAccessRight<scopeCertificateStates>;
    updateScopeCertificate: StateSpecificAccessRight<scopeCertificateStates>;
    deleteScopeCertificate: StateSpecificAccessRight<scopeCertificateStates>;
    createTransactionCertificate: StateSpecificAccessRight<transactionCertificateStates>;
    readTransactionCertificate: StateSpecificAccessRight<transactionCertificateStates>;
    updateTransactionCertificate: StateSpecificAccessRight<transactionCertificateStates>;
    deleteTransactionCertificate: StateSpecificAccessRight<transactionCertificateStates>;
    createPreGtbTc: StateSpecificAccessRight<preGtbTcStates>;
    readPreGtbTc: StateSpecificAccessRight<preGtbTcStates>;
    updatePreGtbTc: StateSpecificAccessRight<preGtbTcStates>;
    deletePreGtbTc: StateSpecificAccessRight<preGtbTcStates>;
    dataListsInMenuSystem: AccessRight;
    manageEventDetection: AccessRight;
    manageSystemParameter: AccessRight;
    useBulkReport: StateSpecificAccessRight<bulkReportStates>;
    useApplicationLog: AccessRight;
    useUploadLog: AccessRight;
    viewTiles: StateSpecificAccessRight<viewTileStates>;
    useMailTemplate: AccessRight;
    createTimeBasedMail: AccessRight;
    readTimeBasedMail: AccessRight;
    updateTimeBasedMail: AccessRight;
    deleteTimeBasedMail: AccessRight;
    grantPriorConsent: AccessRight;
}

const _standardBodyStateOptions = ["ACTIVE", "DEACTIVATED", "TERMINATED"] as const;
export const standardBodyStateOptions = [..._standardBodyStateOptions];
type standardBodyStates = typeof _standardBodyStateOptions[number][];
const _certificationLevelOrganisationStateOptions = ["ACTIVE", "DEACTIVATED", "TERMINATED"] as const;
export const certificationLevelOrganisationStateOptions = [..._certificationLevelOrganisationStateOptions];
type certificationLevelOrganisation = typeof _certificationLevelOrganisationStateOptions[number][];
const _operationLevelOrganisationStateOptions = ["ACTIVE", "DEACTIVATED", "TERMINATED"] as const;
export const operationLevelOrganisationStateOptions = [..._operationLevelOrganisationStateOptions];
type operationLevelOrganisationStates = typeof _operationLevelOrganisationStateOptions[number][];
const _scopeCertificateStateOptions = [
    "AWAITING_APPROVAL",
    "DRAFT",
    "EXPIRED",
    "IN_RECERTIFICATION",
    "PENDING",
    "REJECTED",
    "VALID",
    "SUSPENDED",
    "WITHDRAWN",
    "OBSOLETE",
] as const;
export const scopeCertificateStateOptions = [..._scopeCertificateStateOptions];
export type scopeCertificateStatusType = typeof scopeCertificateStateOptions[number];
type scopeCertificateStates = typeof _scopeCertificateStateOptions[number][];
const _transactionCertificateStateOptions = ["AWAITING_APPROVAL", "DRAFT", "REJECTED", "VALID", "WITHDRAWN"] as const;
export const transactionCertificateStateOptions = [..._transactionCertificateStateOptions];
export type TransactionCertificateStatusType = typeof transactionCertificateStateOptions[number];
type transactionCertificateStates = typeof _transactionCertificateStateOptions[number][];
const _preGtbTcStateOptions = ["PRELIMINARY", "FINAL", "WITHDRAWN"] as const;
export const preGtbTcStateOptions = [..._preGtbTcStateOptions];
export type preGtbTcStatusType = typeof preGtbTcStateOptions[number];
type preGtbTcStates = typeof _preGtbTcStateOptions[number][];

const _bulkReportStates = [
    "SUPPLY_CHAIN_OPERATORS",
    "FACILITIES",
    "SCOPE_CERTIFICATES",
    "SC_PRODUCTS",
    "SC_MATERIALS",
    "SC_PROCESS_CATEGORIES",
    "SC_FACILITIES",
    "TRANSACTION_CERTIFICATES",
    "TC_SHIPMENTS",
    "TC_PRODUCTS",
    "TC_MATERIALS",
    "TC_INPUT_QUANTITIES",
    "PRE_GTB_TCS",
    "PRE_GTB_PRODUCTS",
    "PRE_GTB_MATERIALS",
    "PRE_GTB_MATERIAL_ORIGINS",
    "ORGANIC_MATERIAL_PRODUCTION_SCS",
    "ORGANIC_MATERIAL_PRODUCTIONS",
    "ORGANIC_MATERIAL_CERTIFICATIONS",
    "ORGANIC_MATERIAL_PURCHASES",
    "ORGANIC_MATERIAL_ORIGINS",
    "ACCREDITATION_RELATIONS",
    "SCO_LICENCE_NUMBERS",
    "CB_LICENCING_CODES",
] as const;
export const bulkReportStateOptions = [..._bulkReportStates];
type bulkReportStates = typeof _bulkReportStates[number][];

const _viewTileStates = [
    "NOTEPAD",
    "NEW_FEATURES",
    "MAINTENANCE",
    "ACTIVE_USER_ACCOUNTS",
    "ACTIVE_CBS",
    "ACTIVE_AND_TOTAL_SCS",
    "SC_ACTIVE",
    "SC_PENDING",
    "SC_AWAITING_APPROVAL",
    "SC_IN_PREPARATION",
    "SC_WITHDRAWN",
    "SC_SUSPENDED",
    "TC_APPROVED_WITHIN_7_DAYS_AND_ACTIVE",
    "TC_APPROVED_WITHIN_12_MONTH_AND_ACTIVE",
    "TC_AWAITING_APPROVAL",
    "TC_IN_PREPARATION",
    "TC_WITHDRAWN",
    "TC_VALID_CLIENT",
    "TC_AWAITING_APPROVAL_CLIENT",
    "TC_IN_PREPARATION_CLIENT",
    "TC_WITHDRAWN_CLIENT",
    "SCO_AVAILABLE",
    "SCO_INACTIVE",
    "CLIENT_SCOS",
    "UNCONFIRMED_RELATIONS",
    "SECURITY_EVENTS_LAST_24_HOURS",
    "SYSTEM_INFORMATION",
] as const;
export const viewTileStateOptions = [..._viewTileStates];
type viewTileStates = typeof _viewTileStates[number][];
export type viewTileState = typeof _viewTileStates[number];

export interface AccessRight {
    granted: boolean;
}

export interface StateSpecificAccessRight<State> extends AccessRight {
    grantedStates: State;
}
