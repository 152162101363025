import { joinClassNames } from "../../utils/StringUtils";
import "./certificationBodyLicencingCodes.css";
import SubList from "../../components/grid/SubList";
import useCertificationLicenceSubList from "../../certificationLicence/useCertificationLicenceSubList";

export default function CertificationBodyLicencingCodes({ isReadOnly }: { isReadOnly: boolean }) {
    const certificationLicencesSublistOptions = useCertificationLicenceSubList(isReadOnly);
    return (
        <div className={joinClassNames("certificationBodyLicencingCodes", "formGrid")}>
            <SubList {...certificationLicencesSublistOptions} className="certificationLicences" />
        </div>
    );
}
