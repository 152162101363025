import { yupResolver } from "@hookform/resolvers/yup";
import useIsAuthorised from "../../auth/useIsAuthorised";
import { buildFacilityValidationSchema } from "./facilityValidationSchema";
import { useCallback, useEffect, useMemo, useState } from "react";
import useResolvedRoute, { routeEnum } from "../../components/routing/useResolvedRoute";
import useGtbTranslation from "../../i18n/useGtbTranslation";
import useDomainEntityDetailsEdit from "../../hooks/details/useDomainEntityDetailsEdit";
import { FacilityDetailViewProps, FacilityReadOnlyProps } from "./FacilityDetailView";
import useTypeOfRegistrationId from "../../countryArea/useTypeOfRegistrationId";
import { FacilityDetails } from "../facilityTypes";
import { useQuery } from "../../hooks/useAxios";
import { SupplyChainOperatorReference } from "../../supplyChainOperator/supplyChainOperatorTypes";
import { defaultFacilitySortOption } from "../useFacilitySubList";
import useAuth from "../../auth/useAuth";
import { externalIdComponentTypeEnum } from "../../externalId/externalIdTypes";
import { useOrganisationDefaultReadOnlyProps } from "../../organisation/details/organisationDetailViewProps";
import useRequiresLegalNameInEnglish from "../../organisation/useRequiresLegalNameInEnglish";

export default function useFacilityEdit(): FacilityDetailViewProps {
    const translation = useGtbTranslation();
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedId } = useResolvedRoute();
    const supplyChainOperatorId = getResolvedId("supplyChainOperator");
    const facilityId = getResolvedId("facility");
    const {
        currentUser: { authorisationRole },
    } = useAuth();
    const [requiresLegalNameInEnglish, setRequiresLegalNameInEnglish] = useState(false);

    const { data: supplyChainOperator } = useQuery<SupplyChainOperatorReference>({
        url: "/certificate-service/references/supply-chain-operators/" + supplyChainOperatorId,
    });

    const canSave = useCallback(
        () => {
            if (supplyChainOperator) {
                return hasAccessRight("updateOperationLevelOrganisation", [supplyChainOperator.organisationStatus]);
            }
            return false;
        },
        [hasAccessRight, supplyChainOperator],
    );

    const { detailViewProps, form, initialData, isReadOnly, title, recordNavigationProps } =
        useDomainEntityDetailsEdit<FacilityDetails>({
            baseUrl:
                "/certificate-service/supply-chain-operators/" + supplyChainOperatorId + "/facilities/" + facilityId,
            frontendRoute: "facility",
            recordNavigationDefaultSort: defaultFacilitySortOption,
            titleBuilder: (item) => item.businessName,
            resolver: yupResolver(buildFacilityValidationSchema(requiresLegalNameInEnglish)),
            canSave,
            historyColumns: [
                { identifier: "systemId", title: "facility.history.systemId_column" },
                { identifier: "independentLegalEntity", title: "facility.history.independentLegalEntity_column" },
                { identifier: "businessName", title: "facility.history.businessName_column" },
                { identifier: "legalName", title: "facility.history.legalName_column" },
                { identifier: "legalNameInEnglish", title: "facility.history.legalNameInEnglish_column" },
                { identifier: "addressDetails1", title: "facility.history.addressDetail1_column" },
                { identifier: "addressDetails2", title: "facility.history.addressDetail2_column" },
                { identifier: "addressDetails3", title: "facility.history.addressDetail3_column" },
                { identifier: "latitude", title: "facility.history.latitude_column" },
                { identifier: "longitude", title: "facility.history.longitude_column" },
                { identifier: "postCode", title: "facility.history.postcode_column" },
                { identifier: "town", title: "facility.history.town_column" },
                { identifier: "stateProvince", title: "facility.history.stateProvince_column" },
                {
                    identifier: "organisationalEmail",
                    title: "facility.history.organisationalEmailAddress_column",
                },
                { identifier: "website", title: "facility.history.website_column" },
                {
                    identifier: "organisationalPhoneNumber",
                    title: "facility.history.organisationalPhoneNumber_column",
                },
                { identifier: "organisationStatus", title: "facility.history.status_column" },
                { identifier: "startDate", title: "facility.history.startDate_column" },
                { identifier: "endDate", title: "facility.history.endDate_column" },
                { identifier: "fixedRegistrationId", title: "facility.history.fixedRegistrationId_column" },
                { identifier: "numberOfEmployees", title: "facility.history.numberOfEmployees_column" },
            ],
        });

    const { typeOfRegistrationId, isFixedRegistrationIdReadOnly } = useTypeOfRegistrationId(form);

    const independentLegalEntity = form.form.watch("independentLegalEntity");

    const defaultReadOnlyProps = useOrganisationDefaultReadOnlyProps(isReadOnly);

    useEffect(() => {
        if (independentLegalEntity === false) {
            form.form.setValue("legalName", null, {
                shouldDirty: true,
            });
        }
    }, [form.form, independentLegalEntity]);

    const readOnlyProps = useMemo<FacilityReadOnlyProps>(
        () => ({
            ...defaultReadOnlyProps,
            isFixedRegistrationIdReadOnly: isReadOnly || isFixedRegistrationIdReadOnly,
            isMainFacilityReadOnly: isReadOnly,
            isBrandNamesReadOnly: isReadOnly,
            isMembershipReadOnly: isReadOnly,
            isPublicContactPersonNameReadOnly: isReadOnly,
            isPublicContactPersonEmailReadOnly: isReadOnly,
            isOrganisationalContactPersonNameReadOnly: isReadOnly,
            isOrganisationalContactPersonEmailReadOnly: isReadOnly,
            isIndependentLegalEntityReadOnly: isReadOnly,
            isNumberOfEmployeesReadOnly: isReadOnly,
            isCbInternalIdReadOnly: isReadOnly,
            isLegalNameInEnglishReadOnly: isReadOnly,
            isSubEntityReadOnly: isReadOnly,
        }),
        [defaultReadOnlyProps, isFixedRegistrationIdReadOnly, isReadOnly],
    );

    const externalIdComponentType = useMemo<externalIdComponentTypeEnum>(() => {
        if (authorisationRole.type === "CERTIFICATION_BODY") {
            return "FIELD";
        } else if (authorisationRole.type === "SUPPLY_CHAIN_OPERATOR") {
            return "NONE";
        } else {
            return "SUBLIST";
        }
    }, [authorisationRole.type]);

    const createRoute = useMemo<routeEnum | undefined>(() => {
        return hasAccessRight("updateOperationLevelOrganisation", supplyChainOperator ? [supplyChainOperator.organisationStatus] : undefined) ? "facility" : undefined;
    }, [hasAccessRight, supplyChainOperator]);

    useRequiresLegalNameInEnglish(form, setRequiresLegalNameInEnglish);

    return {
        detailViewProps,
        form,
        readOnlyProps,
        title,
        subtitle: translation({
            key: "facility.detail.subtitle",
            options: { systemId: initialData?.["systemId"] },
        }),
        recordNavigationProps,
        createRoute,
        typeOfRegistrationId,
        supplyChainOperatorLegalName: supplyChainOperator?.legalName,
        externalIdComponentType,
        supplyChainOperator: supplyChainOperator?.businessName,
        requiresLegalNameInEnglish,
    };
}
