import DropdownGroup from "../../../components/dropdown/DropdownGroup";
import DropdownLinkItem from "../../../components/dropdown/DropdownLinkItem";
import { getBaseUrl } from "../../../components/routing/useResolvedRoute";
import MainMenuItem from "../MainMenuItem";
import useIsAuthorised from "../../../auth/useIsAuthorised";

export default function OrganisationMenu() {
    const { hasAccessRight } = useIsAuthorised();

    return (
        <MainMenuItem name={"mainLayout.mainMenu.organisations_menuTitle"}>
            <DropdownGroup>
                {hasAccessRight("readOperationLevelOrganisation") && (
                    <>
                        <DropdownLinkItem
                            to={getBaseUrl("supplyChainOperator")}
                            label={"mainLayout.mainMenu.organisations_menuEntries.supplyChainOperators_button"}
                        />
                        <DropdownLinkItem
                            to={getBaseUrl("organicMaterialProducer")}
                            label={"mainLayout.mainMenu.organisations_menuEntries.organicMaterialProducers_button"}
                        />
                    </>
                )}
                {hasAccessRight("readCertificationLevelOrganisation") && (
                    <>
                        <DropdownLinkItem
                            to={getBaseUrl("certificationBody")}
                            label={"mainLayout.mainMenu.organisations_menuEntries.certificationBodies_button"}
                        />
                        <DropdownLinkItem
                            to={getBaseUrl("accreditationBody")}
                            label={"mainLayout.mainMenu.organisations_menuEntries.accreditationBodies_button"}
                        />
                    </>
                )}
                {hasAccessRight("readStandardBody") && (
                    <DropdownLinkItem
                        to={getBaseUrl("standardBody")}
                        label={"mainLayout.mainMenu.organisations_menuEntries.standardBodies_button"}
                    />
                )}
            </DropdownGroup>
            <DropdownGroup>
                {hasAccessRight("relationTypesInMenuSystem") && (
                    <>
                        {hasAccessRight("readOperationLevelOrganisation") && (
                            <>
                                <DropdownLinkItem
                                    to={getBaseUrl("certificationRelation")}
                                    label={
                                        "mainLayout.mainMenu.organisations_menuEntries.certificationRelations_button"
                                    }
                                />
                                <DropdownLinkItem
                                    to={getBaseUrl("subcontractingRelation")}
                                    label={
                                        "mainLayout.mainMenu.organisations_menuEntries.subcontractingRelations_button"
                                    }
                                />
                                <DropdownLinkItem
                                    to={getBaseUrl("buyingRelation")}
                                    label={"mainLayout.mainMenu.organisations_menuEntries.buyingRelations_button"}
                                />
                                <DropdownLinkItem
                                    to={getBaseUrl("supplyingRelation")}
                                    label={"mainLayout.mainMenu.organisations_menuEntries.supplyingRelations_button"}
                                />
                            </>
                        )}
                        {hasAccessRight("readCertificationLevelOrganisation") && (
                            <DropdownLinkItem
                                to={getBaseUrl("accreditationRelation")}
                                label={"mainLayout.mainMenu.organisations_menuEntries.accreditationRelations_button"}
                            />
                        )}
                        {hasAccessRight("readOperationLevelOrganisation") && (
                            <DropdownLinkItem
                                to={getBaseUrl("scoLicence")}
                                label={"mainLayout.mainMenu.organisations_menuEntries.scoLicences_button"}
                            />
                        )}
                    </>
                )}
            </DropdownGroup>
            <DropdownGroup>
                {hasAccessRight("readOperationLevelOrganisation") && (
                    <>
                        <DropdownLinkItem
                            to={getBaseUrl("externalSupplyChainOperatorId")}
                            label={
                                "mainLayout.mainMenu.organisations_menuEntries.externalSupplyChainOperatorIds_button"
                            }
                        />
                        <DropdownLinkItem
                            to={getBaseUrl("externalFacilityId")}
                            label={"mainLayout.mainMenu.organisations_menuEntries.externalFacilityIds_button"}
                        />
                        <DropdownLinkItem
                            to={getBaseUrl("externalOrganicMaterialProducerId")}
                            label={
                                "mainLayout.mainMenu.organisations_menuEntries.externalOrganicMaterialProducerIds_button"
                            }
                        />
                    </>
                )}
            </DropdownGroup>
        </MainMenuItem>
    );
}
