import React from "react";
import { Route, Routes } from "react-router-dom";
import RequireAuth from "./components/routing/RequireAuth";
import HelpView from "./help/display/HelpView";
import MainLayout from "./mainLayout/MainLayout";
import LoginView from "./login/LoginView";
import HomeView from "./home/HomeView";
import LogoutView from "./login/LogoutView";
import UserListView from "./user/UserListView";
import UserEditView from "./user/details/UserEditView";
import UserCreateView from "./user/details/UserCreateView";
import UserProfileView from "./user/details/UserProfileView";
import CountryAreaListView from "./countryArea/CountryAreaListView";
import CountryAreaEditView from "./countryArea/details/CountryAreaEditView";
import CountryAreaCreateView from "./countryArea/details/CountryAreaCreateView";
import StateProvinceEditView from "./stateProvince/details/StateProvinceEditView";
import StateProvinceCreateView from "./stateProvince/details/StateProvinceCreateView";
import AuthorisationRoleListView from "./authorisationRole/AuthorisationRoleListView";
import AuthorisationRoleCreateView from "./authorisationRole/detailView/AuthorisationRoleCreateView";
import AuthorisationRoleEditView from "./authorisationRole/detailView/AuthorisationRoleEditView";
import EventMonitorListView from "./eventMonitor/EventMonitorListView";
import EventMonitorEditView from "./eventMonitor/details/EventMonitorEditView";
import ApplicationLogListView from "./applicationLog/ApplicationLogListView";
import ApplicationLogEditView from "./applicationLog/details/ApplicationLogEditView";
import ForgotPasswordView from "./forgotPassword/ForgotPasswordView";
import ResetPasswordView from "./resetPassword/ResetPasswordView";
import RawMaterialListView from "./rawMaterial/RawMaterialListView";
import RawMaterialEditView from "./rawMaterial/details/RawMaterialEditView";
import RawMaterialCreateView from "./rawMaterial/details/RawMaterialCreateView";
import ProductCategoryListView from "./productCategory/ProductCategoryListView";
import ProductCategoryEditView from "./productCategory/details/ProductCategoryEditView";
import ProductCategoryCreateView from "./productCategory/details/ProductCategoryCreateView";
import ProductDetailListView from "./productDetail/ProductDetailListView";
import ProductDetailEditView from "./productDetail/details/ProductDetailEditView";
import ProductDetailCreateView from "./productDetail/details/ProductDetailCreateView";
import ProcessCategoryListView from "./processCategory/ProcessCategoryListView";
import ProcessCategoryEditView from "./processCategory/details/ProcessCategoryEditView";
import ProcessCategoryCreateView from "./processCategory/details/ProcessCategoryCreateView";
import ProductConversionListView from "./productConversions/ProductConversionListView";
import ProductConversionEditView from "./productConversions/details/ProductConversionEditView";
import ProductConversionCreateView from "./productConversions/details/ProductConversionCreateView";
import StandardListView from "./standard/StandardListView";
import StandardEditView from "./standard/details/StandardEditView";
import StandardCreateView from "./standard/details/StandardCreateView";
import ScopeEditView from "./scope/details/ScopeEditView";
import ScopeCreateView from "./scope/details/ScopeCreateView";
import ByproductInputCombinationListView from "./byproductInputCombination/ByproductInputCombinationListView";
import ByproductInputCombinationEditView from "./byproductInputCombination/detail/ByproductInputCombinationEditView";
import ByproductInputCombinationCreateView from "./byproductInputCombination/detail/ByproductInputCombinationCreateView";
import LabelGradeEditView from "./labelGrade/details/LabelGradeEditView";
import LabelGradeCreateView from "./labelGrade/details/LabelGradeCreateView";
import StandardBodyListView from "./standardBody/StandardBodyListView";
import StandardBodyEditView from "./standardBody/details/StandardBodyEditView";
import StandardBodyCreateView from "./standardBody/details/StandardBodyCreateView";
import VersionEditView from "./standard/version/details/VersionEditView";
import VersionCreateView from "./standard/version/details/VersionCreateView";
import AllowedMaterialEditView from "./allowedMaterial/details/AllowedMaterialEditView";
import AllowedMaterialCreateView from "./allowedMaterial/details/AllowedMaterialCreateView";
import MaterialContentRuleEditView from "./materialContentRule/details/MaterialContentRuleEditView";
import AdditionalMaterialCreateView from "./materialContentRule/additionalMaterial/details/AdditionalMaterialCreateView";
import AdditionalMaterialEditView from "./materialContentRule/additionalMaterial/details/AdditionalMaterialEditView";
import { getBaseUrl, getCreateUrl, getEditUrl, getWildcardUrl } from "./components/routing/useResolvedRoute";
import RefreshView from "./components/RefreshView";
import AccreditationBodyListView from "./accreditationBody/AccreditationBodyListView";
import AccreditationBodyEditView from "./accreditationBody/details/AccreditationBodyEditView";
import AccreditationBodyCreateView from "./accreditationBody/details/AccreditationBodyCreateView";
import CertificationBodyListView from "./certificationBody/CertificationBodyListView";
import CertificationBodyEditView from "./certificationBody/details/CertificationBodyEditView";
import CertificationBodyCreateView from "./certificationBody/details/CertificationBodyCreateView";
import SupplyChainOperatorListView from "./supplyChainOperator/SupplyChainOperatorListView";
import SupplyChainOperatorEditView from "./supplyChainOperator/details/SupplyChainOperatorEditView";
import SupplyChainOperatorCreateView from "./supplyChainOperator/details/SupplyChainOperatorCreateView";
import OrganicMaterialProducerListView from "./organicMaterialProducers/OrganicMaterialProducerListView";
import OrganicMaterialProducerCreateView from "./organicMaterialProducers/details/OrganicMaterialProducerCreateView";
import OrganicMaterialProducerEditView from "./organicMaterialProducers/details/OrganicMaterialProducerEditView";
import CertificationLicenceEditView from "./certificationLicence/details/CertificationLicenceEditView";
import CertificationLicenceCreateView from "./certificationLicence/details/CertificationLicenceCreateView";
import AccreditationRelationListView from "./accreditationRelation/AccreditationRelationListView";
import AccreditationRelationCreateView from "./accreditationRelation/details/AccreditationRelationCreateView";
import AccreditationRelationEditView from "./accreditationRelation/details/AccreditationRelationEditView";
import SubcontractingRelationListView from "./subcontractingRelation/SubcontractingRelationListView";
import SubcontractingRelationEditView from "./subcontractingRelation/details/SubcontractingRelationEditView";
import SubcontractingRelationCreateView from "./subcontractingRelation/details/SubcontractingRelationCreateView";
import FacilityEditView from "./facility/details/FacilityEditView";
import FacilityCreateView from "./facility/details/FacilityCreateView";
import SupplyingRelationListView from "./supplyingRelations/SupplyingRelationListView";
import SupplyingRelationEditView from "./supplyingRelations/details/SupplyingRelationEditView";
import SupplyingRelationCreateView from "./supplyingRelations/details/SupplyingRelationCreateView";
import CertificationRelationCreateView from "./certificationRelation/details/CertificationRelationCreateView";
import CertificationRelationEditView from "./certificationRelation/details/CertificationRelationEditView";
import CertificationRelationListView from "./certificationRelation/CertificationRelationListView";
import useIsAuthorised from "./auth/useIsAuthorised";
import CertificationRelationConfirmationView from "./certificationRelationConfirmation/CertificationRelationConfirmationView";
import ExternalFacilityIdListView from "./externalId/facility/ExternalFacilityIdListView";
import ExternalFacilityIdEditView from "./externalId/facility/details/ExternalFacilityIdEditView";
import ExternalFacilityIdCreateView from "./externalId/facility/details/ExternalFacilityIdCreateView";
import ExternalOrganicMaterialProducerIdListView from "./externalId/organicMaterialProducer/ExternalOrganicMaterialProducerIdListView";
import ExternalOrganicMaterialProducerIdEditView
    from "./externalId/organicMaterialProducer/details/ExternalOrganicMaterialProducerIdEditView";
import ExternalOrganicMaterialProducerIdCreateView
    from "./externalId/organicMaterialProducer/details/ExternalOrganicMaterialProducerIdCreateView";
import ExternalSupplyChainOperatorIdListView from "./externalId/supplyChainOperator/ExternalSupplyChainOperatorIdListView";
import ExternalSupplyChainOperatorIdEditView from "./externalId/supplyChainOperator/details/ExternalSupplyChainOperatorIdEditView";
import ExternalSupplyChainOperatorIdCreateView from "./externalId/supplyChainOperator/details/ExternalSupplyChainOperatorIdCreateView";
import ScoLicenceListView from "./scoLicence/ScoLicenceListView";
import ScoLicenceEditView from "./scoLicence/details/ScoLicenceEditView";
import ScoLicenceCreateView from "./scoLicence/details/ScoLicenceCreateView";
import SubcontractingRelationConfirmationView from "./subcontractingRelationConfirmation/SubcontractingRelationConfirmationView";
import NotFoundErrorPage from "./errorPage/NotFoundErrorPage";
import ForbiddenErrorPage from "./errorPage/ForbiddenErrorPage";
import ScopeCertificateListView from "./scopeCertificate/ScopeCertificateListView";
import ScopeCertificateCreateView from "./scopeCertificate/details/ScopeCertificateCreateView";
import ScopeCertificateEditView from "./scopeCertificate/details/ScopeCertificateEditView";
import StaticPageView from "./components/staticPages/StaticPageView";
import ScProductCreateView from "./scopeCertificate/details/products/details/ScProductCreateView";
import ScProductEditView from "./scopeCertificate/details/products/details/ScProductEditView";
import ScProcessCategoryCreateView from "./scopeCertificate/details/products/details/processCategories/details/ScProcessCategoryCreateView";
import ScProcessCategoryEditView from "./scopeCertificate/details/products/details/processCategories/details/ScProcessCategoryEditView";
import ScMaterialCreateView from "./scopeCertificate/details/products/details/scMaterial/details/ScMaterialCreateView";
import ScMaterialEditView from "./scopeCertificate/details/products/details/scMaterial/details/ScMaterialEditView";
import ScFacilityCreateView from "./scopeCertificate/details/products/details/processCategories/details/facilities/ScFacilityCreateView";
import ScFacilityEditView from "./scopeCertificate/details/products/details/processCategories/details/facilities/ScFacilityEditView";
import MaterialContentRuleCreateView from "./materialContentRule/details/MaterialContentRuleCreateView";
import PreGtbTransactionCertificateListView from "./preGtbTransactionCertificate/PreGtbTransactionCertificateListView";
import PreGtbTransactionCertificateCreateView from "./preGtbTransactionCertificate/details/PreGtbTransactionCertificateCreateView";
import PreGtbTransactionCertificateEditView from "./preGtbTransactionCertificate/details/PreGtbTransactionCertificateEditView";
import PreGtbProductCreateView from "./preGtbTransactionCertificate/details/products/details/PreGtbProductCreateView";
import PreGtbProductEditView from "./preGtbTransactionCertificate/details/products/details/PreGtbProductEditView";
import TransactionCertificateListView from "./transactionCertificate/TransactionCertificateListView";
import TransactionCertificateCreateView from "./transactionCertificate/details/TransactionCertificateCreateView";
import TransactionCertificateEditView from "./transactionCertificate/details/TransactionCertificateEditView";
import BuyingRelationConfirmationView from "./buyingRelation/BuyingRelationConfirmationView";
import BuyingRelationListView from "./buyingRelation/BuyingRelationListView";
import BuyingRelationEditView from "./buyingRelation/details/BuyingRelationEditView";
import BuyingRelationCreateView from "./buyingRelation/details/BuyingRelationCreateView";
import DeduplicationLogListView from "./deduplicationLog/DeduplicationLogListView";
import useAuth from "./auth/useAuth";
import UploadLogListView from "./uploadLog/UploadLogListView";
import PreGtbTcMaterialCreateView from "./preGtbTransactionCertificate/details/materials/details/PreGtbTcMaterialCreateView";
import PreGtbTcMaterialEditView from "./preGtbTransactionCertificate/details/materials/details/PreGtbTcMaterialEditView";
import UploadLogEditView from "./uploadLog/details/UploadLogEditView";
import ShipmentCreateView from "./transactionCertificate/details/shipments/details/ShipmentCreateView";
import ShipmentEditView from "./transactionCertificate/details/shipments/details/ShipmentEditView";
import OrganicMaterialProductionListView from "./organicMaterialProductions/OrganicMaterialProductionListView";
import OrganicMaterialProductionCreateView from "./organicMaterialProductions/details/OrganicMaterialProductionCreateView";
import OrganicMaterialProductionEditView from "./organicMaterialProductions/details/OrganicMaterialProductionEditView";
import SystemParameterView from "./systemparameters/SystemParameterView";
import DeduplicationLogEditView from "./deduplicationLog/details/DeduplicationLogEditView";
import OrganicMaterialProductionScListView from "./organicMaterialProductionScopeCertificate/OrganicMaterialProductionScListView";
import OrganicMaterialProductionScCreateView
    from "./organicMaterialProductionScopeCertificate/details/OrganicMaterialProductionScCreateView";
import OrganicMaterialProductionScEditView from "./organicMaterialProductionScopeCertificate/details/OrganicMaterialProductionScEditView";
import OrganicMaterialPurchaseCreateView from "./organicMaterialPurchase/details/OrganicMaterialPurchaseCreateView";
import OrganicMaterialPurchaseListView from "./organicMaterialPurchase/OrganicMaterialPurchaseListView";
import OrganicMaterialPurchaseEditView from "./organicMaterialPurchase/details/OrganicMaterialPurchaseEditView";
import TcProductCreateView from "./transactionCertificate/details/tcProduct/details/TcProductCreateView";
import TcProductEditView from "./transactionCertificate/details/tcProduct/details/TcProductEditView";
import OrganicMaterialOriginCreateView
    from "./organicMaterialPurchase/details/organicMaterialOrigin/details/OrganicMaterialOriginCreateView";
import OrganicMaterialOriginEditView from "./organicMaterialPurchase/details/organicMaterialOrigin/details/OrganicMaterialOriginEditView";
import TcProductMapView from "./transactionCertificate/details/tcProduct/details/mapView/TcProductMapView";
import InputQuantityCreateView from "./transactionCertificate/details/tcProduct/details/inputQuantity/details/InputQuantityCreateView";
import InputQuantityEditView from "./transactionCertificate/details/tcProduct/details/inputQuantity/details/InputQuantityEditView";
import TcMaterialEditView from "./transactionCertificate/details/tcProduct/details/tcMaterial/details/TcMaterialEditView";
import OrganicMaterialTransactionCertificateCreateView
    from "./organicMaterialPurchase/details/organicMaterialOrigin/details/organicMaterialTransactionCertificate/details/OrganicMaterialTransactionCertificateCreateView";
import OrganicMaterialTransactionCertificateEditView
    from "./organicMaterialPurchase/details/organicMaterialOrigin/details/organicMaterialTransactionCertificate/details/OrganicMaterialTransactionCertificateEditView";
import AllocatedTcNumberList from "./allocatedTcNumber/AllocatedTcNumberListView";
import MailTemplateListView from "./mailTemplate/MailTemplateListView";
import MailTemplateEditView from "./mailTemplate/details/MailTemplateEditView";
import DynamicUserGroupListView from "./dynamicUserGroup/DynamicUserGroupListView";
import DynamicUserGroupCreateView from "./dynamicUserGroup/detail/DynamicUserGroupCreateView";
import DynamicUserGroupEditView from "./dynamicUserGroup/detail/DynamicUserGroupEditView";
import TimeBasedMailListView from "./timeBasedMail/TimeBasedMailListView";
import TimeBasedMailCreateView from "./timeBasedMail/details/TimeBasedMailCreateView";
import TimeBasedMailEditView from "./timeBasedMail/details/TimeBasedMailEditView";

function GtbRoutes() {
    const { hasAccessRight, isSystemAdmin } = useIsAuthorised();
    const { currentUser: { authorisationRole: { level: authorisationLevel } } = { authorisationRole: {} } } = useAuth();

    return (
        <Routes>
            <Route
                path={getWildcardUrl("help")}
                element={<RequireAuth condition={"logged_in"} element={<HelpView />} />}
            />
            <Route path={getBaseUrl("logout")} element={<RequireAuth condition={"all"} element={<LogoutView />} />} />
            <Route
                path={getBaseUrl("login")}
                element={<RequireAuth condition={"not_logged_in"} element={<LoginView />} />}
            />
            <Route
                path={getBaseUrl("resetPassword")}
                element={<RequireAuth condition={"not_logged_in"} element={<ForgotPasswordView />} />}
            />
            <Route
                path={getEditUrl("resetPassword")}
                element={<RequireAuth condition={"not_logged_in"} element={<ResetPasswordView />} />}
            />
            <Route
                path={getEditUrl("certificationRelationConfirmation")}
                element={<RequireAuth condition={"all"} element={<CertificationRelationConfirmationView />} />}
            />
            <Route
                path={getEditUrl("subcontractingRelationConfirmation")}
                element={<RequireAuth condition={"all"} element={<SubcontractingRelationConfirmationView />} />}
            />
            <Route
                path={getEditUrl("buyingRelationConfirmation")}
                element={<RequireAuth condition={"all"} element={<BuyingRelationConfirmationView />} />}
            />
            <Route path={"/refresh"} element={<RequireAuth condition={"all"} element={<RefreshView />} />} />
            <Route path="/" element={<RequireAuth condition={"logged_in"} element={<MainLayout />} />}>
                <Route
                    path={getBaseUrl("homepage")}
                    element={<RequireAuth condition={"logged_in"} element={<HomeView />} />}
                />
                <Route
                    path={getBaseUrl("user")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readUserAccount")}
                            element={<UserListView searchContext="all" />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("searchActiveUser")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readUserAccount")}
                            element={<UserListView searchContext="active" />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("searchInactiveUser")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readUserAccount")}
                            element={<UserListView searchContext="inactive" />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("user")}
                    element={<RequireAuth condition={hasAccessRight("readUserAccount")} element={<UserEditView />} />}
                />
                <Route
                    path={getCreateUrl("user")}
                    element={
                        <RequireAuth condition={hasAccessRight("createUserAccount")} element={<UserCreateView />} />
                    }
                />
                <Route
                    path={getBaseUrl("profile")}
                    element={<RequireAuth condition={"logged_in"} element={<UserProfileView />} />}
                />
                <Route
                    path={getBaseUrl("countryArea")}
                    element={
                        <RequireAuth condition={hasAccessRight("readMasterData")} element={<CountryAreaListView />} />
                    }
                />
                <Route
                    path={getEditUrl("countryArea")}
                    element={
                        <RequireAuth condition={hasAccessRight("readMasterData")} element={<CountryAreaEditView />} />
                    }
                />
                <Route
                    path={getCreateUrl("countryArea")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("createMasterData")}
                            element={<CountryAreaCreateView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("stateProvince")}
                    element={
                        <RequireAuth condition={hasAccessRight("readMasterData")} element={<StateProvinceEditView />} />
                    }
                />
                <Route
                    path={getCreateUrl("stateProvince")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("updateMasterData")}
                            element={<StateProvinceCreateView />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("authorisationRole")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readAuthorisationRole")}
                            element={<AuthorisationRoleListView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("authorisationRole")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("createAuthorisationRole")}
                            element={<AuthorisationRoleCreateView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("authorisationRole")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readAuthorisationRole")}
                            element={<AuthorisationRoleEditView />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("eventMonitor")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("manageEventDetection")}
                            element={<EventMonitorListView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("eventMonitor")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("manageEventDetection")}
                            element={<EventMonitorEditView />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("applicationLog")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("useApplicationLog")}
                            element={<ApplicationLogListView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("applicationLog")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("useApplicationLog")}
                            element={<ApplicationLogEditView />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("rawMaterial")}
                    element={
                        <RequireAuth condition={hasAccessRight("readMasterData")} element={<RawMaterialListView />} />
                    }
                />
                <Route
                    path={getEditUrl("rawMaterial")}
                    element={
                        <RequireAuth condition={hasAccessRight("readMasterData")} element={<RawMaterialEditView />} />
                    }
                />
                <Route
                    path={getCreateUrl("rawMaterial")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("createMasterData")}
                            element={<RawMaterialCreateView />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("productCategory")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readMasterData")}
                            element={<ProductCategoryListView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("productCategory")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readMasterData")}
                            element={<ProductCategoryEditView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("productCategory")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("createMasterData")}
                            element={<ProductCategoryCreateView />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("productDetail")}
                    element={
                        <RequireAuth condition={hasAccessRight("readMasterData")} element={<ProductDetailListView />} />
                    }
                />
                <Route
                    path={getEditUrl("productDetail")}
                    element={
                        <RequireAuth condition={hasAccessRight("readMasterData")} element={<ProductDetailEditView />} />
                    }
                />
                <Route
                    path={getCreateUrl("productDetail")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("createMasterData")}
                            element={<ProductDetailCreateView />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("processCategory")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readMasterData")}
                            element={<ProcessCategoryListView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("processCategory")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readMasterData")}
                            element={<ProcessCategoryEditView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("processCategory")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("createMasterData")}
                            element={<ProcessCategoryCreateView />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("productConversion")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readProductConversion")}
                            element={<ProductConversionListView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("productConversion")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readProductConversion")}
                            element={<ProductConversionEditView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("productConversion")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("createProductConversion")}
                            element={<ProductConversionCreateView />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("standard")}
                    element={
                        <RequireAuth condition={hasAccessRight("readMasterData")} element={<StandardListView />} />
                    }
                />
                <Route
                    path={getEditUrl("standard")}
                    element={
                        <RequireAuth condition={hasAccessRight("readMasterData")} element={<StandardEditView />} />
                    }
                />
                <Route
                    path={getCreateUrl("standard")}
                    element={
                        <RequireAuth condition={hasAccessRight("createMasterData")} element={<StandardCreateView />} />
                    }
                />
                <Route
                    path={getEditUrl("scope")}
                    element={<RequireAuth condition={hasAccessRight("readMasterData")} element={<ScopeEditView />} />}
                />
                <Route
                    path={getCreateUrl("scope")}
                    element={
                        <RequireAuth condition={hasAccessRight("updateMasterData")} element={<ScopeCreateView />} />
                    }
                />
                <Route
                    path={getBaseUrl("byproductInputCombination")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readProductConversion")}
                            element={<ByproductInputCombinationListView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("byproductInputCombination")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readProductConversion")}
                            element={<ByproductInputCombinationEditView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("byproductInputCombination")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("createProductConversion")}
                            element={<ByproductInputCombinationCreateView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("labelGrade")}
                    element={
                        <RequireAuth condition={hasAccessRight("readMasterData")} element={<LabelGradeEditView />} />
                    }
                />
                <Route
                    path={getCreateUrl("labelGrade")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("updateMasterData")}
                            element={<LabelGradeCreateView />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("standardBody")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readStandardBody")}
                            element={<StandardBodyListView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("standardBody")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readStandardBody")}
                            element={<StandardBodyEditView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("standardBody")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("createStandardBody")}
                            element={<StandardBodyCreateView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("standardVersion")}
                    element={<RequireAuth condition={hasAccessRight("readMasterData")} element={<VersionEditView />} />}
                />
                <Route
                    path={getCreateUrl("standardVersion")}
                    element={
                        <RequireAuth condition={hasAccessRight("updateMasterData")} element={<VersionCreateView />} />
                    }
                />
                <Route
                    path={getEditUrl("allowedMaterial")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readMasterData")}
                            element={<AllowedMaterialEditView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("standardAllowedMaterial")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("updateMasterData")}
                            element={<AllowedMaterialCreateView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("rawMaterialAllowedMaterial")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("updateMasterData")}
                            element={<AllowedMaterialCreateView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("materialContentRule")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("updateMasterData")}
                            element={<MaterialContentRuleCreateView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("materialContentRule")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readMasterData")}
                            element={<MaterialContentRuleEditView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("additionalMaterial")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("updateMasterData")}
                            element={<AdditionalMaterialCreateView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("additionalMaterial")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readMasterData")}
                            element={<AdditionalMaterialEditView />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("accreditationBody")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readCertificationLevelOrganisation")}
                            element={<AccreditationBodyListView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("accreditationBody")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readCertificationLevelOrganisation")}
                            element={<AccreditationBodyEditView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("accreditationBody")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("createCertificationLevelOrganisation")}
                            element={<AccreditationBodyCreateView />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("accreditationRelation")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readCertificationLevelOrganisation")}
                            element={<AccreditationRelationListView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("accreditationRelation")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readCertificationLevelOrganisation")}
                            element={<AccreditationRelationEditView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("accreditationRelation")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("updateCertificationLevelOrganisation")}
                            element={<AccreditationRelationCreateView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("certificationBodyAccreditationRelation")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readCertificationLevelOrganisation")}
                            element={<AccreditationRelationEditView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("certificationBodyAccreditationRelation")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("updateCertificationLevelOrganisation")}
                            element={<AccreditationRelationCreateView />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("certificationBody")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readCertificationLevelOrganisation")}
                            element={<CertificationBodyListView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("certificationBody")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readCertificationLevelOrganisation")}
                            element={<CertificationBodyEditView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("certificationBody")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("createCertificationLevelOrganisation")}
                            element={<CertificationBodyCreateView />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("supplyChainOperator")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readOperationLevelOrganisation")}
                            element={<SupplyChainOperatorListView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("supplyChainOperator")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readOperationLevelOrganisation")}
                            element={<SupplyChainOperatorEditView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("supplyChainOperator")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("createOperationLevelOrganisation")}
                            element={<SupplyChainOperatorCreateView />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("supplyingRelation")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readOperationLevelOrganisation")}
                            element={<SupplyingRelationListView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("supplyingRelation")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readOperationLevelOrganisation")}
                            element={<SupplyingRelationEditView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("supplyingRelation")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("updateOperationLevelOrganisation")}
                            element={<SupplyingRelationCreateView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("supplyChainOperatorSupplyingRelation")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("updateOperationLevelOrganisation")}
                            element={<SupplyingRelationCreateView />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("scoLicence")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readOperationLevelOrganisation")}
                            element={<ScoLicenceListView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("scoLicenceNumber")}
                    element={
                        <RequireAuth condition={hasAccessRight("readOperationLevelOrganisation")} element={<ScoLicenceEditView />} />
                    }
                />
                <Route
                    path={getCreateUrl("scoLicenceNumber")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("updateOperationLevelOrganisation")}
                            element={<ScoLicenceCreateView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("organicMaterialOrigin")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("updateOperationLevelOrganisation")}
                            element={<OrganicMaterialOriginCreateView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("organicMaterialOrigin")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readOperationLevelOrganisation")}
                            element={<OrganicMaterialOriginEditView />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("organicMaterialProducer")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readOperationLevelOrganisation")}
                            element={<OrganicMaterialProducerListView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("organicMaterialProducer")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readOperationLevelOrganisation")}
                            element={<OrganicMaterialProducerEditView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("organicMaterialProducer")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("createOperationLevelOrganisation")}
                            element={<OrganicMaterialProducerCreateView />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("organicMaterialProduction")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readOperationLevelOrganisation")}
                            element={<OrganicMaterialProductionListView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("organicMaterialProduction")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readOperationLevelOrganisation")}
                            element={<OrganicMaterialProductionEditView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("organicMaterialProductionScOrganicMaterialProduction")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("updateOperationLevelOrganisation")}
                            element={<OrganicMaterialProductionCreateView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("organicMaterialTransactionCertificate")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("updateOperationLevelOrganisation")}
                            element={<OrganicMaterialTransactionCertificateCreateView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("organicMaterialTransactionCertificate")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readOperationLevelOrganisation")}
                            element={<OrganicMaterialTransactionCertificateEditView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("facility")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readOperationLevelOrganisation")}
                            element={<FacilityEditView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("facility")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("updateOperationLevelOrganisation")}
                            element={<FacilityCreateView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("certificationLicence")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readCertificationLevelOrganisation")}
                            element={<CertificationLicenceEditView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("certificationLicence")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("updateCertificationLevelOrganisation")}
                            element={<CertificationLicenceCreateView />}
                        />
                    }
                />

                <Route
                    path={getBaseUrl("certificationRelation")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readOperationLevelOrganisation")}
                            element={<CertificationRelationListView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("certificationRelation")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readOperationLevelOrganisation")}
                            element={<CertificationRelationEditView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("certificationRelation")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("updateOperationLevelOrganisation")}
                            element={<CertificationRelationCreateView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("supplyChainOperatorCertificationRelation")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("updateOperationLevelOrganisation")}
                            element={<CertificationRelationCreateView />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("subcontractingRelation")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readOperationLevelOrganisation")}
                            element={<SubcontractingRelationListView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("subcontractingRelation")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readOperationLevelOrganisation")}
                            element={<SubcontractingRelationEditView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("subcontractingRelation")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("updateOperationLevelOrganisation")}
                            element={<SubcontractingRelationCreateView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("supplyChainOperatorSubcontractingRelation")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("updateOperationLevelOrganisation")}
                            element={<SubcontractingRelationCreateView />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("buyingRelation")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readOperationLevelOrganisation")}
                            element={<BuyingRelationListView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("buyingRelation")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readOperationLevelOrganisation")}
                            element={<BuyingRelationEditView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("buyingRelation")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("updateOperationLevelOrganisation")}
                            element={<BuyingRelationCreateView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("supplyChainOperatorBuyingRelation")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("updateOperationLevelOrganisation")}
                            element={<BuyingRelationCreateView />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("externalFacilityId")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readOperationLevelOrganisation")}
                            element={<ExternalFacilityIdListView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("externalFacilityId")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readOperationLevelOrganisation")}
                            element={<ExternalFacilityIdEditView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("externalFacilityId")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("updateOperationLevelOrganisation")}
                            element={<ExternalFacilityIdCreateView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("facilityExternalFacilityId")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readOperationLevelOrganisation")}
                            element={<ExternalFacilityIdEditView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("facilityExternalFacilityId")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("updateOperationLevelOrganisation")}
                            element={<ExternalFacilityIdCreateView />}
                        />
                    }
                />

                <Route
                    path={getBaseUrl("externalOrganicMaterialProducerId")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readOperationLevelOrganisation")}
                            element={<ExternalOrganicMaterialProducerIdListView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("externalOrganicMaterialProducerId")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readOperationLevelOrganisation")}
                            element={<ExternalOrganicMaterialProducerIdEditView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("externalOrganicMaterialProducerId")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("updateOperationLevelOrganisation")}
                            element={<ExternalOrganicMaterialProducerIdCreateView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("organicMaterialProducerExternalOrganicMaterialProducerId")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readOperationLevelOrganisation")}
                            element={<ExternalOrganicMaterialProducerIdEditView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("organicMaterialProducerExternalOrganicMaterialProducerId")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("updateOperationLevelOrganisation")}
                            element={<ExternalOrganicMaterialProducerIdCreateView />}
                        />
                    }
                />

                <Route
                    path={getBaseUrl("externalSupplyChainOperatorId")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readOperationLevelOrganisation")}
                            element={<ExternalSupplyChainOperatorIdListView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("externalSupplyChainOperatorId")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readOperationLevelOrganisation")}
                            element={<ExternalSupplyChainOperatorIdEditView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("externalSupplyChainOperatorId")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("updateOperationLevelOrganisation")}
                            element={<ExternalSupplyChainOperatorIdCreateView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("supplyChainOperatorExternalSupplyChainOperatorId")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readOperationLevelOrganisation")}
                            element={<ExternalSupplyChainOperatorIdEditView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("supplyChainOperatorExternalSupplyChainOperatorId")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("updateOperationLevelOrganisation")}
                            element={<ExternalSupplyChainOperatorIdCreateView />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("scopeCertificate")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readScopeCertificate")}
                            element={<ScopeCertificateListView searchContext={"all"} />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("searchInPreparationScopeCertificate")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readScopeCertificate", ["DRAFT", "REJECTED"])}
                            element={<ScopeCertificateListView searchContext={"in_preparation"} />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("searchAwaitingApprovalScopeCertificate")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readScopeCertificate", ["AWAITING_APPROVAL"])}
                            element={<ScopeCertificateListView searchContext={"awaiting_approval"} />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("searchActiveScopeCertificate")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readScopeCertificate", ["VALID", "IN_RECERTIFICATION"])}
                            element={<ScopeCertificateListView searchContext={"active"} />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("searchPendingScopeCertificate")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readScopeCertificate", ["PENDING"])}
                            element={<ScopeCertificateListView searchContext={"pending"} />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("searchExpiredScopeCertificate")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readScopeCertificate", ["EXPIRED"])}
                            element={<ScopeCertificateListView searchContext={"expired"} />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("searchObsoleteScopeCertificate")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readScopeCertificate")}
                            element={<ScopeCertificateListView searchContext={"obsolete"} />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("searchSuspendedScopeCertificate")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readScopeCertificate", ["SUSPENDED"])}
                            element={<ScopeCertificateListView searchContext={"suspended"} />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("searchWithdrawnScopeCertificate")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readScopeCertificate", ["WITHDRAWN"])}
                            element={<ScopeCertificateListView searchContext={"withdrawn"} />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("searchClientActiveScopeCertificate")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readScopeCertificate", ["VALID", "IN_RECERTIFICATION"])}
                            element={<ScopeCertificateListView searchContext={"client_active"} />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("searchClientPendingScopeCertificate")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readScopeCertificate", ["PENDING"])}
                            element={<ScopeCertificateListView searchContext={"client_pending"} />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("searchClientInPreparationScopeCertificate")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readScopeCertificate", ["DRAFT", "REJECTED"])}
                            element={<ScopeCertificateListView searchContext={"client_in_preparation"} />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("searchClientAwaitingApprovalScopeCertificate")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readScopeCertificate", ["AWAITING_APPROVAL"])}
                            element={<ScopeCertificateListView searchContext={"client_awaiting_approval"} />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("searchClientSuspendedScopeCertificate")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readScopeCertificate", ["SUSPENDED"])}
                            element={<ScopeCertificateListView searchContext={"client_suspended"} />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("searchClientWithdrawnScopeCertificate")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readScopeCertificate", ["WITHDRAWN"])}
                            element={<ScopeCertificateListView searchContext={"client_withdrawn"} />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("scopeCertificate")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("createScopeCertificate")}
                            element={<ScopeCertificateCreateView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("scopeCertificate")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readScopeCertificate")}
                            element={<ScopeCertificateEditView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("supplyChainOperatorScopeCertificate")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("createScopeCertificate")}
                            element={<ScopeCertificateCreateView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("scProduct")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("updateScopeCertificate")}
                            element={<ScProductCreateView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("scProduct")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readScopeCertificate")}
                            element={<ScProductEditView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("scProcessCategory")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("updateScopeCertificate")}
                            element={<ScProcessCategoryCreateView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("scProcessCategory")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readScopeCertificate")}
                            element={<ScProcessCategoryEditView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("scMaterial")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("updateScopeCertificate")}
                            element={<ScMaterialCreateView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("scMaterial")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readScopeCertificate")}
                            element={<ScMaterialEditView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("scFacility")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("updateScopeCertificate")}
                            element={<ScFacilityCreateView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("scFacility")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readScopeCertificate")}
                            element={<ScFacilityEditView />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("preGtbTransactionCertificate")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readPreGtbTc")}
                            element={<PreGtbTransactionCertificateListView searchContext={"all"} />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("searchPreliminaryPreGtbTcs")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readPreGtbTc", ["PRELIMINARY"])}
                            element={<PreGtbTransactionCertificateListView searchContext={"preliminary"} />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("searchFinalPreGtbTcs")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readPreGtbTc", ["FINAL"])}
                            element={<PreGtbTransactionCertificateListView searchContext={"final"} />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("searchWithdrawnPreGtbTcs")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readPreGtbTc", ["WITHDRAWN"])}
                            element={<PreGtbTransactionCertificateListView searchContext={"withdrawn"} />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("preGtbTransactionCertificate")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("createPreGtbTc")}
                            element={<PreGtbTransactionCertificateCreateView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("supplyChainOperatorPreGtbTransactionCertificate")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("createPreGtbTc")}
                            element={<PreGtbTransactionCertificateCreateView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("preGtbTransactionCertificate")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readPreGtbTc")}
                            element={<PreGtbTransactionCertificateEditView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("preGtbProduct")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("updatePreGtbTc")}
                            element={<PreGtbProductCreateView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("preGtbProduct")}
                    element={
                        <RequireAuth condition={hasAccessRight("readPreGtbTc")} element={<PreGtbProductEditView />} />
                    }
                />
                <Route
                    path={getCreateUrl("preGtbTcMaterial")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("updatePreGtbTc")}
                            element={<PreGtbTcMaterialCreateView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("preGtbTcMaterial")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readPreGtbTc")}
                            element={<PreGtbTcMaterialEditView />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("deduplicationLog")}
                    element={
                        <RequireAuth
                            condition={authorisationLevel === 1 || authorisationLevel === 2}
                            element={<DeduplicationLogListView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("deduplicationLog")}
                    element={
                        <RequireAuth
                            condition={authorisationLevel === 1 || authorisationLevel === 2}
                            element={<DeduplicationLogEditView />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("transactionCertificate")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readTransactionCertificate")}
                            element={<TransactionCertificateListView searchContext={"ALL"} />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("searchInPreparationTransactionCertificate")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readTransactionCertificate", ["DRAFT", "REJECTED"])}
                            element={<TransactionCertificateListView searchContext={"IN_PREPARATION"} />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("searchAwaitingApprovalTransactionCertificate")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readTransactionCertificate", ["AWAITING_APPROVAL"])}
                            element={<TransactionCertificateListView searchContext={"AWAITING_APPROVAL"} />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("searchValidTransactionCertificate")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readTransactionCertificate", ["VALID"])}
                            element={<TransactionCertificateListView searchContext={"VALID"} />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("searchWithdrawnTransactionCertificate")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readTransactionCertificate", ["WITHDRAWN"])}
                            element={<TransactionCertificateListView searchContext={"WITHDRAWN"} />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("searchValidClientTransactionCertificate")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readTransactionCertificate", ["VALID"])}
                            element={<TransactionCertificateListView searchContext={"VALID_CLIENT"} />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("searchWithdrawnClientTransactionCertificate")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readTransactionCertificate", ["WITHDRAWN"])}
                            element={<TransactionCertificateListView searchContext={"WITHDRAWN_CLIENT"} />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("searchAwaitingApprovalClientTransactionCertificate")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readTransactionCertificate", ["AWAITING_APPROVAL"])}
                            element={<TransactionCertificateListView searchContext={"AWAITING_APPROVAL_CLIENT"} />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("searchInPreparationClientTransactionCertificate")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readTransactionCertificate", ["REJECTED", "DRAFT"])}
                            element={<TransactionCertificateListView searchContext={"IN_PREPARATION_CLIENT"} />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("transactionCertificate")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("createTransactionCertificate")}
                            element={<TransactionCertificateCreateView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("supplyChainOperatorTransactionCertificate")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("createTransactionCertificate")}
                            element={<TransactionCertificateCreateView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("transactionCertificate")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readTransactionCertificate")}
                            element={<TransactionCertificateEditView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("shipment")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("updateTransactionCertificate")}
                            element={<ShipmentCreateView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("shipment")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readTransactionCertificate")}
                            element={<ShipmentEditView />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("organicMaterialProductionSc")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readOperationLevelOrganisation")}
                            element={<OrganicMaterialProductionScListView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("organicMaterialProductionSc")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("updateOperationLevelOrganisation")}
                            element={<OrganicMaterialProductionScCreateView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("organicMaterialProductionSc")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readOperationLevelOrganisation")}
                            element={<OrganicMaterialProductionScEditView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("organicMaterialProducerScopeCertificate")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("updateOperationLevelOrganisation")}
                            element={<OrganicMaterialProductionScCreateView />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("organicMaterialPurchase")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readOperationLevelOrganisation")}
                            element={<OrganicMaterialPurchaseListView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("supplyChainOperatorOrganicMaterialPurchase")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("updateOperationLevelOrganisation")}
                            element={<OrganicMaterialPurchaseCreateView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("organicMaterialPurchase")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readOperationLevelOrganisation")}
                            element={<OrganicMaterialPurchaseEditView />}
                        />
                    }
                />

                <Route
                    path={getCreateUrl("tcProduct")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("updateTransactionCertificate")}
                            element={<TcProductCreateView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("tcProduct")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readTransactionCertificate")}
                            element={<TcProductEditView />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("tcProductMap")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readTransactionCertificate")}
                            element={<TcProductMapView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("tcMaterial")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readTransactionCertificate")}
                            element={<TcMaterialEditView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("inputQuantity")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("updateTransactionCertificate")}
                            element={<InputQuantityCreateView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("inputQuantity")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readTransactionCertificate")}
                            element={<InputQuantityEditView />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("errorNotFound")}
                    element={<RequireAuth condition={"logged_in"} element={<NotFoundErrorPage />} />}
                />
                <Route
                    path={getBaseUrl("errorForbidden")}
                    element={<RequireAuth condition={"logged_in"} element={<ForbiddenErrorPage />} />}
                />
                <Route
                    path={getBaseUrl("systemParameters")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("manageSystemParameter")}
                            element={<SystemParameterView />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("allocatedTcNumber")}
                    element={
                        <RequireAuth
                            condition={authorisationLevel === 1 || authorisationLevel === 2}
                            element={<AllocatedTcNumberList />}
                        />
                    }
                />
                <Route
                    path={getBaseUrl("uploadLog")}
                    element={<RequireAuth condition={hasAccessRight("useUploadLog")} element={<UploadLogListView />} />}
                />
                <Route
                    path={getEditUrl("uploadLog")}
                    element={<RequireAuth condition={hasAccessRight("useUploadLog")} element={<UploadLogEditView />} />}
                />
                <Route
                    path={getBaseUrl("mailTemplate")}
                    element={
                        <RequireAuth condition={hasAccessRight("useMailTemplate")} element={<MailTemplateListView />} />
                    }
                />
                <Route
                    path={getEditUrl("mailTemplate")}
                    element={
                        <RequireAuth condition={hasAccessRight("useMailTemplate")} element={<MailTemplateEditView />} />
                    }
                />
                <Route
                    path={getBaseUrl("dynamicUserGroup")}
                    element={<RequireAuth condition={isSystemAdmin} element={<DynamicUserGroupListView />} />}
                />
                <Route
                    path={getCreateUrl("dynamicUserGroup")}
                    element={<RequireAuth condition={isSystemAdmin} element={<DynamicUserGroupCreateView />} />}
                />
                <Route
                    path={getEditUrl("dynamicUserGroup")}
                    element={<RequireAuth condition={isSystemAdmin} element={<DynamicUserGroupEditView />} />}
                />

                <Route
                    path={getBaseUrl("timeBasedMail")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readTimeBasedMail")}
                            element={<TimeBasedMailListView />}
                        />
                    }
                />
                <Route
                    path={getEditUrl("timeBasedMail")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("readTimeBasedMail")}
                            element={<TimeBasedMailEditView />}
                        />
                    }
                />
                <Route
                    path={getCreateUrl("timeBasedMail")}
                    element={
                        <RequireAuth
                            condition={hasAccessRight("createTimeBasedMail")}
                            element={<TimeBasedMailCreateView />}
                        />
                    }
                />
            </Route>
            <Route path="*" element={<RequireAuth condition={"not_logged_in"} element={<LoginView />} />} />
            <Route path="/imprint" element={<StaticPageView targetPage="imprint" />} />
            <Route path="/privacy-policy" element={<StaticPageView targetPage="privacyPolicy" />} />
            <Route path="/terms-of-use" element={<StaticPageView targetPage="termsOfUse" />} />
            <Route path="/how-to-apply" element={<StaticPageView targetPage="howToApply" />} />
        </Routes>
    );
}

export default GtbRoutes;
