import { StateProvinceDetails } from "../stateProvinceTypes";
import { yupResolver } from "@hookform/resolvers/yup";
import { stateProvinceValidationSchema } from "./stateProvinceValidationSchema";
import { useQuery } from "../../hooks/useAxios";
import { CountryAreaDetails } from "../../countryArea/countryAreaTypes";
import useIsAuthorised from "../../auth/useIsAuthorised";
import { defaultStateProvinceSortOption } from "../useStateProvinceSubList";
import useResolvedRoute, { routeEnum } from "../../components/routing/useResolvedRoute";
import { useMemo } from "react";
import useGtbTranslation from "../../i18n/useGtbTranslation";
import useDomainEntityDetailsEdit from "../../hooks/details/useDomainEntityDetailsEdit";
import { buildTranslatedHistoryColumns } from "../../i18n/languageUtils";
import useLanguage from "../../i18n/LanguageContextProvider";

export default function useStateProvinceEdit() {
    const translation = useGtbTranslation();
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedId } = useResolvedRoute();
    const currentLanguage = useLanguage();
    const countryAreaId = getResolvedId("countryArea");
    const stateProvinceId = getResolvedId("stateProvince");

    const { data: countryArea } = useQuery<CountryAreaDetails>({
        url: "/certificate-service/references/countries/" + countryAreaId,
    });

    const { detailViewProps, form, initialData, isReadOnly, title, recordNavigationProps } =
        useDomainEntityDetailsEdit<StateProvinceDetails>({
            baseUrl: "/certificate-service/countries/" + countryAreaId + "/states-provinces/" + stateProvinceId,
            resolver: yupResolver(stateProvinceValidationSchema),
            frontendRoute: "stateProvince",
            recordNavigationDefaultSort: defaultStateProvinceSortOption,
            titleBuilder: (item) => item.name[currentLanguage],
            canSave: hasAccessRight("updateMasterData"),
            historyColumns: [
                { identifier: "countryArea", title: "stateProvince.history.countryArea_column" },
                ...buildTranslatedHistoryColumns<StateProvinceDetails>("stateProvince.history.name_column", "name"),
                { identifier: "isoCode", title: "stateProvince.history.isoCode_column" },
                { identifier: "startDate", title: "stateProvince.history.startDate_column" },
                { identifier: "endDate", title: "stateProvince.history.endDate_column" },
            ],
        });

    const createRoute = useMemo<routeEnum | undefined>(() => {
        return hasAccessRight("updateMasterData") ? "stateProvince" : undefined;
    }, [hasAccessRight]);

    return {
        detailViewProps,
        form,
        isReadOnly,
        title,
        subtitle: translation({
            key: "stateProvince.detail.subtitle",
            options: { isoCode: initialData?.isoCode },
        }),
        countryAreaName: `${countryArea?.name}`,
        recordNavigationProps,
        createRoute,
    };
}
