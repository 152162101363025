import { useMemo } from "react";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import { SortOption, useQueryBuilder } from "../components/grid/component/useQueryBuilder";
import useResolvedRoute from "../components/routing/useResolvedRoute";
import { SubListProps } from "../components/grid/SubList";
import { BuyingRelationDetails, BuyingRelationSummary } from "./buyingRelationTypes";
import { defaultBuyingRelationSortOption } from "./useBuyingRelationListView";
import { GridColumn } from "../components/grid/component/Grid";
import { buildDataColumn, buildDateColumn, buildTextColumn } from "../components/grid/ColumnProvider";
import { I18nKey } from "../i18n/useGtbTranslation";
import { EnumEntityType } from "../components/Input/pickList/typedEnumPicklist/enumEntityType";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

const commonColumns: GridColumn<BuyingRelationSummary>[] = [
    buildTextColumn({
        key: "standardAcronym",
        title: "buyingRelation.list.standard_column",
        minWidth: 123,
    }),
    buildDataColumn<BuyingRelationSummary, EnumEntityType>({
        key: "accessLevel",
        title: "buyingRelation.list.accessLevel_column",
        minWidth: 137,
        url: "/certificate-service/references/buying-relation-access-levels",
        itemId: "id",
        itemLabel: "name",
    }),
    buildDataColumn<BuyingRelationSummary, EnumEntityType>({
        key: "confirmationMethod",
        title: "buyingRelation.list.confirmationMethod_column",
        minWidth: 202,
        itemId: "id",
        itemLabel: "name",
        url: "/certificate-service/references/buying-relation-confirmation-methods",
    }),
    buildDateColumn({
        key: "startDate",
        title: "buyingRelation.list.startDate_column",
        minWidth: 130,
    }),
    buildDateColumn({
        key: "endDate",
        title: "buyingRelation.list.endDate_column",
        minWidth: 124,
    }),
];

export default function useBuyingRelationSubList({
                                                     firstColumn,
                                                     baseQueryParamKey,
                                                     baseQueryParamValue,
                                                     header,
                                                     tableName,
                                                     createUrl,
                                                     sortOption = defaultBuyingRelationSortOption,
                                                     readOnly,
                                                 }: UseBuyingRelationsSubListProps) {
    const { getResolvedEditUrl } = useResolvedRoute();

    const columns = useMemo<GridColumn<BuyingRelationSummary>[]>(() => {
        return [firstColumn, ...commonColumns];
    }, [firstColumn]);

    const { query, columnOptions, forceReload } = useQueryBuilder<BuyingRelationSummary>(
        "/certificate-service/buying-relations",
        {
            sort: sortOption,
            filterActive: false,
            [baseQueryParamKey]: baseQueryParamValue,
        },
    );

    const showDetailsAction = useShowDetailsAction(
        (item: BuyingRelationSummary) => getResolvedEditUrl("buyingRelation", item.id),
        query.parameter,
    );

    const deleteAction = useDeleteAction(
        (item: BuyingRelationSummary) => `/certificate-service/buying-relations/${item.id}`,
        () => forceReload(),
        "updateOperationLevelOrganisation",
    );

    const contextMenuActions = useGridRowContextMenuActions(showDetailsAction, readOnly ? undefined : deleteAction);

    return useMemo<SubListProps<BuyingRelationSummary>>(
        () => ({
            query,
            columns,
            uniqueDataKey: "id",
            onItemDoubleClick: showDetailsAction,
            header,
            itemActions: contextMenuActions,
            columnOptions,
            createUrl: !readOnly ? createUrl : undefined,
            tableName: tableName,
        }),
        [
            columnOptions,
            columns,
            contextMenuActions,
            createUrl,
            readOnly,
            header,
            query,
            showDetailsAction,
            tableName,
        ],
    );
}

export interface UseBuyingRelationsSubListProps {
    firstColumn: GridColumn<BuyingRelationSummary>;
    baseQueryParamKey: "sellerSupplyChainOperatorId" | "buyerSupplyChainOperatorId";
    baseQueryParamValue?: string;
    header: I18nKey;
    tableName: string;
    sortOption?: SortOption<BuyingRelationSummary | BuyingRelationDetails>;
    createUrl?: string;
    readOnly: boolean;
}
