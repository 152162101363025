import { yupResolver } from "@hookform/resolvers/yup";
import useIsAuthorised from "../../auth/useIsAuthorised";
import { RawMaterialDetails } from "../rawMaterialTypes";
import { rawMaterialValidationSchema } from "./rawMaterialValidationSchema";
import { defaultRawMaterialSortOption } from "../useRawMaterialListView";
import useResolvedRoute, { routeEnum } from "../../components/routing/useResolvedRoute";
import { useMemo } from "react";
import useGtbTranslation from "../../i18n/useGtbTranslation";
import useDomainEntityDetailsEdit from "../../hooks/details/useDomainEntityDetailsEdit";
import { buildTranslatedHistoryColumns } from "../../i18n/languageUtils";
import useLanguage from "../../i18n/LanguageContextProvider";
import { RawMaterialDetailViewProps } from "./RawMaterialDetailView";

export default function useRawMaterialEdit(): RawMaterialDetailViewProps {
    const translation = useGtbTranslation();
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedId } = useResolvedRoute();
    const currentLanguage = useLanguage();
    const rawMaterialId = getResolvedId("rawMaterial");

    const { detailViewProps, form, initialData, isReadOnly, title, recordNavigationProps } =
        useDomainEntityDetailsEdit<RawMaterialDetails>({
            baseUrl: "/certificate-service/raw-materials/" + rawMaterialId,
            frontendRoute: "rawMaterial",
            titleBuilder: (item) => item.name[currentLanguage],
            recordNavigationDefaultSort: defaultRawMaterialSortOption,
            resolver: yupResolver(rawMaterialValidationSchema),
            canSave: hasAccessRight("updateMasterData"),
            historyColumns: [
                { identifier: "code", title: "rawMaterial.history.code_column" },
                ...buildTranslatedHistoryColumns<RawMaterialDetails>("rawMaterial.history.name_column", "name"),
                { identifier: "pickListSequence", title: "rawMaterial.history.pickListSequence_column" },
                {
                    identifier: "printNameMandatory",
                    title: "rawMaterial.history.printNameMandatory_column",
                },
                { identifier: "typeOfMaterial", title: "rawMaterial.history.typeOfMaterial_column" },
                { identifier: "startDate", title: "rawMaterial.history.startDate_column" },
                { identifier: "endDate", title: "rawMaterial.history.endDate_column" },
            ],
        });

    const createRoute = useMemo<routeEnum | undefined>(() => {
        return hasAccessRight("createMasterData") ? "rawMaterial" : undefined;
    }, [hasAccessRight]);

    return {
        detailViewProps,
        form,
        isReadOnly,
        subListVisible: true,
        title,
        subtitle: translation({ key: "rawMaterial.detail.subtitle", options: { code: initialData?.["code"] } }),
        recordNavigationProps,
        createRoute,
    };
}
