import { buildTextColumn, ColumnProps } from "../components/grid/ColumnProvider";
import { useMemo } from "react";
import useIsAuthorised from "../auth/useIsAuthorised";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import { SortOption, useQueryBuilder } from "../components/grid/component/useQueryBuilder";
import { AllowedMaterialDetails, AllowedMaterialSummary } from "./allowedMaterialTypes";
import useResolvedRoute from "../components/routing/useResolvedRoute";
import { SubListProps } from "../components/grid/SubList";
import { GridColumn } from "../components/grid/component/Grid";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

export default function useAllowedMaterialSubList({
                                                      baseQueryParamKey,
                                                      baseQueryParamValue,
                                                      sortOption,
                                                      column,
                                                      createUrl,
                                                  }: UseAllowedMaterialSubListProps) {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedEditUrl } = useResolvedRoute();

    const { query, columnOptions, forceReload } = useQueryBuilder<AllowedMaterialSummary>(
        `/certificate-service/allowed-materials`,
        {
            sort: sortOption,
            filterActive: false,
            [baseQueryParamKey]: baseQueryParamValue,
        },
    );

    const showDetailsAction = useShowDetailsAction(
        (item: AllowedMaterialSummary) => getResolvedEditUrl("allowedMaterial", item.id),
        query.parameter,
    );

    const deleteAction = useDeleteAction(
        (item: AllowedMaterialSummary) => `/certificate-service/allowed-materials/${item.id}`,
        () => forceReload(),
        "updateMasterData",
    );

    const contextMenuActions = useGridRowContextMenuActions(showDetailsAction, deleteAction);

    const columns = useMemo<GridColumn<AllowedMaterialSummary>[]>(() => [buildTextColumn(column)], [column]);

    return useMemo<SubListProps<AllowedMaterialSummary>>(
        () => ({
            query,
            columns,
            columnOptions,
            uniqueDataKey: "id",
            onItemDoubleClick: showDetailsAction,
            header: "allowedMaterial.list.header",
            tableName: `${baseQueryParamKey}-allowedMaterial`,
            itemActions: contextMenuActions,
            createUrl: hasAccessRight("updateMasterData") ? createUrl : undefined,
        }),
        [
            query,
            columns,
            columnOptions,
            showDetailsAction,
            baseQueryParamKey,
            contextMenuActions,
            hasAccessRight,
            createUrl,
        ],
    );
}

export interface UseAllowedMaterialSubListProps {
    sortOption: SortOption<AllowedMaterialSummary | AllowedMaterialDetails>;
    column: ColumnProps<AllowedMaterialSummary>;
    baseQueryParamValue?: string;
    baseQueryParamKey: "standardId" | "rawMaterialId";
    createUrl: string;
}
