import useIsAuthorised from "../../../auth/useIsAuthorised";
import MainMenuItem from "../MainMenuItem";
import { getBaseUrl } from "../../../components/routing/useResolvedRoute";
import DropdownLinkItem from "../../../components/dropdown/DropdownLinkItem";

export default function RawMaterialProductionMenu() {
    const { hasAccessRight } = useIsAuthorised();

    return (
        <MainMenuItem name="mainLayout.mainMenu.rawMaterialProduction_menuTitle">
            {hasAccessRight("readOperationLevelOrganisation") && (
                <>
                    <DropdownLinkItem
                        to={getBaseUrl("organicMaterialPurchase")}
                        label={"mainLayout.mainMenu.rawMaterialProduction_menuEntries.organicMaterialPurchases_button"}
                    />
                    <DropdownLinkItem
                        label="mainLayout.mainMenu.rawMaterialProduction_menuEntries.organicMaterialProductions_button"
                        to={getBaseUrl("organicMaterialProduction")}
                    />
                    <DropdownLinkItem
                        to={getBaseUrl("organicMaterialProductionSc")}
                        label={
                            "mainLayout.mainMenu.rawMaterialProduction_menuEntries.organicMaterialProductionSCs_button"
                        }
                    />
                </>
            )}
        </MainMenuItem>
    );
}
