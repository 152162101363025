import useDetailsCreate from "../../hooks/details/useDetailsCreate";
import { yupResolver } from "@hookform/resolvers/yup";
import { Nullable } from "../../utils/typeUtils";
import { MaterialContentRuleDetailViewProps } from "./MaterialContentRuleDetailView";
import { LabelGradeReference } from "../../labelGrade/labelGradeTypes";
import { materialContentRuleValidationSchema } from "./materialContentRuleValidationSchema";
import { MaterialContentRuleDetails } from "../materialContentRuleTypes";
import { VersionReference } from "../../standard/version/versionTypes";
import { useMemo } from "react";
import { useQuery } from "../../hooks/useAxios";
import useResolvedRoute from "../../components/routing/useResolvedRoute";

export default function useMaterialContentRuleCreate(): MaterialContentRuleDetailViewProps {
    const { getResolvedId } = useResolvedRoute();
    const standardId = getResolvedId("standard");
    const standardVersionId = getResolvedId("standardVersion");
    const labelGradeId = getResolvedId("labelGrade");

    const { data: standardVersion, isLoading: isVersionLoading } = useQuery<VersionReference>({
        url: "/certificate-service/references/standard-versions/" + standardVersionId,
    });

    const { data: labelGrade, isLoading: isLabelGradeLoading } = useQuery<LabelGradeReference>({
        url: "/certificate-service/references/label-grades/" + labelGradeId,
    });

    const initialData = useMemo<Nullable<MaterialContentRuleDetails>>(() => {
        return {
            id: null,
            systemId: null,
            additionalMaterials: null,
            certifiedMaterial: null,
            certifiedMaterialCode: null,
            certifiedMaterialName: null,
            labelGrade: labelGrade?.id!,
            standard: null,
            standardAcronym: null,
            standardVersionVersionNumber: null,
            standardVersion: labelGrade ? null : standardVersion?.id!,
            labelGradeLabelGrade: null,
        };
    }, [labelGrade, standardVersion]);

    const { detailViewProps, form, title } = useDetailsCreate<MaterialContentRuleDetails>({
        title: "materialContentRule.detail.newRecord_title",
        frontendRoute: "materialContentRule",
        initialData,
        resolver: yupResolver(materialContentRuleValidationSchema),
        saveUrl: `/certificate-service/standards/${standardId}/standard-versions/${standardVersionId}/label-grades/${labelGradeId}/material-content-rules`,
    });

    return useMemo(() => {
        return {
            detailViewProps: {
                ...detailViewProps,
                isLoading: detailViewProps.isLoading || isLabelGradeLoading || isVersionLoading,
            },
            form,
            title,
            readOnly: false,
            standard: standardId,
            standardVersion: standardVersion
                ? `${standardVersion.standardAcronym} ${standardVersion.versionNumber}`
                : "",
            labelGrade,
            createRoute: "materialContentRule",
        };
    }, [isLabelGradeLoading, detailViewProps, form, isVersionLoading, labelGrade, standardId, standardVersion, title]);
}
