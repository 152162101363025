import GtbIconButton from "../GtbIconButton";
import "./detailViewInterface.css";
import useResolvedRoute, { routeEnum } from "../routing/useResolvedRoute";
import useRefreshNavigate from "../routing/useRefreshNavigate";
import useHistoryDialog, { HistoryParams } from "../../history/useHistoryDialog";
import { useState } from "react";
import NoteList from "./note/NoteList";
import { backendUrlType } from "../../hooks/useAxios";
import GoBackButton from "../GoBackButton";
import useRemoveTabindex from "../../hooks/useRemoveTabindex";
import { useBuildArrowNavigationListener } from "../../utils/a11yUtils";
import GtbIconLink from "../routing/GtbIconLink";

function DetailViewInterface({
    canEdit = true,
    undoChanges,
    createRoute,
    clone,
    noteRootUrl,
    historyParams,
    goToMapUrl,
}: {
    canEdit?: boolean;
    undoChanges?: () => void;
    createRoute?: routeEnum;
    clone?: Function;
    noteRootUrl?: backendUrlType;
    historyParams?: HistoryParams;
    goToMapUrl?: string;
}) {
    const { getResolvedCreateUrl } = useResolvedRoute();
    const { refreshNavigate } = useRefreshNavigate();
    const { showDialog: showHistoryDialog } = useHistoryDialog({ historyParams });
    const [notesOpen, setNotesOpen] = useState(false);
    const removeTabindex = useRemoveTabindex<HTMLDivElement>();
    const arrowNavigationListener = useBuildArrowNavigationListener("button", "vertical");

    return (
        <div className="detailViewInterface">
            {notesOpen && <NoteList id="entityComments" noteRootUrl={noteRootUrl!} className="detailViewComments" />}
            <div
                onKeyDown={arrowNavigationListener}
                {...removeTabindex}
                className="detailViewInterfaceButtons"
                role="toolbar"
                aria-orientation="vertical"
            >
                {canEdit && (
                    <GtbIconButton
                        type="submit"
                        label="components.detailView.toolbar.save_tooltip"
                        iconName="floppyDisk"
                    />
                )}
                {createRoute && (
                    <GtbIconButton
                        role="link"
                        label="components.detailView.toolbar.newRecord_tooltip"
                        iconName="new-record"
                        onClick={() => {
                            refreshNavigate(getResolvedCreateUrl(createRoute));
                        }}
                    />
                )}
                {clone && (
                    <GtbIconButton
                        role="link"
                        label="components.detailView.toolbar.clone_tooltip"
                        iconName="clone"
                        onClick={() => clone()}
                    />
                )}
                <GtbIconButton
                    label="components.detailView.toolbar.reset_tooltip"
                    iconName="undo"
                    onClick={undoChanges}
                />
                {noteRootUrl && (
                    <GtbIconButton
                        label="components.detailView.toolbar.note_tooltip"
                        iconName="comment"
                        aria-controls="entityComments"
                        onClick={() => setNotesOpen((prevState) => !prevState)}
                        aria-expanded={notesOpen}
                    />
                )}
                {historyParams && (
                    <GtbIconButton
                        label="components.detailView.toolbar.history_tooltip"
                        iconName="history"
                        onClick={showHistoryDialog}
                    />
                )}
                {goToMapUrl && (
                    <GtbIconLink
                        label="components.detailView.toolbar.showOnMap_tooltip"
                        iconName="locationMarker"
                        to={goToMapUrl}
                    />
                )}
                <GoBackButton />
            </div>
        </div>
    );
}

export default DetailViewInterface;
