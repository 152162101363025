import useDetailsCreate from "../../hooks/details/useDetailsCreate";
import { yupResolver } from "@hookform/resolvers/yup";
import { organicMaterialProductionValidationSchema } from "./organicMaterialProductionValidationSchema";
import { OrganicMaterialProductionDetailViewProps, OrganicMaterialProductionReadOnlyProps } from "./OrganicMaterialProductionDetailView";
import { OrganicMaterialProductionDetails } from "../organicMaterialProductionTypes";
import { Nullable } from "../../utils/typeUtils";
import useConformanceCheck from "../../conformance/useConformanceCheck";
import { useMemo } from "react";
import useResolvedRoute from "../../components/routing/useResolvedRoute";
import { useQuery } from "../../hooks/useAxios";
import { OrganicMaterialProductionScReference } from "../../organicMaterialProductionScopeCertificate/organicMaterialProductionScTypes";

const readOnlyProps: OrganicMaterialProductionReadOnlyProps = {
    isCertifiedOrganicMaterialReadOnly: false,
    isUsdaNopCompliantReadOnly: false,
    isSizeOfProductionAreaReadOnly: false,
    isProductionEstimateReadOnly: false,
    isProductionSoldReadOnly: true,
    isHarvestYearReadOnly: false,
    isTownReadOnly: false,
    isPostCodeReadOnly: false,
    isCountryAreaReadOnly: false,
    isAddressDetails1ReadOnly: false,
    isAddressDetails2ReadOnly: false,
    isAddressDetails3ReadOnly: false,
    isProductionScReadOnly: true,
};

export default function useOrganicMaterialProductionCreate(): OrganicMaterialProductionDetailViewProps {
    const { getResolvedId } = useResolvedRoute();
    const organicMaterialProducerScId = getResolvedId("organicMaterialProductionSc");
    const handleConformanceCheck = useConformanceCheck<OrganicMaterialProductionDetails>();
    const { data: organicMaterialProductionSc } = useQuery<OrganicMaterialProductionScReference>({
        url: "/certificate-service/references/organic-material-production-scs/" + organicMaterialProducerScId,
    });

    const initialData = useMemo<Nullable<OrganicMaterialProductionDetails>>(
        () => ({
            id: null,
            systemId: null,
            organicMaterialProducer: organicMaterialProductionSc?.organicMaterialProducer ?? null,
            organicMaterialProducerBusinessName: null,
            certifiedOrganicMaterial: null,
            usdaNopCompliant: false,
            sizeOfProductionArea: null,
            productionEstimate: null,
            productionSold: null,
            startOfHarvestYear: new Date().getUTCFullYear(),
            town: null,
            postCode: null,
            countryArea: null,
            stateProvince: null,
            addressDetails1: null,
            addressDetails2: null,
            addressDetails3: null,
            frozen: null,
            organicMaterialProducerStatus: null,
        }),
        [organicMaterialProductionSc?.organicMaterialProducer],
    );

    const { detailViewProps, form, title } = useDetailsCreate({
        title: "organicMaterialProduction.detail.newRecord_title",
        frontendRoute: "organicMaterialProduction",
        initialData,
        resolver: yupResolver(organicMaterialProductionValidationSchema),
        saveUrl: `/certificate-service/organic-material-production-scs/${organicMaterialProducerScId}/organic-material-productions`,
        resolveErrors: (error, item, retrySaveAction) => handleConformanceCheck(error, item, retrySaveAction),
    });

    return {
        title,
        detailViewProps,
        form,
        readOnlyProps,
    };
}
