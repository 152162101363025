import useIsAuthorised from "../../../../auth/useIsAuthorised";
import useResolvedRoute from "../../../../components/routing/useResolvedRoute";
import { useCallback, useEffect, useMemo, useState } from "react";
import useDomainEntityDetailsEdit from "../../../../hooks/details/useDomainEntityDetailsEdit";
import { PreGtbTcMaterialDetailViewProps } from "./PreGtbTcMaterialDetailView";
import { PreGtbTcMaterialDetails } from "../preGtbTcMaterialsTypes";
import { defaultPreGtbTcMaterialSortOption } from "../usePreGtbTcMaterialSubList";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { buildPreGtbTcMaterialValidationSchema } from "./preGtbTcMaterialValidationSchema";
import { useQuery } from "../../../../hooks/useAxios";
import { PreGtbProductDetails } from "../../products/preGtbProductTypes";
import useCalculatePreGtbTcMaterialCertifiedWeight from "./useCalculatePreGtbTcMaterialCertifiedWeight";
import { LabelGradeReference } from "../../../../labelGrade/labelGradeTypes";
import { preGtbTransactionCertificateReadOnlyByStatus } from "../../../preGtbTransactionCertificateTypes";
import { useWatch } from "react-hook-form";

export default function usePreGtbTcMaterialEdit(): PreGtbTcMaterialDetailViewProps {
    const [labelGradeTypeOfMaterialId, setLabelGradeTypeOfMaterialId] = useState<string | null>(null);
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedId } = useResolvedRoute();

    const preGtbTcId = getResolvedId("preGtbTransactionCertificate");
    const preGtbProductId = getResolvedId("preGtbProduct");
    const preGtbTcMaterialId = getResolvedId("preGtbTcMaterial");

    const { data: preGtbTcProduct, isLoading: isPreGtbTcProductLoading } = useQuery<PreGtbProductDetails>({
        url: `/certificate-service/pre-gtb-tcs/${preGtbTcId}/pre-gtb-products/${preGtbProductId}`,
    });

    const { isLoading: isLabelGradeLoading, runQuery: runLabelGradeQuery } = useQuery<LabelGradeReference>({
        url: ``,
        enabled: false,
    });

    useEffect(() => {
        if (!isPreGtbTcProductLoading && preGtbTcProduct?.labelGrade) {
            runLabelGradeQuery({
                url: `/certificate-service/references/label-grades/${preGtbTcProduct.labelGrade}`,
            }).then((response) => setLabelGradeTypeOfMaterialId(response.typeOfMaterial));
        }
    }, [isPreGtbTcProductLoading, preGtbTcProduct, runLabelGradeQuery]);

    const canSave = useCallback(
        (item: PreGtbTcMaterialDetails) =>
            !preGtbTransactionCertificateReadOnlyByStatus(item.preGtbTcStatus) &&
            hasAccessRight("updatePreGtbTc", [item.preGtbTcStatus]),
        [hasAccessRight],
    );

    const { detailViewProps, form, isReadOnly, title, recordNavigationProps, initialData } =
        useDomainEntityDetailsEdit<PreGtbTcMaterialDetails>({
            baseUrl: `/certificate-service/pre-gtb-tcs/${preGtbTcId}/pre-gtb-products/${preGtbProductId}/pre-gtb-tc-materials/${preGtbTcMaterialId}`,
            frontendRoute: "preGtbTcMaterial",
            recordNavigationDefaultSort: defaultPreGtbTcMaterialSortOption,
            titleBuilder: (item) => item.rawMaterialPrint ?? item.rawMaterialName,
            resolver: yupResolver(buildPreGtbTcMaterialValidationSchema),
            canSave,
            historyColumns: [
                { identifier: "rawMaterial", title: "preGtbTcMaterial.history.rawMaterial_column" },
                { identifier: "rawMaterialPrint", title: "preGtbTcMaterial.history.rawMaterialPrint_column" },
                { identifier: "percentage", title: "preGtbTcMaterial.history.percentage_column" },
                { identifier: "origin", title: "preGtbTcMaterial.history.origin_column" },
            ],
        });

    const isCertified = useWatch({ control: form.form.control, name: "certified" });
    const rawMaterialPrintRequired = useWatch({ control: form.form.control, name: "rawMaterialPrintRequired" });
    const percentage = useWatch({ control: form.form.control, name: "percentage" });

    const readOnly = useMemo<PreGtbTcMaterialDetailViewProps["readOnly"]>(
        () => ({
            rawMaterial: isReadOnly,
            rawMaterialPrint: isReadOnly,
            percentage: isReadOnly,
            origin: isReadOnly,
        }),
        [isReadOnly],
    );

    useCalculatePreGtbTcMaterialCertifiedWeight(
        form,
        preGtbTcProduct,
        isCertified,
        percentage,
        isPreGtbTcProductLoading || isLabelGradeLoading,
    );

    return {
        detailViewProps: {
            ...detailViewProps,
            isLoading: detailViewProps.isLoading || isPreGtbTcProductLoading || isLabelGradeLoading,
        },
        preGtbTcId,
        form,
        readOnly,
        title,
        recordNavigationProps,
        labelGradeTypeOfMaterialId,
        rawMaterialPrintVisible: !!rawMaterialPrintRequired,
        isCertified: isCertified,
        createRoute: initialData && canSave(initialData) ? "preGtbTcMaterial" : undefined,
    };
}
