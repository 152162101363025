import Tabs, { TabKey } from "../../components/tabs/Tabs";
import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";
import EntityDetailView from "../../components/entity/EntityDetailView";
import { OrganicMaterialProductionDetails } from "../organicMaterialProductionTypes";
import { TypedDetailViewProps } from "../../components/detailView/DetailView";
import OrganicMaterialProductionBasicData from "./OrganicMaterialProductionBasicData";
import OrganicMaterialProductionAddressData from "./OrganicMaterialProductionAddressData";
import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";

export default function OrganicMaterialProductionDetailView({
                                                                form,
                                                                readOnlyProps,
                                                                subListVisible,
                                                                ...rest
                                                            }: OrganicMaterialProductionDetailViewProps) {
    useHelpContext("organic-material-production-details");

    return (
        <EntityDetailView form={form} {...rest}>
            <Tabs<OrganicMaterialProductionDetails, OrganicMaterialProductionTabs>
                tabs={[
                    {
                        heading: "organicMaterialProduction.detail.basicData_tab",
                        element: (
                            <OrganicMaterialProductionBasicData
                                form={form}
                                readOnlyProps={readOnlyProps}
                                subListVisible={subListVisible}
                            />
                        ),
                    },
                    {
                        heading: "organicMaterialProduction.detail.addressData_tab",
                        element: <OrganicMaterialProductionAddressData form={form} readOnlyProps={readOnlyProps} />,
                    },
                ]}
                errors={form.form.formState.errors}
                errorToTab={{
                    id: "internal",
                    systemId: "internal",
                    frozen: "internal",
                    organicMaterialProducerStatus: "internal",
                    organicMaterialProducer: "organicMaterialProduction.detail.basicData_tab",
                    certifiedOrganicMaterial: "organicMaterialProduction.detail.basicData_tab",
                    sizeOfProductionArea: "organicMaterialProduction.detail.basicData_tab",
                    productionEstimate: "organicMaterialProduction.detail.basicData_tab",
                    productionSold: "organicMaterialProduction.detail.basicData_tab",
                    usdaNopCompliant: "organicMaterialProduction.detail.basicData_tab",
                    town: "organicMaterialProduction.detail.addressData_tab",
                    postCode: "organicMaterialProduction.detail.addressData_tab",
                    countryArea: "organicMaterialProduction.detail.addressData_tab",
                    stateProvince: "organicMaterialProduction.detail.addressData_tab",
                    addressDetails1: "organicMaterialProduction.detail.addressData_tab",
                    addressDetails2: "organicMaterialProduction.detail.addressData_tab",
                    addressDetails3: "organicMaterialProduction.detail.addressData_tab",
                    startOfHarvestYear: "internal",
                }}
            />
        </EntityDetailView>
    );
}

type OrganicMaterialProductionTabs = TabKey<
    "organicMaterialProduction.detail.basicData_tab" | "organicMaterialProduction.detail.addressData_tab"
>;

export interface OrganicMaterialProductionDetailViewProps
    extends TypedDetailViewProps<OrganicMaterialProductionDetails> {
    readOnlyProps: OrganicMaterialProductionReadOnlyProps;
    subListVisible?: boolean;
}

export interface OrganicMaterialProductionReadOnlyProps {
    isCertifiedOrganicMaterialReadOnly: boolean;
    isUsdaNopCompliantReadOnly: boolean;
    isSizeOfProductionAreaReadOnly: boolean;
    isProductionEstimateReadOnly: boolean;
    isProductionSoldReadOnly: boolean;
    isHarvestYearReadOnly: boolean;
    isTownReadOnly: boolean;
    isPostCodeReadOnly: boolean;
    isCountryAreaReadOnly: boolean;
    isAddressDetails1ReadOnly: boolean;
    isAddressDetails2ReadOnly: boolean;
    isAddressDetails3ReadOnly: boolean;
    isProductionScReadOnly: boolean;
}

export interface OrganicMaterialProductionDetailViewTabProps {
    form: UseGtbFormReturn<OrganicMaterialProductionDetails>;
    readOnlyProps: OrganicMaterialProductionReadOnlyProps;
    subListVisible?: boolean;
}
