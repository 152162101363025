import useResolvedRoute, { getCreateUrl } from "../components/routing/useResolvedRoute";
import { CertificationRelationSummary } from "./certificationRelationTypes";
import { GridColumn } from "../components/grid/component/Grid";
import { buildTextColumn } from "../components/grid/ColumnProvider";
import useCertificationRelationSubList, { certificationRelationSubListColumns } from "./useCertificationRelationSubList";
import useIsAuthorised from "../auth/useIsAuthorised";

const columns: GridColumn<CertificationRelationSummary>[] = [
    buildTextColumn({
        key: "supplyChainOperatorBusinessName",
        title: "certificationRelation.list.supplyChainOperator_column",
        minWidth: 201,
    }),
    ...certificationRelationSubListColumns,
];
export default function useCertificationBodyCertificationRelationSubList() {
    const { getResolvedId } = useResolvedRoute();
    const { hasAccessRight } = useIsAuthorised();

    return useCertificationRelationSubList({
        columns,
        baseQueryParamKey: "certificationBodyId",
        baseQueryParamValue: getResolvedId("certificationBody"),
        tableName: "certificationBodyCertificationRelations",
        createUrl: hasAccessRight("updateOperationLevelOrganisation")
            ? getCreateUrl("certificationRelation")
            : undefined,
        sortOption: {
            field: "supplyChainOperatorBusinessName",
            direction: "ASC",
        },
    });
}
