import { yupResolver } from "@hookform/resolvers/yup";
import { useMemo } from "react";
import { OrganicMaterialOriginDetails } from "../organicMaterialOriginTypes";
import { OrganicMaterialOriginDetailViewProps } from "./OrganicMaterialOriginDetailView";
import useIsAuthorised from "../../../../auth/useIsAuthorised";
import useResolvedRoute, { routeEnum } from "../../../../components/routing/useResolvedRoute";
import useDomainEntityDetailsEdit from "../../../../hooks/details/useDomainEntityDetailsEdit";
import { defaultOrganicMaterialOriginSortOption } from "../useOrganicMaterialOriginSubList";
import { organicMaterialOriginValidationSchema } from "./organicMaterialOriginValidationSchema";
import useGtbTranslation from "../../../../i18n/useGtbTranslation";
import useConformanceCheck from "../../../../conformance/useConformanceCheck";

export default function useOrganicMaterialOriginEdit(): OrganicMaterialOriginDetailViewProps {
    const { hasAccessRight, isRoleLevelAtLeast } = useIsAuthorised();
    const { getResolvedId } = useResolvedRoute();
    const translation = useGtbTranslation();
    const organicMaterialPurchaseId = getResolvedId("organicMaterialPurchase");
    const organicMaterialOriginId = getResolvedId("organicMaterialOrigin");
    const handleConformanceCheck = useConformanceCheck<OrganicMaterialOriginDetails>();

    const { detailViewProps, initialData, form, isReadOnly, title, recordNavigationProps } =
        useDomainEntityDetailsEdit<OrganicMaterialOriginDetails>({
            baseUrl: `/certificate-service/organic-material-purchases/${organicMaterialPurchaseId}/organic-material-origins/${organicMaterialOriginId}`,
            frontendRoute: "organicMaterialOrigin",
            recordNavigationDefaultSort: defaultOrganicMaterialOriginSortOption,
            titleBuilder: (item) =>
                translation({
                    key: "organicMaterialOrigin.detail.title",
                    options: {
                        certifiedOrganicMaterialName: item.certifiedOrganicMaterialName,
                        organicMaterialPurchaseSystemId: item.organicMaterialPurchaseSystemId,
                        organicMaterialProductionSystemId: item.organicMaterialProductionSystemId,
                    },
                }),
            resolver: yupResolver(organicMaterialOriginValidationSchema),
            canSave: item => hasAccessRight("updateOperationLevelOrganisation", [item.buyerStatus]) && isRoleLevelAtLeast(3),
            historyColumns: [
                {
                    identifier: "organicMaterialProduction",
                    title: "organicMaterialOrigin.history.organicMaterialProduction_column",
                },
                {
                    identifier: "transferredQuantity",
                    title: "organicMaterialOrigin.history.transferredQuantity_column",
                },
            ],
            resolveErrors: (error, item, retrySaveAction) => handleConformanceCheck(error, item, retrySaveAction),
        });

    const readOnly = useMemo<OrganicMaterialOriginDetailViewProps["readOnly"]>(
        () => ({
            organicMaterialProducerId: true,
            organicMaterialProduction: true,
            transferredQuantity:
                isReadOnly ||
                !isRoleLevelAtLeast(3) ||
                !!initialData?.frozen ||
                !!initialData?.transferredQuantityFrozen,
            isSubEntityReadOnly: isReadOnly,
        }),
        [initialData?.frozen, initialData?.transferredQuantityFrozen, isReadOnly, isRoleLevelAtLeast],
    );

    const createRoute = useMemo<routeEnum | undefined>(() => {
        return !isReadOnly ? "organicMaterialOrigin" : undefined;
    }, [isReadOnly]);

    return {
        detailViewProps,
        form,
        readOnly,
        title,
        recordNavigationProps,
        createRoute,
        organicMaterialPurchaseId,
        subListVisible: true,
    };
}
