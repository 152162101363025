import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import { AuthorisationRoleDetails } from "../authorisationRoleTypes";
import { joinClassNames } from "../../utils/StringUtils";
import GtbTextField from "../../components/Input/GtbTextField";
import GtbNumberField from "../../components/Input/GtbNumberField";
import "./authorisationRoleBasicData.css";
import AuthorisationTypeComponent from "./components/AuthorisationTypeComponent";
import AccessRightComponent from "./components/AccessRightComponent";
import StateSpecificAccessRightComponent from "./components/StateSpecificAccessrightComponent";
import {
    bulkReportStateOptions,
    certificationLevelOrganisationStateOptions,
    operationLevelOrganisationStateOptions,
    preGtbTcStateOptions,
    scopeCertificateStateOptions,
    standardBodyStateOptions,
    transactionCertificateStateOptions,
    viewTileStateOptions,
} from "../../auth/roleTypes";
import { viewTypeType } from "../../hooks/details/useDetails";
import useGtbTranslation from "../../i18n/useGtbTranslation";
import { LabeledCheckbox } from "../../components/Input/Checkbox";
import EnumEntityRadiobuttonGroup from "../../components/Input/radiobutton/EnumEntityRadiobuttonGroup";

function AuthorisationRoleBasicData({
                                        form,
                                        readOnly,
                                        viewType,
                                        enableOrganisationTypeSelection,
                                    }: AuthorisationRoleBasicDataProps) {
    const translation = useGtbTranslation();
    return (
        <div className={joinClassNames("authorisationRoleBasicData", "formGrid")}>
            <GtbTextField
                label={"authorisationRole.detail.roleName_input"}
                readOnly={readOnly}
                {...form.registerWithErrors("name")}
                className="name"
            />
            <GtbNumberField
                label={"authorisationRole.detail.roleLevel_input"}
                readOnly={readOnly || viewType === "edit"}
                {...form.registerNumberWithErrors("level")}
                className="level"
            />
            <EnumEntityRadiobuttonGroup
                label={"authorisationRole.detail.organisationType_input"}
                readOnly={readOnly || !enableOrganisationTypeSelection}
                {...form.form.register("type")}
                className="type"
                name="type"
                referenceUrl="/certificate-service/references/organisation-types"
            />
            <LabeledCheckbox
                readOnly={readOnly}
                label="authorisationRole.detail.reduceOtpPrompting_input"
                id="canReduceOtpPrompting"
                className="canReduceOtpPrompting"
                {...form.form.register("canReduceOtpPrompting")}
            />
            <div className="authorisationTypes">
                <AuthorisationTypeComponent label={"authorisationRole.detail.userAccounts_title"}>
                    <AccessRightComponent
                        readOnly={readOnly}
                        label={"authorisationRole.detail.create_input"}
                        name="createUserAccount"
                        form={form}
                    />
                    <AccessRightComponent
                        readOnly={readOnly}
                        label={"authorisationRole.detail.read_input"}
                        name="readUserAccount"
                        form={form}
                    />
                    <AccessRightComponent
                        readOnly={readOnly}
                        label={"authorisationRole.detail.update_input"}
                        name="updateUserAccount"
                        form={form}
                    />
                    <AccessRightComponent
                        readOnly={readOnly}
                        label={"authorisationRole.detail.delete_input"}
                        name="deleteUserAccount"
                        form={form}
                    />
                </AuthorisationTypeComponent>
                <AuthorisationTypeComponent label={"authorisationRole.detail.authorisationRoles_title"}>
                    <AccessRightComponent
                        readOnly={readOnly}
                        label={"authorisationRole.detail.create_input"}
                        name="createAuthorisationRole"
                        form={form}
                    />
                    <AccessRightComponent
                        readOnly={readOnly}
                        label={"authorisationRole.detail.read_input"}
                        name="readAuthorisationRole"
                        form={form}
                    />
                    <AccessRightComponent
                        readOnly={readOnly}
                        label={"authorisationRole.detail.update_input"}
                        name="updateAuthorisationRole"
                        form={form}
                    />
                    <AccessRightComponent
                        readOnly={readOnly}
                        label={"authorisationRole.detail.delete_input"}
                        name="deleteAuthorisationRole"
                        form={form}
                    />
                </AuthorisationTypeComponent>
                <AuthorisationTypeComponent label={"authorisationRole.detail.masterData_title"}>
                    <p>{translation("authorisationRole.detail.masterData_subtitle")}</p>
                    <AccessRightComponent
                        readOnly={readOnly}
                        label={"authorisationRole.detail.create_input"}
                        name="createMasterData"
                        form={form}
                    />
                    <AccessRightComponent
                        readOnly={readOnly}
                        label={"authorisationRole.detail.read_input"}
                        name="readMasterData"
                        form={form}
                    />
                    <AccessRightComponent
                        readOnly={readOnly}
                        label={"authorisationRole.detail.update_input"}
                        name="updateMasterData"
                        form={form}
                    />
                    <AccessRightComponent
                        readOnly={readOnly}
                        label={"authorisationRole.detail.delete_input"}
                        name="deleteMasterData"
                        form={form}
                    />
                </AuthorisationTypeComponent>
                <AuthorisationTypeComponent label={"authorisationRole.detail.rawMaterialProduction_title"}>
                    <AccessRightComponent
                        label="authorisationRole.detail.dataListsInMenuSystem_input"
                        name="dataListsInMenuSystem"
                        readOnly={readOnly}
                        form={form}
                    />
                </AuthorisationTypeComponent>
                <AuthorisationTypeComponent label={"authorisationRole.detail.productConversions_title"}>
                    <p>{translation("authorisationRole.detail.productConversions_subtitle")}</p>
                    <AccessRightComponent
                        readOnly={readOnly}
                        label={"authorisationRole.detail.create_input"}
                        name="createProductConversion"
                        form={form}
                    />
                    <AccessRightComponent
                        readOnly={readOnly}
                        label={"authorisationRole.detail.read_input"}
                        name="readProductConversion"
                        form={form}
                    />
                    <AccessRightComponent
                        readOnly={readOnly}
                        label={"authorisationRole.detail.update_input"}
                        name="updateProductConversion"
                        form={form}
                    />
                    <AccessRightComponent
                        readOnly={readOnly}
                        label={"authorisationRole.detail.delete_input"}
                        name="deleteProductConversion"
                        form={form}
                    />
                </AuthorisationTypeComponent>
                <AuthorisationTypeComponent label={"authorisationRole.detail.standardBodies_title"}>
                    <StateSpecificAccessRightComponent
                        label={"authorisationRole.detail.create_input"}
                        readOnly={readOnly}
                        name={"createStandardBody"}
                        form={form}
                        options={standardBodyStateOptions}
                    />
                    <StateSpecificAccessRightComponent
                        label={"authorisationRole.detail.read_input"}
                        readOnly={readOnly}
                        name={"readStandardBody"}
                        form={form}
                        options={standardBodyStateOptions}
                    />
                    <StateSpecificAccessRightComponent
                        label={"authorisationRole.detail.update_input"}
                        readOnly={readOnly}
                        name={"updateStandardBody"}
                        form={form}
                        options={standardBodyStateOptions}
                    />
                    <StateSpecificAccessRightComponent
                        label={"authorisationRole.detail.delete_input"}
                        readOnly={readOnly}
                        name={"deleteStandardBody"}
                        form={form}
                        options={standardBodyStateOptions}
                    />
                </AuthorisationTypeComponent>
                <AuthorisationTypeComponent label={"authorisationRole.detail.certificationLevelOrganisations_title"}>
                    <p>{translation("authorisationRole.detail.certificationLevelOrganisations_subtitle")}</p>
                    <StateSpecificAccessRightComponent
                        label={"authorisationRole.detail.create_input"}
                        readOnly={readOnly}
                        name={"createCertificationLevelOrganisation"}
                        form={form}
                        options={certificationLevelOrganisationStateOptions}
                    />
                    <StateSpecificAccessRightComponent
                        label={"authorisationRole.detail.read_input"}
                        readOnly={readOnly}
                        name={"readCertificationLevelOrganisation"}
                        form={form}
                        options={certificationLevelOrganisationStateOptions}
                    />
                    <StateSpecificAccessRightComponent
                        label={"authorisationRole.detail.update_input"}
                        readOnly={readOnly}
                        name={"updateCertificationLevelOrganisation"}
                        form={form}
                        options={certificationLevelOrganisationStateOptions}
                    />
                    <StateSpecificAccessRightComponent
                        label={"authorisationRole.detail.delete_input"}
                        readOnly={readOnly}
                        name={"deleteCertificationLevelOrganisation"}
                        form={form}
                        options={certificationLevelOrganisationStateOptions}
                    />
                    <AccessRightComponent
                        readOnly={readOnly}
                        label={"authorisationRole.detail.priorConsent_input"}
                        name="grantPriorConsent"
                        form={form}
                    />
                </AuthorisationTypeComponent>
                <AuthorisationTypeComponent label={"authorisationRole.detail.operationLevelOrganisations_title"}>
                    <p>{translation("authorisationRole.detail.operationLevelOrganisations_subtitle")}</p>
                    <StateSpecificAccessRightComponent
                        label={"authorisationRole.detail.create_input"}
                        readOnly={readOnly}
                        name={"createOperationLevelOrganisation"}
                        form={form}
                        options={operationLevelOrganisationStateOptions}
                    />
                    <StateSpecificAccessRightComponent
                        label={"authorisationRole.detail.read_input"}
                        readOnly={readOnly}
                        name={"readOperationLevelOrganisation"}
                        form={form}
                        options={operationLevelOrganisationStateOptions}
                    />
                    <StateSpecificAccessRightComponent
                        label={"authorisationRole.detail.update_input"}
                        readOnly={readOnly}
                        name={"updateOperationLevelOrganisation"}
                        form={form}
                        options={operationLevelOrganisationStateOptions}
                    />
                    <StateSpecificAccessRightComponent
                        label={"authorisationRole.detail.delete_input"}
                        readOnly={readOnly}
                        name={"deleteOperationLevelOrganisation"}
                        form={form}
                        options={operationLevelOrganisationStateOptions}
                    />
                    <AccessRightComponent
                        label="authorisationRole.detail.relationTypesInMenuSystem_input"
                        name="relationTypesInMenuSystem"
                        readOnly={readOnly}
                        form={form}
                    />
                </AuthorisationTypeComponent>
                <AuthorisationTypeComponent label={"authorisationRole.detail.scEntities_title"}>
                    <p>{translation("authorisationRole.detail.scEntities_subtitle")}</p>
                    <StateSpecificAccessRightComponent
                        label={"authorisationRole.detail.create_input"}
                        readOnly={readOnly}
                        name={"createScopeCertificate"}
                        form={form}
                        options={scopeCertificateStateOptions}
                    />
                    <StateSpecificAccessRightComponent
                        label={"authorisationRole.detail.read_input"}
                        readOnly={readOnly}
                        name={"readScopeCertificate"}
                        form={form}
                        options={scopeCertificateStateOptions}
                    />
                    <StateSpecificAccessRightComponent
                        label={"authorisationRole.detail.update_input"}
                        readOnly={readOnly}
                        name={"updateScopeCertificate"}
                        form={form}
                        options={scopeCertificateStateOptions}
                    />
                    <StateSpecificAccessRightComponent
                        label={"authorisationRole.detail.delete_input"}
                        readOnly={readOnly}
                        name={"deleteScopeCertificate"}
                        form={form}
                        options={scopeCertificateStateOptions}
                    />
                </AuthorisationTypeComponent>
                <AuthorisationTypeComponent label={"authorisationRole.detail.tcEntities_title"}>
                    <p>{translation("authorisationRole.detail.tcEntities_subtitle")}</p>
                    <StateSpecificAccessRightComponent
                        label={"authorisationRole.detail.create_input"}
                        readOnly={readOnly}
                        name={"createTransactionCertificate"}
                        form={form}
                        options={transactionCertificateStateOptions}
                    />
                    <StateSpecificAccessRightComponent
                        label={"authorisationRole.detail.read_input"}
                        readOnly={readOnly}
                        name={"readTransactionCertificate"}
                        form={form}
                        options={transactionCertificateStateOptions}
                    />
                    <StateSpecificAccessRightComponent
                        label={"authorisationRole.detail.update_input"}
                        readOnly={readOnly}
                        name={"updateTransactionCertificate"}
                        form={form}
                        options={transactionCertificateStateOptions}
                    />
                    <StateSpecificAccessRightComponent
                        label={"authorisationRole.detail.delete_input"}
                        readOnly={readOnly}
                        name={"deleteTransactionCertificate"}
                        form={form}
                        options={transactionCertificateStateOptions}
                    />
                </AuthorisationTypeComponent>
                <AuthorisationTypeComponent label={"authorisationRole.detail.preGtbTcEntities_title"}>
                    <p>{translation("authorisationRole.detail.preGtbTcEntities_subtitle")}</p>
                    <StateSpecificAccessRightComponent
                        label={"authorisationRole.detail.create_input"}
                        readOnly={readOnly}
                        name={"createPreGtbTc"}
                        form={form}
                        options={preGtbTcStateOptions}
                    />
                    <StateSpecificAccessRightComponent
                        label={"authorisationRole.detail.read_input"}
                        readOnly={readOnly}
                        name={"readPreGtbTc"}
                        form={form}
                        options={preGtbTcStateOptions}
                    />
                    <StateSpecificAccessRightComponent
                        label={"authorisationRole.detail.update_input"}
                        readOnly={readOnly}
                        name={"updatePreGtbTc"}
                        form={form}
                        options={preGtbTcStateOptions}
                    />
                    <StateSpecificAccessRightComponent
                        label={"authorisationRole.detail.delete_input"}
                        readOnly={readOnly}
                        name={"deletePreGtbTc"}
                        form={form}
                        options={preGtbTcStateOptions}
                    />
                </AuthorisationTypeComponent>
                <AuthorisationTypeComponent label={"authorisationRole.detail.eventDetection_title"}>
                    <AccessRightComponent
                        readOnly={readOnly}
                        label={"authorisationRole.detail.manage_input"}
                        name="manageEventDetection"
                        form={form}
                    />
                </AuthorisationTypeComponent>
                <AuthorisationTypeComponent label={"authorisationRole.detail.systemParameters_title"}>
                    <AccessRightComponent
                        readOnly={readOnly}
                        label={"authorisationRole.detail.manage_input"}
                        name="manageSystemParameter"
                        form={form}
                    />
                </AuthorisationTypeComponent>
                <AuthorisationTypeComponent label={"authorisationRole.detail.applicationLog_title"}>
                    <AccessRightComponent
                        readOnly={readOnly}
                        label={"authorisationRole.detail.use_input"}
                        name="useApplicationLog"
                        form={form}
                    />
                </AuthorisationTypeComponent>
                <AuthorisationTypeComponent label={"authorisationRole.detail.uploadLog_title"}>
                    <AccessRightComponent
                        readOnly={readOnly}
                        label={"authorisationRole.detail.use_input"}
                        name="useUploadLog"
                        form={form}
                    />
                </AuthorisationTypeComponent>
                <AuthorisationTypeComponent label={"authorisationRole.detail.mailTemplate_title"}>
                    <AccessRightComponent
                        readOnly={readOnly}
                        label={"authorisationRole.detail.use_input"}
                        name="useMailTemplate"
                        form={form}
                    />
                </AuthorisationTypeComponent>
                <AuthorisationTypeComponent label={"authorisationRole.detail.timeBasedMail_title"}>
                    <AccessRightComponent
                        readOnly={readOnly}
                        label={"authorisationRole.detail.create_input"}
                        name="createTimeBasedMail"
                        form={form}
                    />
                    <AccessRightComponent
                        readOnly={readOnly}
                        label={"authorisationRole.detail.read_input"}
                        name="readTimeBasedMail"
                        form={form}
                    />
                    <AccessRightComponent
                        readOnly={readOnly}
                        label={"authorisationRole.detail.update_input"}
                        name="updateTimeBasedMail"
                        form={form}
                    />
                    <AccessRightComponent
                        readOnly={readOnly}
                        label={"authorisationRole.detail.delete_input"}
                        name="deleteTimeBasedMail"
                        form={form}
                    />
                </AuthorisationTypeComponent>
                <AuthorisationTypeComponent label={"authorisationRole.detail.bulkReports_title"}>
                    <StateSpecificAccessRightComponent
                        label={"authorisationRole.detail.use_input"}
                        readOnly={readOnly}
                        name={"useBulkReport"}
                        form={form}
                        options={bulkReportStateOptions}
                    />
                </AuthorisationTypeComponent>
                <AuthorisationTypeComponent label={"authorisationRole.detail.dashboardTiles_title"}>
                    <StateSpecificAccessRightComponent
                        label={"authorisationRole.detail.view_input"}
                        readOnly={readOnly}
                        name={"viewTiles"}
                        form={form}
                        options={viewTileStateOptions}
                    />
                </AuthorisationTypeComponent>
            </div>
        </div>
    );
}

export default AuthorisationRoleBasicData;

interface AuthorisationRoleBasicDataProps {
    form: UseGtbFormReturn<AuthorisationRoleDetails>;
    readOnly: boolean;
    enableOrganisationTypeSelection: boolean;
    viewType: viewTypeType;
}
