import { yupResolver } from "@hookform/resolvers/yup";
import { SupplyingRelationDetails } from "../supplyingRelationTypes";
import { supplyingRelationValidationSchema } from "./supplyingRelationValidationSchema";
import useResolvedRoute, { routeEnum } from "../../components/routing/useResolvedRoute";
import { useMemo } from "react";
import useDomainEntityDetailsEdit from "../../hooks/details/useDomainEntityDetailsEdit";
import { defaultSupplyingRelationDetailsSortOption } from "../useSupplyingRelationListView";
import { SupplyingRelationReadOnlyProps } from "./SupplyingRelationDetailView";
import useAuth from "../../auth/useAuth";
import useIsAuthorised from "../../auth/useIsAuthorised";

export default function useSupplyingRelationEdit() {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedId } = useResolvedRoute();
    const supplyingRelationId = getResolvedId("supplyingRelation");

    const {
        currentUser: { authorisationRole },
    } = useAuth();
    const isAccessLevelWriteable = useMemo(
        () => authorisationRole.type === "STANDARD_BODY" && authorisationRole.level === 2,
        [authorisationRole.level, authorisationRole.type],
    );
    const isAuthRoleReadOnly = useMemo(() => authorisationRole.level > 2, [authorisationRole]);

    const { detailViewProps, form, initialData, isReadOnly, title, recordNavigationProps } =
        useDomainEntityDetailsEdit<SupplyingRelationDetails>({
            baseUrl: "/certificate-service/supplying-relations/" + supplyingRelationId,
            resolver: yupResolver(supplyingRelationValidationSchema),
            titleBuilder: (item) =>
                `${item.supplyChainOperatorBusinessName} ${item.organicMaterialProducerBusinessName}`,
            canSave: item => hasAccessRight("updateOperationLevelOrganisation", [item.supplyChainOperatorStatus]),
            recordNavigationDefaultSort: defaultSupplyingRelationDetailsSortOption,
            frontendRoute: "supplyingRelation",
            historyColumns: [
                {
                    identifier: "supplyChainOperator",
                    title: "supplyingRelation.history.supplyChainOperator_column",
                },
                {
                    identifier: "organicMaterialProducer",
                    title: "supplyingRelation.history.organicMaterialProducer_column",
                },
                {
                    identifier: "accessLevel",
                    title: "supplyingRelation.history.accessLevel_column",
                },
                {
                    identifier: "startDate",
                    title: "supplyingRelation.history.startDate_column",
                },
                {
                    identifier: "endDate",
                    title: "supplyingRelation.history.endDate_column",
                },
            ],
        });

    const readOnlyProps: SupplyingRelationReadOnlyProps = {
        readOnly: isReadOnly,
        scoReadOnly: true,
        accessLevelReadOnly: !isAccessLevelWriteable,
        ompReadOnly: true,
        endDateReadOnly: isAuthRoleReadOnly,
    };

    const createRoute = useMemo<routeEnum | undefined>(() => {
        return hasAccessRight("updateOperationLevelOrganisation") ? "supplyingRelation" : undefined;
    }, [hasAccessRight]);

    return {
        detailViewProps,
        form,
        title,
        initialData,
        readOnlyProps,
        recordNavigationProps,
        createRoute,
    };
}
