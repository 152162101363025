import { Nullable } from "../../utils/typeUtils";
import { CertificationRelationDetails } from "../certificationRelationTypes";
import useDetailsCreate from "../../hooks/details/useDetailsCreate";
import { yupResolver } from "@hookform/resolvers/yup";
import { certificationRelationValidationSchema } from "./certificationRelationValidationSchema";
import { CertificationRelationDetailViewProps, CertificationRelationReadOnlyProps } from "./CertificationRelationDetailView";
import { useMemo } from "react";
import UseConfirmationDialog from "../../components/dialog/UseConfirmationDialog";
import { pageUrl } from "../../utils/windowFunctions";
import useAuth from "../../auth/useAuth";
import useResolvedRoute from "../../components/routing/useResolvedRoute";
import { useQuery } from "../../hooks/useAxios";
import { SupplyChainOperatorReference } from "../../supplyChainOperator/supplyChainOperatorTypes";
import useIsAuthorised from "../../auth/useIsAuthorised";

export default function useCertificationRelationCreate(): CertificationRelationDetailViewProps {
    const {
        currentUser: { authorisationRole, organisation },
    } = useAuth();
    const { isRoleLevelAtLeast, isCBAdmin, isSBAdmin } = useIsAuthorised();
    const { getResolvedId } = useResolvedRoute();
    const showRequestBusinessRelationDialog = UseConfirmationDialog();

    const currentCb = useMemo(
        () => (authorisationRole.type === "CERTIFICATION_BODY" ? organisation : null),
        [authorisationRole, organisation],
    );

    const currentSco = useMemo(
        () =>
            getResolvedId("supplyChainOperator") ??
            (authorisationRole.type === "SUPPLY_CHAIN_OPERATOR" ? organisation : null),
        [authorisationRole.type, getResolvedId, organisation],
    );

    const { data: supplyChainOperator } = useQuery<SupplyChainOperatorReference>({
        url: "/certificate-service/references/supply-chain-operators/" + currentSco,
        enabled: !!currentSco,
    });

    const initialData = useMemo<Nullable<CertificationRelationDetails> | undefined>(() => {
        return {
            id: null,
            certificationBody: currentCb,
            supplyChainOperator: currentSco,
            standard: null,
            cbLicenceNumber: null,
            accessLevel: "UNCONFIRMED",
            recertificationEmail:
                supplyChainOperator === null || supplyChainOperator === undefined
                    ? null
                    : supplyChainOperator.organisationalEmail,
            certificationBodyCc: false,
            startDate: new Date(),
            endDate: null,
            certificationBodyBusinessName: null,
            supplyChainOperatorBusinessName: null,
            supplyChainOperatorStatus: supplyChainOperator === null || supplyChainOperator === undefined
                ? null
                : supplyChainOperator.organisationStatus,
            url: pageUrl() + "/certificationRelation/%s/confirmation/%s",
        };
    }, [currentCb, currentSco, supplyChainOperator]);

    const { detailViewProps, form, title } = useDetailsCreate<CertificationRelationDetails>({
        title: "certificationRelation.detail.newRecord_title",
        frontendRoute: "certificationRelation",
        initialData,
        resolver: yupResolver(certificationRelationValidationSchema),
        saveUrl: "/certificate-service/certification-relations",
        afterCreation: (savedItem) => {
            if (savedItem.accessLevel === "UNCONFIRMED") {
                showRequestBusinessRelationDialog({
                    title: "certificationRelation.detail.businessRelationConfirmationRequested_dialogTitle",
                    message: {
                        key: "certificationRelation.detail.businessRelationConfirmationRequested_dialogText",
                        options: { SCO: savedItem.supplyChainOperatorBusinessName },
                    },
                });
            }
        },
    });

    const readOnlyProps: CertificationRelationReadOnlyProps = {
        certificationBodyReadOnly: authorisationRole.type === "CERTIFICATION_BODY",
        accessLevelReadOnly: !isSBAdmin,
        cbLicenceNumberReadOnly: !(isSBAdmin || isCBAdmin),
        recertificationEmailReadOnly: !(isRoleLevelAtLeast(4) && authorisationRole.type === "CERTIFICATION_BODY"),
        certificationBodyCcReadOnly: !(isRoleLevelAtLeast(4) && authorisationRole.type === "CERTIFICATION_BODY"),
        endDateReadOnly: !(isRoleLevelAtLeast(3) && authorisationRole.type === "SUPPLY_CHAIN_OPERATOR"),
        standardReadOnly: false,
        startDateReadOnly: false,
        supplyChainOperatorReadOnly: !!currentSco,
    };

    return {
        title,
        detailViewProps,
        form,
        createRoute: "certificationRelation",
        supplyChainOperatorPickListFlags: { allActive: true },
        readOnlyProps,
    };
}
