import InfinityScrollSubList from "../../../../components/grid/InfinityScrollSubList";
import useOrganicMaterialTransactionCertificateSubList
    from "./organicMaterialTransactionCertificate/useOrganicMaterialTransactionCertificateSubList";

export default function OrganicMaterialTransactionCertificates({ readOnly }: { readOnly: boolean }) {
    const organicMaterialTransactionCertificatesSubList = useOrganicMaterialTransactionCertificateSubList(readOnly);
    return (
        <InfinityScrollSubList
            {...organicMaterialTransactionCertificatesSubList}
            className="organicMaterialTransactionCertificate"
        />
    );
}
