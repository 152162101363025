import { SortOption } from "../components/grid/component/useQueryBuilder";
import { GridColumn } from "../components/grid/component/Grid";
import { buildDataColumn, buildNumberColumn, buildTextColumn } from "../components/grid/ColumnProvider";
import { OrganicMaterialPurchaseDetails, OrganicMaterialPurchaseSummary } from "./organicMaterialPurchaseTypes";
import useIsAuthorised from "../auth/useIsAuthorised";
import useResolvedRoute from "../components/routing/useResolvedRoute";
import useListView from "../hooks/useListView";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import { useMemo } from "react";
import { EntityListViewGridProps } from "../components/entity/EntityListView";
import { EnumEntityType } from "../components/Input/pickList/typedEnumPicklist/enumEntityType";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

export const defaultOrganicMaterialPurchaseSortOption: SortOption<
    OrganicMaterialPurchaseSummary | OrganicMaterialPurchaseDetails
> = {
    field: "systemId",
    direction: "ASC",
};

const columns: GridColumn<OrganicMaterialPurchaseSummary>[] = [
    buildTextColumn({
        key: "systemId",
        title: "organicMaterialPurchase.list.systemId_column",
        minWidth: 134,
    }),
    buildTextColumn({
        key: "buyerLegalName",
        title: "organicMaterialPurchase.list.buyer_column",
        minWidth: 123,
    }),
    buildTextColumn({
        key: "certifiedOrganicMaterialName",
        title: "organicMaterialPurchase.list.certifiedOrganicMaterial_column",
        minWidth: 251,
    }),
    buildNumberColumn({
        key: "availableQuantity",
        title: "organicMaterialPurchase.list.availableQuantity_column",
        minWidth: 227,
        fixedDecimal: true,
    }),
    buildNumberColumn({
        key: "initialQuantity",
        title: "organicMaterialPurchase.list.initialQuantity_column",
        minWidth: 199,
        fixedDecimal: true,
    }),
    buildNumberColumn({
        key: "initialAvailableQuantity",
        title: "organicMaterialPurchase.list.initialAvailableQuantity_column",
        minWidth: 375,
        fixedDecimal: true,
    }),
    buildDataColumn<OrganicMaterialPurchaseSummary, EnumEntityType>({
        key: "screeningResult",
        title: "organicMaterialPurchase.list.screeningResult_column",
        minWidth: 182,
        url: "/certificate-service/references/screening-results",
        itemId: "id",
        itemLabel: "name",
        visible: false,
    }),
    buildDataColumn<OrganicMaterialPurchaseSummary, EnumEntityType>({
        key: "rejected",
        title: "organicMaterialPurchase.list.rejected_column",
        minWidth: 123,
        url: "/certificate-service/references/rejected",
        itemId: "id",
        itemLabel: "name",
        visible: false,
    }),
];

export default function useOrganicMaterialPurchaseListView() {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedEditUrl } = useResolvedRoute();

    const {
        queryBuilder: { forceReload, columnOptions, query, queryCommands },
    } = useListView<OrganicMaterialPurchaseSummary>({
        navigationContextLabel: "organicMaterialPurchase.breadcrumb",
        baseQuery: "/certificate-service/organic-material-purchases",
        defaultSortOption: defaultOrganicMaterialPurchaseSortOption,
    });

    const showDetailsAction = useShowDetailsAction(
        (item: OrganicMaterialPurchaseSummary) => getResolvedEditUrl("organicMaterialPurchase", item.id),
        query.parameter,
    );

    const deleteAction = useDeleteAction(
        (item: OrganicMaterialPurchaseSummary) => `/certificate-service/organic-material-purchases/${item.id}`,
        () => forceReload(),
        "updateOperationLevelOrganisation",
    );

    const actions = useGridRowContextMenuActions(showDetailsAction, deleteAction);

    return useMemo<EntityListViewGridProps<OrganicMaterialPurchaseSummary>>(() => {
        return {
            query,
            columns: columns,
            uniqueDataKey: "id",
            onItemDoubleClick: showDetailsAction,
            tableName: "organicMaterialPurchase",
            columnOptions,
            itemActions: actions,
            queryCommands,
            bulkDeleteAccessRight: () => hasAccessRight("updateOperationLevelOrganisation"),
        };
    }, [query, showDetailsAction, hasAccessRight, columnOptions, actions, queryCommands]);
}
