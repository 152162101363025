import { yupResolver } from "@hookform/resolvers/yup";
import useIsAuthorised from "../../auth/useIsAuthorised";
import { useCallback, useEffect, useMemo, useState } from "react";
import useResolvedRoute from "../../components/routing/useResolvedRoute";
import useDomainEntityDetailsEdit from "../../hooks/details/useDomainEntityDetailsEdit";
import { OrganicMaterialPurchaseDetails } from "../organicMaterialPurchaseTypes";
import { defaultOrganicMaterialPurchaseSortOption } from "../useOrganicMaterialPurchaseListView";
import { OrganicMaterialPurchaseDetailViewProps } from "./OrganicMaterialPurchaseDetailView";
import { buildOrganicMaterialPurchaseValidationSchema } from "../organicMaterialPurchaseValidationSchema";
import { useWatch } from "react-hook-form";
import { useQuery } from "../../hooks/useAxios";
import useSetFormValue from "../../hooks/formHandling/useSetFormValue";

export default function useOrganicMaterialPurchaseEdit(): OrganicMaterialPurchaseDetailViewProps {
    const { hasAccessRight, isCBAdmin } = useIsAuthorised();
    const { getResolvedId } = useResolvedRoute();
    const organicMaterialPurchaseId = getResolvedId("organicMaterialPurchase");
    const [attributesRequiredAndVisible, setAttributesRequiredAndVisible] = useState<
        OrganicMaterialPurchaseDetailViewProps["visibility"]
    >({
        gmoScreeningReport: false,
        screeningResult: false,
        rejected: false,
    });

    const { runQuery: queryPurchase, isLoading: isReQueryingPurchase } = useQuery({
        url: `/certificate-service/organic-material-purchases/${organicMaterialPurchaseId}`,
        enabled: false,
    });

    const { detailViewProps, form, initialData, isReadOnly, title, recordNavigationProps } =
        useDomainEntityDetailsEdit<OrganicMaterialPurchaseDetails>({
            baseUrl: `/certificate-service/organic-material-purchases/${organicMaterialPurchaseId}`,
            frontendRoute: "organicMaterialPurchase",
            recordNavigationDefaultSort: defaultOrganicMaterialPurchaseSortOption,
            titleBuilder: (item) => item.systemId,
            resolver: yupResolver(
                buildOrganicMaterialPurchaseValidationSchema(
                    attributesRequiredAndVisible.gmoScreeningReport,
                    attributesRequiredAndVisible.screeningResult,
                    attributesRequiredAndVisible.rejected,
                ),
            ),
            canSave: item => hasAccessRight("updateOperationLevelOrganisation", [item.buyerStatus]),
            historyColumns: [
                { identifier: "systemId", title: "organicMaterialPurchase.history.systemId_column" },
                { identifier: "buyer", title: "organicMaterialPurchase.history.buyer_column" },
                {
                    identifier: "certifiedOrganicMaterial",
                    title: "organicMaterialPurchase.history.certifiedOrganicMaterial_column",
                },
                {
                    identifier: "gmoScreeningReport",
                    title: "organicMaterialPurchase.history.gmoScreeningReport_column",
                },
                { identifier: "screeningResult", title: "organicMaterialPurchase.history.screeningResult_column" },
                { identifier: "rejected", title: "organicMaterialPurchase.history.rejected_column" },
                {
                    identifier: "initialAvailableQuantity",
                    title: "organicMaterialPurchase.history.initialAvailableQuantity_column",
                },
            ],
        });

    const setFormValue = useSetFormValue(form.form);
    const currentInitialQuantity = form.form.watch("initialQuantity");

    useEffect(() => {
        if (currentInitialQuantity === 0 && form.form.formState.dirtyFields["initialAvailableQuantity"]) {
            setFormValue("initialAvailableQuantity", 0);
        }
    }, [currentInitialQuantity, form.form.formState.dirtyFields, setFormValue]);

    const readOnly = useMemo<OrganicMaterialPurchaseDetailViewProps["readOnly"]>(
        () => ({
            certifiedOrganicMaterial: true,
            gmoScreeningReport: isReadOnly,
            screeningResult: isReadOnly,
            rejected: isReadOnly,
            initialAvailableQuantity:
                isReadOnly ||
                !initialData ||
                (initialData.initialAvailableQuantity > 0 && !isCBAdmin) ||
                currentInitialQuantity === 0,
            origins: isReadOnly,
        }),
        [initialData, currentInitialQuantity, isCBAdmin, isReadOnly],
    );

    const currentScreeningResult = useWatch({ control: form.form.control, name: "screeningResult" });

    useEffect(() => {
        setAttributesRequiredAndVisible({
            gmoScreeningReport: !!initialData?.gmoScreeningReport,
            screeningResult: !!initialData?.screeningResult,
            rejected: !!initialData?.screeningResult && currentScreeningResult === "GMO_FOUND",
        });
    }, [currentScreeningResult, initialData]);

    const refetchInitialVolume = useCallback(() => {
        queryPurchase().then((purchase) =>
            form.form.resetField("initialQuantity", {
                defaultValue: purchase.initialQuantity,
            }),
        );
    }, [queryPurchase, form.form]);

    return {
        detailViewProps: {
            ...detailViewProps,
            isLoading: detailViewProps.isLoading || isReQueryingPurchase,
        },
        form,
        readOnly,
        visibility: attributesRequiredAndVisible,
        title,
        recordNavigationProps,
        gmoScreeningReportUrl: `/certificate-service/organic-material-purchases/${organicMaterialPurchaseId}/screening-report`,
        subListVisible: true,
        frozen: !!initialData?.frozen,
        refetchInitialVolume,
        initialAvailableQuantityIsSet: !!initialData && initialData?.initialAvailableQuantity > 0,
    };
}
