import { yupResolver } from "@hookform/resolvers/yup";
import useIsAuthorised from "../../auth/useIsAuthorised";
import { buildSupplyChainOperatorValidationSchema } from "./supplyChainOperatorValidationSchema";
import { ReactNode, useCallback, useMemo, useState } from "react";
import useResolvedRoute, { routeEnum } from "../../components/routing/useResolvedRoute";
import useGtbTranslation from "../../i18n/useGtbTranslation";
import useDomainEntityDetailsEdit from "../../hooks/details/useDomainEntityDetailsEdit";
import { SupplyChainOperatorDetails } from "../supplyChainOperatorTypes";
import { defaultSupplyChainOperatorSortOption } from "../useSupplyChainOperatorListView";
import { SupplyChainOperatorDetailViewProps, SupplyChainOperatorReadOnlyProps } from "./SupplyChainOperatorDetailView";
import useTypeOfRegistrationId from "../../countryArea/useTypeOfRegistrationId";
import { validateString } from "../../utils/yupExtension";
import { externalIdComponentTypeEnum } from "../../externalId/externalIdTypes";
import useAuth from "../../auth/useAuth";
import { useOrganisationDefaultReadOnlyProps } from "../../organisation/details/organisationDetailViewProps";
import useRequiresLegalNameInEnglish from "../../organisation/useRequiresLegalNameInEnglish";

export default function useSupplyChainOperatorEdit(): SupplyChainOperatorDetailViewProps {
    const translation = useGtbTranslation();
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedId } = useResolvedRoute();
    const supplyChainOperatorId = getResolvedId("supplyChainOperator");
    const {
        currentUser: { authorisationRole },
    } = useAuth();
    const [requiresLegalNameInEnglish, setRequiresLegalNameInEnglish] = useState(false);

    const canSave = useCallback(
        (item: SupplyChainOperatorDetails) => {
            if (item) {
                return hasAccessRight("updateOperationLevelOrganisation", [item.organisationStatus]);
            }
            return false;
        },
        [hasAccessRight],
    );

    const { detailViewProps, form, initialData, isReadOnly, title, recordNavigationProps } =
        useDomainEntityDetailsEdit<SupplyChainOperatorDetails>({
            baseUrl: "/certificate-service/supply-chain-operators/" + supplyChainOperatorId,
            frontendRoute: "supplyChainOperator",
            recordNavigationDefaultSort: defaultSupplyChainOperatorSortOption,
            titleBuilder: (item) => item.businessName,
            resolver: yupResolver(
                buildSupplyChainOperatorValidationSchema(requiresLegalNameInEnglish).shape({
                    mainFacility: validateString().when("isReducedAccess", {
                        is: false,
                        then: (schema) => schema.isRequired(),
                    }),
                }),
            ),
            canSave,
            historyColumns: [
                { identifier: "systemId", title: "supplyChainOperator.history.systemId_column" },
                { identifier: "legalName", title: "supplyChainOperator.history.legalName_column" },
                { identifier: "legalNameInEnglish", title: "supplyChainOperator.history.legalNameInEnglish_column" },
                { identifier: "businessName", title: "supplyChainOperator.history.businessName_column" },
                { identifier: "addressDetails1", title: "supplyChainOperator.history.addressDetail1_column" },
                { identifier: "addressDetails2", title: "supplyChainOperator.history.addressDetail2_column" },
                { identifier: "addressDetails3", title: "supplyChainOperator.history.addressDetail3_column" },
                { identifier: "latitude", title: "supplyChainOperator.history.latitude_column" },
                { identifier: "longitude", title: "supplyChainOperator.history.longitude_column" },
                { identifier: "postCode", title: "supplyChainOperator.history.postcode_column" },
                { identifier: "town", title: "supplyChainOperator.history.town_column" },
                { identifier: "stateProvince", title: "supplyChainOperator.history.stateProvince_column" },
                {
                    identifier: "organisationalEmail",
                    title: "supplyChainOperator.history.organisationalEmailAddress_column",
                },
                { identifier: "website", title: "supplyChainOperator.history.website_column" },
                {
                    identifier: "organisationalPhoneNumber",
                    title: "supplyChainOperator.history.organisationalPhoneNumber_column",
                },
                { identifier: "organisationStatus", title: "supplyChainOperator.history.status_column" },
                {
                    identifier: "publicContactPersonName",
                    title: "supplyChainOperator.history.publicContactPersonName_column",
                },
                {
                    identifier: "publicContactPersonEmail",
                    title: "supplyChainOperator.history.publicContactPersonEmail_column",
                },
                {
                    identifier: "organisationalContactPersonName",
                    title: "supplyChainOperator.history.organisationalContactPersonName_column",
                },
                {
                    identifier: "organisationalContactPersonEmail",
                    title: "supplyChainOperator.history.organisationalContactPersonEmail_column",
                },
                { identifier: "startDate", title: "supplyChainOperator.history.startDate_column" },
                { identifier: "endDate", title: "supplyChainOperator.history.endDate_column" },
                { identifier: "fixedRegistrationId", title: "supplyChainOperator.history.fixedRegistrationId_column" },
                { identifier: "mainFacility", title: "supplyChainOperator.history.mainFacility_column" },
                { identifier: "brandNames", title: "supplyChainOperator.history.brandNames_column" },
                { identifier: "membership", title: "supplyChainOperator.history.membership_column" },
            ],
        });

    const { typeOfRegistrationId, isFixedRegistrationIdReadOnly } = useTypeOfRegistrationId(form);

    const defaultReadOnlyProps = useOrganisationDefaultReadOnlyProps(isReadOnly);

    const readOnlyProps = useMemo<SupplyChainOperatorReadOnlyProps>(
        () => ({
            ...defaultReadOnlyProps,
            isFixedRegistrationIdReadOnly: isReadOnly || isFixedRegistrationIdReadOnly,
            isMainFacilityReadOnly: isReadOnly,
            isBrandNamesReadOnly: isReadOnly,
            isMembershipReadOnly: isReadOnly,
            isPublicContactPersonNameReadOnly: isReadOnly,
            isPublicContactPersonEmailReadOnly: isReadOnly,
            isOrganisationalContactPersonNameReadOnly: isReadOnly,
            isOrganisationalContactPersonEmailReadOnly: isReadOnly,
            isCbInternalIdReadOnly: isReadOnly,
            isLegalNameInEnglishReadOnly: isReadOnly,
            isSubEntityReadOnly: isReadOnly,
        }),
        [defaultReadOnlyProps, isFixedRegistrationIdReadOnly, isReadOnly],
    );

    const externalIdComponentType = useMemo<externalIdComponentTypeEnum>(() => {
        if (authorisationRole.type === "CERTIFICATION_BODY") {
            return "FIELD";
        } else if (authorisationRole.type === "SUPPLY_CHAIN_OPERATOR") {
            return "NONE";
        } else {
            return "SUBLIST";
        }
    }, [authorisationRole.type]);

    const createRoute = useMemo<routeEnum | undefined>(() => {
        return hasAccessRight("createOperationLevelOrganisation") ? "supplyChainOperator" : undefined;
    }, [hasAccessRight]);

    useRequiresLegalNameInEnglish(form, setRequiresLegalNameInEnglish);

    const reducedAccess = useMemo(() => !!initialData?.isReducedAccess, [initialData?.isReducedAccess]);

    const subtitle: ReactNode = useMemo(
        () => (
            <>
                {translation({
                    key: "supplyChainOperator.detail.subtitle",
                    options: { systemId: initialData?.systemId },
                })}
                {reducedAccess && (
                    <span className="sco__reducedAccessWarning">
                        {translation("supplyChainOperator.detail.reducedAccessWarning")}
                    </span>
                )}
            </>
        ),
        [initialData?.systemId, reducedAccess, translation],
    );

    return {
        detailViewProps: reducedAccess
            ? { ...detailViewProps, historyParams: undefined, noteRootUrl: undefined }
            : detailViewProps,
        form,
        readOnlyProps,
        reducedAccess,
        title,
        requiresLegalNameInEnglish,
        subtitle,
        recordNavigationProps,
        createRoute,
        subListVisible: true,
        typeOfRegistrationId,
        externalIdComponentType,
        supplyChainOperatorId,
    };
}
