import useIsAuthorised from "../auth/useIsAuthorised";
import { SortOption } from "../components/grid/component/useQueryBuilder";
import { useMemo } from "react";
import { GridColumn } from "../components/grid/component/Grid";
import useListView from "../hooks/useListView";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import { ProductCategoryDetails, ProductCategorySummary } from "./productCategoryTypes";
import { buildDateColumn, buildNumberColumn, buildTextColumn } from "../components/grid/ColumnProvider";
import { EntityListViewGridProps } from "../components/entity/EntityListView";
import useResolvedRoute, { getCreateUrl } from "../components/routing/useResolvedRoute";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

export const defaultProductCategorySortOption: SortOption<ProductCategorySummary | ProductCategoryDetails> = {
    field: "code",
    direction: "ASC",
};

const columns: GridColumn<ProductCategorySummary>[] = [
    buildTextColumn({ key: "code", title: "processCategory.list.code_column", minWidth: 123 }),
    buildTextColumn({ key: "name", title: "processCategory.list.name_column", minWidth: 123 }),
    buildNumberColumn({
        key: "pickListSequence",
        title: "processCategory.list.pickListSequence_column",
        minWidth: 195,
    }),
    buildDateColumn({ key: "endDate", title: "processCategory.list.endDate_column", minWidth: 124, visible: false }),
    buildDateColumn({
        key: "startDate",
        title: "processCategory.list.startDate_column",
        minWidth: 130,
        visible: false,
    }),
];

export default function useProductCategoryListView() {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedEditUrl } = useResolvedRoute();

    const {
        queryBuilder: { forceReload, query, columnOptions, queryCommands },
    } = useListView<ProductCategorySummary>({
        navigationContextLabel: "productCategory.breadcrumb",
        baseQuery: "/certificate-service/product-categories",
        defaultSortOption: defaultProductCategorySortOption,
    });

    const showDetailsAction = useShowDetailsAction(
        (item: ProductCategorySummary) => getResolvedEditUrl("productCategory", item.id),
        query.parameter,
    );

    const deleteAction = useDeleteAction(
        (item: ProductCategorySummary) => `/certificate-service/product-categories/${item.id}`,
        () => forceReload(),
        "deleteMasterData",
    );

    const actions = useGridRowContextMenuActions(showDetailsAction, deleteAction);

    return useMemo<EntityListViewGridProps<ProductCategorySummary>>(() => {
        return {
            query,
            columns: columns,
            columnOptions,
            uniqueDataKey: "id",
            onItemDoubleClick: showDetailsAction,
            createUrl: hasAccessRight("createMasterData") ? getCreateUrl("productCategory") : undefined,
            tableName: "productCategory",
            itemActions: actions,
            queryCommands,
            bulkDeleteAccessRight: "deleteMasterData",
        };
    }, [query, columnOptions, showDetailsAction, hasAccessRight, actions, queryCommands]);
}
