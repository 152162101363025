import { TypedDetailViewProps } from "../../components/detailView/DetailView";
import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import "../../components/spinner.css";
import { CertificationBodyDetails } from "../certificationBodyTypes";
import CertificationBodyBasicData from "../../certificationBody/details/CertificationBodyBasicData";
import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";
import EntityDetailView from "../../components/entity/EntityDetailView";
import Tabs, { TabKey, TabProps } from "../../components/tabs/Tabs";
import { OrganisationReadOnlyProps } from "../../organisation/details/organisationDetailViewProps";
import OrganisationAddressData from "../../organisation/details/OrganisationAddressData";
import CertificationBodyCertificationData from "./CertificationBodyCertificationData";
import CertificationBodyAccreditation from "./CertificationBodyAccreditation";
import CertificationBodyLicencingCodes from "./CertificationBodyLicencingCodes";
import CertificationBodyCertificationRelations from "./CertificationBodyCertificationRelations";
import CertificationBodyTLSCertificate from "./CertificationBodyTLSCertificate";
import { useMemo } from "react";
import { insertIf } from "../../utils/arrayUtils";
import useIsAuthorised from "../../auth/useIsAuthorised";
import CertificationBodyPriorConsentData from "./CertificationBodyPriorConsentData";
import useIsBetaEnabled from "../../hooks/useIsBetaEnabled";

export default function CertificationBodyDetailView({
                                                        detailViewProps,
                                                        form,
                                                        readOnlyProps,
                                                        urlProps,
                                                        subListVisible,
                                                        tlsTabVisible,
                                                        isTcNumberBlockSizeVisible,
                                                        ...rest
                                                    }: CertificationBodyDetailViewProps) {
    useHelpContext("certification-body-details");
    const { hasAccessRight } = useIsAuthorised();
    const isBetaEnabled = useIsBetaEnabled();

    const sublistTabs = useMemo<TabProps<CertificationBodyTabs>[]>(
        () => [
            {
                heading: "certificationBody.detail.certificationLicences_tab",
                element: <CertificationBodyLicencingCodes isReadOnly={readOnlyProps.isCertificationLicencesReadOnly} />,
            },
            {
                heading: "certificationBody.detail.accreditation_tab",
                element: <CertificationBodyAccreditation isReadOnly={readOnlyProps.isAccreditationReadOnly} />,
            },
            ...insertIf<TabProps<CertificationBodyTabs>>(hasAccessRight("readOperationLevelOrganisation"), {
                heading: "certificationBody.detail.certificationRelations_tab",
                element: <CertificationBodyCertificationRelations />,
            }),
        ],
        [hasAccessRight, readOnlyProps.isAccreditationReadOnly, readOnlyProps.isCertificationLicencesReadOnly],
    );

    const tlsTabs = useMemo<TabProps<CertificationBodyTabs>[]>(
        () => [
            {
                heading: "certificationBody.detail.tls_certificate_tab",
                element: <CertificationBodyTLSCertificate />,
            },
        ],
        [],
    );

    const priorConsentTabs = useMemo<TabProps<CertificationBodyTabs>[]>(
        () => [
            {
                heading: "certificationBody.detail.priorConsentData_tab",
                element: <CertificationBodyPriorConsentData form={form} readOnlyProps={readOnlyProps} />,
            },
        ],
        [form, readOnlyProps]
    );

    const tabs = useMemo<TabProps<CertificationBodyTabs>[]>(() => {
        return [
            {
                heading: "certificationBody.detail.basicData_tab",
                element: <CertificationBodyBasicData form={form} readOnlyProps={readOnlyProps} />,
            },
            {
                heading: "certificationBody.detail.addressData_tab",
                element: (
                    <OrganisationAddressData
                        form={form}
                        readOnlyProps={readOnlyProps}
                        organisationI18nKey={"certificationBody"}
                    />
                ),
            },
            {
                heading: "certificationBody.detail.certificationData_tab",
                element: (
                    <CertificationBodyCertificationData
                        form={form}
                        readOnlyProps={readOnlyProps}
                        urlProps={urlProps}
                        isTcNumberBlockSizeVisible={isTcNumberBlockSizeVisible}
                    />
                ),
            },
            ...(subListVisible ? sublistTabs : []),
            ...(isBetaEnabled ? priorConsentTabs : []),
            ...(tlsTabVisible ? tlsTabs : []),
        ];
    }, [
        form,
        readOnlyProps,
        urlProps,
        isTcNumberBlockSizeVisible,
        subListVisible,
        sublistTabs,
        isBetaEnabled,
        priorConsentTabs,
        tlsTabVisible,
        tlsTabs,
    ]);

    return (
        <EntityDetailView {...rest} detailViewProps={detailViewProps} form={form}>
            <Tabs<CertificationBodyDetails, CertificationBodyTabs>
                tabs={tabs}
                errors={form.form.formState.errors}
                errorToTab={{
                    id: "internal",
                    countryArea: "certificationBody.detail.addressData_tab",
                    stateProvince: "certificationBody.detail.addressData_tab",
                    systemId: "internal",
                    businessName: "certificationBody.detail.basicData_tab",
                    website: "certificationBody.detail.basicData_tab",
                    organisationalEmail: "certificationBody.detail.basicData_tab",
                    organisationalPhoneNumber: "certificationBody.detail.basicData_tab",
                    startDate: "certificationBody.detail.basicData_tab",
                    endDate: "certificationBody.detail.basicData_tab",
                    town: "certificationBody.detail.addressData_tab",
                    postCode: "certificationBody.detail.addressData_tab",
                    addressDetails1: "certificationBody.detail.addressData_tab",
                    addressDetails2: "certificationBody.detail.addressData_tab",
                    addressDetails3: "certificationBody.detail.addressData_tab",
                    latitude: "certificationBody.detail.addressData_tab",
                    longitude: "certificationBody.detail.addressData_tab",
                    organisationStatus: "certificationBody.detail.basicData_tab",
                    legalName: "certificationBody.detail.basicData_tab",
                    authorisedPerson: "certificationBody.detail.certificationData_tab",
                    scTemplate: "certificationBody.detail.certificationData_tab",
                    tcTemplate: "certificationBody.detail.certificationData_tab",
                    tcNumberBlockSize: "certificationBody.detail.certificationData_tab",
                    tlsCertificate: "certificationBody.detail.tls_certificate_tab",
                    cbsWithGrantedPriorConsent: "certificationBody.detail.priorConsentData_tab",
                }}
            />
        </EntityDetailView>
    );
}

type CertificationBodyTabs = TabKey<
    | "certificationBody.detail.basicData_tab"
    | "certificationBody.detail.addressData_tab"
    | "certificationBody.detail.certificationData_tab"
    | "certificationBody.detail.certificationLicences_tab"
    | "certificationBody.detail.accreditation_tab"
    | "certificationBody.detail.certificationRelations_tab"
    | "certificationBody.detail.tls_certificate_tab"
    | "certificationBody.detail.priorConsentData_tab"
>;

export interface CertificationBodyDetailViewProps extends TypedDetailViewProps<CertificationBodyDetails> {
    readOnlyProps: CertificationBodyReadOnlyProps;
    urlProps?: CertificationBodyUrlProps;
    subListVisible?: boolean;
    tlsTabVisible: boolean;
    isTcNumberBlockSizeVisible: boolean;
}

export interface CertificationBodyDetailViewTabProps {
    form: UseGtbFormReturn<CertificationBodyDetails>;
    readOnlyProps: CertificationBodyReadOnlyProps;
    urlProps?: CertificationBodyUrlProps;
}

export interface CertificationBodyReadOnlyProps extends OrganisationReadOnlyProps {
    isLegalNameReadOnly: boolean;
    isAuthorisedPersonReadOnly: boolean;
    isScTemplateReadOnly: boolean;
    isTcTemplateReadOnly: boolean;
    isTcNumberBlockSizeReadOnly: boolean;
    isPriorConsentReadOnly: boolean;
    isCertificationLicencesReadOnly: boolean;
    isAccreditationReadOnly: boolean;
}

export interface CertificationBodyUrlProps {
    scTemplateUrl: string;
    tcTemplateUrl: string;
}
