import { useCallback, useMemo } from "react";
import { ShipmentDetails } from "../shipmentTypes";
import { defaultShipmentSortOption } from "../useShipmentSubList";
import { ShipmentDetailViewProps } from "./ShipmentDetailView";
import { yupResolver } from "@hookform/resolvers/yup";
import { buildShipmentValidationSchema } from "./shipmentValidationSchema";
import useIsAuthorised from "../../../../auth/useIsAuthorised";
import useResolvedRoute from "../../../../components/routing/useResolvedRoute";
import useDomainEntityDetailsEdit from "../../../../hooks/details/useDomainEntityDetailsEdit";
import { transactionCertificateReadOnlyByStatus } from "../../../transactionCertificateTypes";

export default function useShipmentEdit(): ShipmentDetailViewProps {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedId } = useResolvedRoute();
    const transactionCertificateId = getResolvedId("transactionCertificate")!;
    const shipmentId = getResolvedId("shipment");

    const canSave = useCallback(
        (item: ShipmentDetails) => {
            if (item) {
                return (
                    hasAccessRight("updateTransactionCertificate", [item.tcStatus]) &&
                    !transactionCertificateReadOnlyByStatus(item.tcStatus)
                );
            }
            return false;
        },
        [hasAccessRight],
    );

    const { detailViewProps, form, isReadOnly, title, recordNavigationProps, initialData } =
        useDomainEntityDetailsEdit<ShipmentDetails>({
            baseUrl: `/certificate-service/transaction-certificates/${transactionCertificateId}/shipments/${shipmentId}`,
            frontendRoute: "shipment",
            recordNavigationDefaultSort: defaultShipmentSortOption,
            titleBuilder: (item) => item.shipmentNumber,
            resolver: yupResolver(buildShipmentValidationSchema()),
            canSave,
            historyColumns: [
                { identifier: "shipmentNumber", title: "shipment.history.shipmentNumber_column" },
                { identifier: "shipmentDate", title: "shipment.history.shipmentDate_column" },
                { identifier: "grossShippingWeight", title: "shipment.history.grossShippingWeight_column" },
                { identifier: "countryOfDestination", title: "shipment.history.countryAreaOfDestination_column" },
                { identifier: "consignee", title: "shipment.history.consignee_column" },
                {
                    identifier: "shipmentDocumentNumber",
                    title: "shipment.history.shipmentDocumentNumber_column",
                },
                { identifier: "invoiceNumbersAndDates", title: "shipment.history.invoiceNumbersAndDates_column" },
            ],
        });

    const visibility = useMemo(
        () => ({
            tcFacilityAsSellerBusinessName: !!initialData?.tcFacilityAsSellerBusinessName,
            tcFacilityAsBuyerBusinessName: !!initialData?.tcFacilityAsBuyerBusinessName,
        }),
        [initialData?.tcFacilityAsBuyerBusinessName, initialData?.tcFacilityAsSellerBusinessName],
    );

    return {
        detailViewProps,
        form,
        readOnly: isReadOnly,
        visibility,
        title,
        recordNavigationProps,
        createRoute: initialData && canSave(initialData) ? "shipment" : undefined,
        transactionCertificateId,
    };
}
