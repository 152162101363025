import useDetailsCreate from "../../hooks/details/useDetailsCreate";
import { yupResolver } from "@hookform/resolvers/yup";
import { Nullable } from "../../utils/typeUtils";
import { useCallback, useEffect, useMemo, useState } from "react";
import { OrganicMaterialPurchaseDetails } from "../organicMaterialPurchaseTypes";
import { OrganicMaterialPurchaseDetailViewProps } from "./OrganicMaterialPurchaseDetailView";
import useResolvedRoute from "../../components/routing/useResolvedRoute";
import { buildOrganicMaterialPurchaseValidationSchema } from "../organicMaterialPurchaseValidationSchema";
import { useWatch } from "react-hook-form";

const readOnly: OrganicMaterialPurchaseDetailViewProps["readOnly"] = {
    certifiedOrganicMaterial: false,
    gmoScreeningReport: false,
    screeningResult: false,
    rejected: false,
    initialAvailableQuantity: true,
    origins: true,
};

export default function useOrganicMaterialPurchaseCreate(): OrganicMaterialPurchaseDetailViewProps {
    const { getResolvedId } = useResolvedRoute();

    const parentSco = getResolvedId("supplyChainOperator")!;
    const [attributesRequiredAndVisible, setAttributesRequiredAndVisible] = useState<
        OrganicMaterialPurchaseDetailViewProps["visibility"]
    >({
        gmoScreeningReport: false,
        screeningResult: false,
        rejected: false,
    });

    const initialData = useMemo<Nullable<OrganicMaterialPurchaseDetails>>(
        () => ({
            id: null,
            systemId: null,
            buyer: parentSco,
            certifiedOrganicMaterial: null,
            availableQuantity: 0,
            initialQuantity: 0,
            gmoScreeningReport: null,
            screeningResult: null,
            rejected: null,
            frozen: null,
            initialAvailableQuantity: 0,
            buyerStatus: null,
        }),
        [parentSco],
    );

    const { detailViewProps, form, title } = useDetailsCreate<OrganicMaterialPurchaseDetails>({
        title: "organicMaterialPurchase.detail.newRecord_title",
        frontendRoute: "organicMaterialPurchase",
        initialData,
        resolver: yupResolver(
            buildOrganicMaterialPurchaseValidationSchema(
                attributesRequiredAndVisible.gmoScreeningReport,
                attributesRequiredAndVisible.screeningResult,
                attributesRequiredAndVisible.rejected,
            ),
        ),
        saveUrl: "/certificate-service/organic-material-purchases",
    });

    const currentScreeningResult = useWatch({ control: form.form.control, name: "screeningResult" });

    const onCertifiedMaterialChange = useCallback(
        (material) => {
            const isOrganicCotton = material?.code === "RM0104";
            setAttributesRequiredAndVisible({
                gmoScreeningReport: isOrganicCotton,
                screeningResult: isOrganicCotton,
                rejected: isOrganicCotton && currentScreeningResult === "GMO_FOUND",
            });
        },
        [currentScreeningResult, setAttributesRequiredAndVisible],
    );

    useEffect(() => {
        setAttributesRequiredAndVisible((prevState) => ({
            ...prevState,
            rejected: prevState.screeningResult && currentScreeningResult === "GMO_FOUND",
        }));
    }, [currentScreeningResult, setAttributesRequiredAndVisible]);

    return {
        title,
        detailViewProps,
        form,
        readOnly,
        visibility: attributesRequiredAndVisible,
        frozen: true,
        gmoScreeningReportUrl: "",
        onCertifiedMaterialChange,
        initialAvailableQuantityIsSet: false,
    };
}
