import { useDeleteAction } from "../utils/defaultActions";
import { useQueryBuilder } from "../components/grid/component/useQueryBuilder";
import useResolvedRoute from "../components/routing/useResolvedRoute";
import { OrganicMaterialProductionScSummary } from "./organicMaterialProductionScTypes";
import { defaultOrganicMaterialProductionScSortOption } from "./useOrganicMaterialProductionScListView";
import useOrganicMaterialProductionScSubList from "./useOrganicMaterialProductionScSubList";
import useIsAuthorised from "../auth/useIsAuthorised";

export default function useOrganicMaterialProducerOrganicMaterialProductionScSubList() {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedId, getResolvedCreateUrl } = useResolvedRoute();

    const { query, columnOptions, forceReload } = useQueryBuilder<OrganicMaterialProductionScSummary>(
        `/certificate-service/organic-material-production-scs`,
        {
            sort: defaultOrganicMaterialProductionScSortOption,
            filterActive: false,
            organicMaterialProducerId: getResolvedId("organicMaterialProducer"),
        },
    );

    const deleteAction = useDeleteAction(
        (item: OrganicMaterialProductionScSummary) => `/certificate-service/organic-material-production-scs/${item.id}`,
        forceReload,
        "updateOperationLevelOrganisation",
    );

    return useOrganicMaterialProductionScSubList({
        query,
        columnOptions,
        deleteDetachContextMenuAction: deleteAction,
        createUrl: hasAccessRight("updateOperationLevelOrganisation")
            ? getResolvedCreateUrl("organicMaterialProducerScopeCertificate")
            : undefined,
        tableName: "organicMaterialProducerOrganicMaterialProductionSc",
    });
}
