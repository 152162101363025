import { AuthorisationRoleDetails } from "../authorisationRoleTypes";
import { AuthorisationRoleDetailViewProps } from "./AuthorisationRoleDetailView";
import { yupResolver } from "@hookform/resolvers/yup";
import { authorisationRoleValidationSchema } from "./authorisationRoleValidationSchema";
import useIsAuthorised from "../../auth/useIsAuthorised";
import { defaultAuthorisationRoleSortOption } from "../useAuthorisationRoleListView";
import useResolvedRoute, { routeEnum } from "../../components/routing/useResolvedRoute";
import useDomainEntityDetailsEdit from "../../hooks/details/useDomainEntityDetailsEdit";
import { useCallback, useMemo } from "react";
import useGtbTranslation, { I18nKey } from "../../i18n/useGtbTranslation";

function useAuthorisationRoleEdit(): AuthorisationRoleDetailViewProps {
    const translation = useGtbTranslation();
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedId } = useResolvedRoute();
    const authorisationRoleId = getResolvedId("authorisationRole");

    const concatTranslations = useCallback(
        (key1: I18nKey, key2: I18nKey) => {
            return translation(key1) + " " + translation(key2);
        },
        [translation]
    );

    const { detailViewProps, form, isReadOnly, viewType, title, recordNavigationProps } =
        useDomainEntityDetailsEdit<AuthorisationRoleDetails>({
            baseUrl: "/certificate-service/roles/" + authorisationRoleId,
            resolver: yupResolver(authorisationRoleValidationSchema),
            frontendRoute: "authorisationRole",
            recordNavigationDefaultSort: defaultAuthorisationRoleSortOption,
            titleBuilder: (item) => item["name"],
            canSave: hasAccessRight("updateAuthorisationRole"),
            historyColumns: [
                { identifier: "name", title: "authorisationRole.history.roleName_column" },
                {
                    identifier: "canReduceOtpPrompting",
                    title: "authorisationRole.history.reduceOtpPrompting_column",
                },
                {
                    identifier: "createAuthorisationRole",
                    title: concatTranslations(
                        "authorisationRole.history.create_columnPrefix",
                        "authorisationRole.history.authorisationRoles_column"
                    ) as I18nKey,
                },
                {
                    identifier: "readAuthorisationRole",
                    title: concatTranslations(
                        "authorisationRole.history.read_columnPrefix",
                        "authorisationRole.history.authorisationRoles_column"
                    ) as I18nKey,
                },
                {
                    identifier: "updateAuthorisationRole",
                    title: concatTranslations(
                        "authorisationRole.history.update_columnPrefix",
                        "authorisationRole.history.authorisationRoles_column"
                    ) as I18nKey,
                },
                {
                    identifier: "deleteAuthorisationRole",
                    title: concatTranslations(
                        "authorisationRole.history.delete_columnPrefix",
                        "authorisationRole.history.authorisationRoles_column"
                    ) as I18nKey,
                },
                {
                    identifier: "createCertificationLevelOrganisation",
                    title: concatTranslations(
                        "authorisationRole.history.create_columnPrefix",
                        "authorisationRole.history.certificationLevelOrganisations_column"
                    ) as I18nKey,
                },
                {
                    identifier: "readCertificationLevelOrganisation",
                    title: concatTranslations(
                        "authorisationRole.history.read_columnPrefix",
                        "authorisationRole.history.certificationLevelOrganisations_column"
                    ) as I18nKey,
                },
                {
                    identifier: "updateCertificationLevelOrganisation",
                    title: concatTranslations(
                        "authorisationRole.history.update_columnPrefix",
                        "authorisationRole.history.certificationLevelOrganisations_column"
                    ) as I18nKey,
                },
                {
                    identifier: "deleteCertificationLevelOrganisation",
                    title: concatTranslations(
                        "authorisationRole.history.delete_columnPrefix",
                        "authorisationRole.history.certificationLevelOrganisations_column"
                    ) as I18nKey,
                },
                {
                    identifier: "grantPriorConsent",
                    title: "authorisationRole.history.priorConsent_column",
                },
                {
                    identifier: "createMasterData",
                    title: concatTranslations(
                        "authorisationRole.history.create_columnPrefix",
                        "authorisationRole.history.masterData_column"
                    ) as I18nKey,
                },
                {
                    identifier: "readMasterData",
                    title: concatTranslations(
                        "authorisationRole.history.read_columnPrefix",
                        "authorisationRole.history.masterData_column"
                    ) as I18nKey,
                },
                {
                    identifier: "updateMasterData",
                    title: concatTranslations(
                        "authorisationRole.history.update_columnPrefix",
                        "authorisationRole.history.masterData_column"
                    ) as I18nKey,
                },
                {
                    identifier: "deleteMasterData",
                    title: concatTranslations(
                        "authorisationRole.history.delete_columnPrefix",
                        "authorisationRole.history.masterData_column"
                    ) as I18nKey,
                },
                {
                    identifier: "createProductConversion",
                    title: concatTranslations(
                        "authorisationRole.history.create_columnPrefix",
                        "authorisationRole.history.productConversions_column"
                    ) as I18nKey,
                },
                {
                    identifier: "readProductConversion",
                    title: concatTranslations(
                        "authorisationRole.history.read_columnPrefix",
                        "authorisationRole.history.productConversions_column"
                    ) as I18nKey,
                },
                {
                    identifier: "updateProductConversion",
                    title: concatTranslations(
                        "authorisationRole.history.update_columnPrefix",
                        "authorisationRole.history.productConversions_column"
                    ) as I18nKey,
                },
                {
                    identifier: "deleteProductConversion",
                    title: concatTranslations(
                        "authorisationRole.history.delete_columnPrefix",
                        "authorisationRole.history.productConversions_column"
                    ) as I18nKey,
                },
                {
                    identifier: "createScopeCertificate",
                    title: concatTranslations(
                        "authorisationRole.history.create_columnPrefix",
                        "authorisationRole.history.scEntities_column"
                    ) as I18nKey,
                },
                {
                    identifier: "readScopeCertificate",
                    title: concatTranslations(
                        "authorisationRole.history.read_columnPrefix",
                        "authorisationRole.history.scEntities_column"
                    ) as I18nKey,
                },
                {
                    identifier: "updateScopeCertificate",
                    title: concatTranslations(
                        "authorisationRole.history.update_columnPrefix",
                        "authorisationRole.history.scEntities_column"
                    ) as I18nKey,
                },
                {
                    identifier: "deleteScopeCertificate",
                    title: concatTranslations(
                        "authorisationRole.history.delete_columnPrefix",
                        "authorisationRole.history.scEntities_column"
                    ) as I18nKey,
                },
                {
                    identifier: "createStandardBody",
                    title: concatTranslations(
                        "authorisationRole.history.create_columnPrefix",
                        "authorisationRole.history.standardBodies_column"
                    ) as I18nKey,
                },
                {
                    identifier: "readStandardBody",
                    title: concatTranslations(
                        "authorisationRole.history.read_columnPrefix",
                        "authorisationRole.history.standardBodies_column"
                    ) as I18nKey,
                },
                {
                    identifier: "updateStandardBody",
                    title: concatTranslations(
                        "authorisationRole.history.update_columnPrefix",
                        "authorisationRole.history.standardBodies_column"
                    ) as I18nKey,
                },
                {
                    identifier: "deleteStandardBody",
                    title: concatTranslations(
                        "authorisationRole.history.delete_columnPrefix",
                        "authorisationRole.history.standardBodies_column"
                    ) as I18nKey,
                },
                {
                    identifier: "createOperationLevelOrganisation",
                    title: concatTranslations(
                        "authorisationRole.history.create_columnPrefix",
                        "authorisationRole.history.operationLevelOrganisations_column"
                    ) as I18nKey,
                },
                {
                    identifier: "readOperationLevelOrganisation",
                    title: concatTranslations(
                        "authorisationRole.history.read_columnPrefix",
                        "authorisationRole.history.operationLevelOrganisations_column"
                    ) as I18nKey,
                },
                {
                    identifier: "updateOperationLevelOrganisation",
                    title: concatTranslations(
                        "authorisationRole.history.update_columnPrefix",
                        "authorisationRole.history.operationLevelOrganisations_column"
                    ) as I18nKey,
                },
                {
                    identifier: "deleteOperationLevelOrganisation",
                    title: concatTranslations(
                        "authorisationRole.history.delete_columnPrefix",
                        "authorisationRole.history.operationLevelOrganisations_column"
                    ) as I18nKey,
                },
                {
                    identifier: "dataListsInMenuSystem",
                    title: "authorisationRole.history.dataListsInMenuSystem_column",
                },
                {
                    identifier: "createTransactionCertificate",
                    title: concatTranslations(
                        "authorisationRole.history.create_columnPrefix",
                        "authorisationRole.history.tcEntities_column"
                    ) as I18nKey,
                },
                {
                    identifier: "readTransactionCertificate",
                    title: concatTranslations(
                        "authorisationRole.history.read_columnPrefix",
                        "authorisationRole.history.tcEntities_column"
                    ) as I18nKey,
                },
                {
                    identifier: "updateTransactionCertificate",
                    title: concatTranslations(
                        "authorisationRole.history.update_columnPrefix",
                        "authorisationRole.history.tcEntities_column"
                    ) as I18nKey,
                },
                {
                    identifier: "deleteTransactionCertificate",
                    title: concatTranslations(
                        "authorisationRole.history.delete_columnPrefix",
                        "authorisationRole.history.tcEntities_column"
                    ) as I18nKey,
                },
                {
                    identifier: "createPreGtbTc",
                    title: concatTranslations(
                        "authorisationRole.history.create_columnPrefix",
                        "authorisationRole.history.preGtbTcEntities_column"
                    ) as I18nKey,
                },
                {
                    identifier: "readPreGtbTc",
                    title: concatTranslations(
                        "authorisationRole.history.read_columnPrefix",
                        "authorisationRole.history.preGtbTcEntities_column"
                    ) as I18nKey,
                },
                {
                    identifier: "updatePreGtbTc",
                    title: concatTranslations(
                        "authorisationRole.history.update_columnPrefix",
                        "authorisationRole.history.preGtbTcEntities_column"
                    ) as I18nKey,
                },
                {
                    identifier: "deletePreGtbTc",
                    title: concatTranslations(
                        "authorisationRole.history.delete_columnPrefix",
                        "authorisationRole.history.preGtbTcEntities_column"
                    ) as I18nKey,
                },
                {
                    identifier: "createUserAccount",
                    title: concatTranslations(
                        "authorisationRole.history.create_columnPrefix",
                        "authorisationRole.history.userAccounts_column"
                    ) as I18nKey,
                },
                {
                    identifier: "readUserAccount",
                    title: concatTranslations(
                        "authorisationRole.history.read_columnPrefix",
                        "authorisationRole.history.userAccounts_column"
                    ) as I18nKey,
                },
                {
                    identifier: "updateUserAccount",
                    title: concatTranslations(
                        "authorisationRole.history.update_columnPrefix",
                        "authorisationRole.history.userAccounts_column"
                    ) as I18nKey,
                },
                {
                    identifier: "deleteUserAccount",
                    title: concatTranslations(
                        "authorisationRole.history.delete_columnPrefix",
                        "authorisationRole.history.userAccounts_column"
                    ) as I18nKey,
                },
                {
                    identifier: "manageEventDetection",
                    title: concatTranslations(
                        "authorisationRole.history.manage_columnPrefix",
                        "authorisationRole.history.eventDetection_column"
                    ) as I18nKey,
                },
                {
                    identifier: "manageSystemParameter",
                    title: concatTranslations(
                        "authorisationRole.history.manage_columnPrefix",
                        "authorisationRole.history.systemParameters_column"
                    ) as I18nKey,
                },
                {
                    identifier: "useApplicationLog",
                    title: concatTranslations(
                        "authorisationRole.history.use_columnPrefix",
                        "authorisationRole.history.applicationLog_column"
                    ) as I18nKey,
                },
                {
                    identifier: "useUploadLog",
                    title: concatTranslations(
                        "authorisationRole.history.use_columnPrefix",
                        "authorisationRole.history.uploadLog_column"
                    ) as I18nKey,
                },
                {
                    identifier: "useMailTemplate",
                    title: concatTranslations(
                        "authorisationRole.history.use_columnPrefix",
                        "authorisationRole.history.mailTemplate_column"
                    ) as I18nKey,
                },
                {
                    identifier: "createTimeBasedMail",
                    title: concatTranslations(
                        "authorisationRole.history.create_columnPrefix",
                        "authorisationRole.history.timeBasedMails_column"
                    ) as I18nKey,
                },
                {
                    identifier: "readTimeBasedMail",
                    title: concatTranslations(
                        "authorisationRole.history.read_columnPrefix",
                        "authorisationRole.history.timeBasedMails_column"
                    ) as I18nKey,
                },
                {
                    identifier: "updateTimeBasedMail",
                    title: concatTranslations(
                        "authorisationRole.history.update_columnPrefix",
                        "authorisationRole.history.timeBasedMails_column"
                    ) as I18nKey,
                },
                {
                    identifier: "deleteTimeBasedMail",
                    title: concatTranslations(
                        "authorisationRole.history.delete_columnPrefix",
                        "authorisationRole.history.timeBasedMails_column"
                    ) as I18nKey,
                },
                {
                    identifier: "useBulkReport",
                    title: concatTranslations(
                        "authorisationRole.history.use_columnPrefix",
                        "authorisationRole.history.bulkReports_column"
                    ) as I18nKey,
                },
                {
                    identifier: "viewTiles",
                    title: concatTranslations(
                        "authorisationRole.history.view_columnPrefix",
                        "authorisationRole.history.dashboardTiles_column"
                    ) as I18nKey,
                },
                { identifier: "level", title: "authorisationRole.history.roleLevel_column" },
                { identifier: "type", title: "authorisationRole.history.roleType_column" },
            ],
        });

    const createRoute = useMemo<routeEnum | undefined>(() => {
        return hasAccessRight("createAuthorisationRole") ? "authorisationRole" : undefined;
    }, [hasAccessRight]);

    return {
        detailViewProps,
        form,
        isReadOnly,
        viewType,
        title,
        recordNavigationProps,
        createRoute,
        enableOrganisationTypeSelection: false,
    };
}

export default useAuthorisationRoleEdit;
