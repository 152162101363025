import useDetailsCreate from "../../hooks/details/useDetailsCreate";
import { yupResolver } from "@hookform/resolvers/yup";
import { Nullable } from "../../utils/typeUtils";
import { ProcessCategoryDetails } from "../processCategoryTypes";
import { processCategoryValidationSchema } from "./processCategoryValidationSchema";
import { getInitialTranslatableValue } from "../../i18n/languageTypes";
import { ProcessCategoryDetailViewProps } from "./ProcessCategoryDetailView";

const initialData: Nullable<ProcessCategoryDetails> = {
    code: null,
    id: null,
    name: getInitialTranslatableValue(),
    pickListSequence: null,
    startDate: null,
    endDate: null,
};

export default function useProcessCategoryCreate(): ProcessCategoryDetailViewProps {
    const { detailViewProps, form, title } = useDetailsCreate<ProcessCategoryDetails>({
        title: "processCategory.detail.newRecord_title",
        frontendRoute: "processCategory",
        initialData,
        resolver: yupResolver(processCategoryValidationSchema),
        saveUrl: "/certificate-service/process-categories",
    });

    return {
        title,
        detailViewProps,
        form,
        isReadOnly: false,
        createRoute: "processCategory",
    };
}
