import useIsAuthorised from "../../../auth/useIsAuthorised";
import { useQuery } from "../../../hooks/useAxios";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { StandardDetails } from "../../standardTypes";
import { VersionDetails } from "../versionTypes";
import { versionValidationSchema } from "./versionValidationSchema";
import { defaultVersionSortOption } from "../useVersionSublist";
import { VersionDetailViewProps } from "./VersionDetailView";
import { useMemo } from "react";
import useResolvedRoute, { routeEnum } from "../../../components/routing/useResolvedRoute";
import useDomainEntityDetailsEdit from "../../../hooks/details/useDomainEntityDetailsEdit";

export default function useVersionEdit(): VersionDetailViewProps {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedId } = useResolvedRoute();
    const standardId = getResolvedId("standard");
    const standardVersionId = getResolvedId("standardVersion");

    const { data: standard } = useQuery<StandardDetails>({
        url: "/certificate-service/standards/" + standardId + "?view=MINIMISED",
    });

    const { detailViewProps, form, initialData, isReadOnly, title, recordNavigationProps } =
        useDomainEntityDetailsEdit<VersionDetails>({
            baseUrl: "/certificate-service/standards/" + standardId + "/standard-versions/" + standardVersionId,
            resolver: yupResolver(versionValidationSchema),
            frontendRoute: "standardVersion",
            recordNavigationDefaultSort: defaultVersionSortOption,
            titleBuilder: (item) => item.versionNumber,
            canSave: hasAccessRight("updateMasterData"),
            historyColumns: [
                { identifier: "systemId", title: "standardVersion.history.systemId_column" },
                { identifier: "standard", title: "standardVersion.history.standard_column" },
                { identifier: "versionNumber", title: "standardVersion.history.versionNumber_column" },
                {
                    identifier: "defaultValidityPeriod",
                    title: "standardVersion.history.defaultValidityPeriod_column",
                },
                {
                    identifier: "recertificationPeriod",
                    title: "standardVersion.history.recertificationPeriod_column",
                },
                {
                    identifier: "recertificationBindingPeriod",
                    title: "standardVersion.history.recertificationBindingPeriod_column",
                },
                { identifier: "rawMaterialsText", title: "standardVersion.history.rawMaterials_column" },
                { identifier: "processingText", title: "standardVersion.history.processing_column" },
                { identifier: "hideUsdaNopCompliance", title: "standardVersion.history.usdaNop_column" },
                { identifier: "cugsText", title: "standardVersion.history.cugs_column" },
                { identifier: "logo", title: "standardVersion.history.logo_column" },
                { identifier: "startDate", title: "standardVersion.history.startDate_column" },
                { identifier: "endDate", title: "standardVersion.history.endDate_column" },
            ],
        });

    const createRoute = useMemo<routeEnum | undefined>(() => {
        return hasAccessRight("updateMasterData") ? "standardVersion" : undefined;
    }, [hasAccessRight]);

    return useMemo(() => {
        return {
            labelGradesVisible: true,
            readOnly: isReadOnly,
            standard: `${standard?.acronym}`,
            detailViewProps,
            form,
            title,
            subtitle: initialData?.systemId,
            logoUrl:
                "/certificate-service/standards/" + standardId + "/standard-versions/" + standardVersionId + "/logo",
            versionNumberReadOnly: true,
            recordNavigationProps,
            createRoute,
        };
    }, [
        detailViewProps,
        form,
        initialData?.systemId,
        isReadOnly,
        recordNavigationProps,
        standard?.acronym,
        standardId,
        title,
        standardVersionId,
        createRoute,
    ]);
}
