import useResolvedRoute from "../components/routing/useResolvedRoute";
import { buildTextColumn } from "../components/grid/ColumnProvider";
import useSubcontractingRelationSubList from "./useSubcontractingRelationSubList";

export default function useSubcontractorsSubList(readOnly: boolean) {
    const { getResolvedId, getResolvedCreateUrl } = useResolvedRoute();

    const firstColumn = buildTextColumn({
        key: "subcontractorSupplyChainOperatorBusinessName",
        title: "subcontractingRelation.list.subcontractor_column",
        minWidth: 166,
    });

    const { ...subcontractorsListOptions } = useSubcontractingRelationSubList({
        firstColumn,
        baseQueryParamKey: "principalSupplyChainOperatorId",
        baseQueryParamValue: getResolvedId("supplyChainOperator"),
        header: "subcontractingRelation.list.subcontractors_header",
        tableName: "subcontractors",
        createUrl: getResolvedCreateUrl("supplyChainOperatorSubcontractingRelation"),
        sortOption: {
            field: "subcontractorSupplyChainOperatorBusinessName",
            direction: "ASC",
        },
        readOnly,
    });

    return subcontractorsListOptions;
}
