import { yupResolver } from "@hookform/resolvers/yup";
import useIsAuthorised from "../../auth/useIsAuthorised";
import { CertificationRelationDetails } from "../certificationRelationTypes";
import { certificationRelationValidationSchema } from "./certificationRelationValidationSchema";
import useResolvedRoute, { routeEnum } from "../../components/routing/useResolvedRoute";
import { useMemo } from "react";
import useDomainEntityDetailsEdit from "../../hooks/details/useDomainEntityDetailsEdit";
import { defaultCertificationRelationSortOption } from "../useCertificationRelationListView";
import { CertificationRelationReadOnlyProps } from "./CertificationRelationDetailView";
import useAuth from "../../auth/useAuth";

export default function useCertificationRelationEdit() {
    const { hasAccessRight } = useIsAuthorised();
    const {
        currentUser: { authorisationRole },
    } = useAuth();
    const { isRoleLevelAtLeast, isCBAdmin, isSBAdmin } = useIsAuthorised();
    const { getResolvedId } = useResolvedRoute();
    const certificationRelationId = getResolvedId("certificationRelation");

    const { detailViewProps, form, initialData, isReadOnly, title, recordNavigationProps } =
        useDomainEntityDetailsEdit<CertificationRelationDetails>({
            baseUrl: "/certificate-service/certification-relations/" + certificationRelationId,
            resolver: yupResolver(certificationRelationValidationSchema),
            frontendRoute: "certificationRelation",
            recordNavigationDefaultSort: defaultCertificationRelationSortOption,
            titleBuilder: (item) => `${item.certificationBodyBusinessName} ${item.supplyChainOperatorBusinessName}`,
            canSave: item => hasAccessRight("updateOperationLevelOrganisation", [item.supplyChainOperatorStatus]),
            historyColumns: [
                {
                    identifier: "certificationBodyBusinessName",
                    title: "certificationRelation.history.certificationBody_column",
                },
                {
                    identifier: "supplyChainOperatorBusinessName",
                    title: "certificationRelation.history.supplyChainOperator_column",
                },
                {
                    identifier: "standard",
                    title: "certificationRelation.history.standard_column",
                },
                {
                    identifier: "cbLicenceNumber",
                    title: "certificationRelation.history.cbLicenceNumber_column",
                },
                {
                    identifier: "accessLevel",
                    title: "certificationRelation.history.accessLevel_column",
                },
                {
                    identifier: "recertificationEmail",
                    title: "certificationRelation.history.recertificationEmail_column",
                },
                {
                    identifier: "certificationBodyCc",
                    title: "certificationRelation.history.certificationBodyCc_column",
                },
                {
                    identifier: "startDate",
                    title: "certificationRelation.history.startDate_column",
                },
                {
                    identifier: "endDate",
                    title: "certificationRelation.history.endDate_column",
                },
            ],
        });

    const readOnlyProps: CertificationRelationReadOnlyProps = {
        certificationBodyReadOnly: true,
        accessLevelReadOnly: isReadOnly || !isSBAdmin,
        cbLicenceNumberReadOnly: isReadOnly || !(isSBAdmin || isCBAdmin),
        recertificationEmailReadOnly:
            isReadOnly || !(isRoleLevelAtLeast(4) && authorisationRole.type === "CERTIFICATION_BODY"),
        certificationBodyCcReadOnly:
            isReadOnly || !(isRoleLevelAtLeast(4) && authorisationRole.type === "CERTIFICATION_BODY"),
        endDateReadOnly: isReadOnly || !(isRoleLevelAtLeast(3) && authorisationRole.type === "SUPPLY_CHAIN_OPERATOR"),
        standardReadOnly: true,
        startDateReadOnly: isReadOnly || !(isRoleLevelAtLeast(3) && authorisationRole.type === "SUPPLY_CHAIN_OPERATOR"),
        supplyChainOperatorReadOnly: true,
    };

    const createRoute = useMemo<routeEnum | undefined>(() => {
        return initialData && hasAccessRight("updateOperationLevelOrganisation", [initialData.supplyChainOperatorStatus]) ? "certificationRelation" : undefined;
    }, [hasAccessRight, initialData]);

    return {
        detailViewProps,
        form,
        title,
        initialData,
        readOnlyProps,
        recordNavigationProps,
        createRoute,
    };
}
