import { CertificationLicenceDetails } from "../certificationLicenceTypes";
import { yupResolver } from "@hookform/resolvers/yup";
import { certificationLicenceValidationSchema } from "./certificationLicenceValidationSchema";
import { useQuery } from "../../hooks/useAxios";
import useIsAuthorised from "../../auth/useIsAuthorised";
import { defaultCertificationLicenceSortOption } from "../useCertificationLicenceSubList";
import useResolvedRoute, { routeEnum } from "../../components/routing/useResolvedRoute";
import { useMemo } from "react";
import useDomainEntityDetailsEdit from "../../hooks/details/useDomainEntityDetailsEdit";
import { CertificationBodyReference } from "../../certificationBody/certificationBodyTypes";
import { CertificationLicenceReadOnlyProps } from "./CertificationLicenceDetailView";

export default function useCertificationLicenceEdit() {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedId } = useResolvedRoute();
    const certificationBodyId = getResolvedId("certificationBody");
    const certificationLicenceId = getResolvedId("certificationLicence");

    const { data: certificationBody } = useQuery<CertificationBodyReference>({
        url: "/certificate-service/references/certification-bodies/" + certificationBodyId,
    });

    const { detailViewProps, form, isReadOnly, title, recordNavigationProps } =
        useDomainEntityDetailsEdit<CertificationLicenceDetails>({
            baseUrl:
                "/certificate-service/certification-bodies/" +
                certificationBodyId +
                "/certification-licences/" +
                certificationLicenceId,
            resolver: yupResolver(certificationLicenceValidationSchema),
            frontendRoute: "certificationLicence",
            recordNavigationDefaultSort: defaultCertificationLicenceSortOption,
            titleBuilder: (item) => item.licencingCode,
            canSave: certificationBody && hasAccessRight("updateCertificationLevelOrganisation", [certificationBody.organisationStatus]),
            historyColumns: [
                { identifier: "standard", title: "certificationLicence.history.standard_column" },
                { identifier: "licencingCode", title: "certificationLicence.history.licencingCode_column" },
                { identifier: "startDate", title: "certificationLicence.history.startDate_column" },
                { identifier: "endDate", title: "certificationLicence.history.endDate_column" },
            ],
        });

    const readOnlyProps: CertificationLicenceReadOnlyProps = {
        isStandardReadOnly: true,
        isLicencingCodeReadOnly: isReadOnly,
        isStartDateReadOnly: isReadOnly,
        isEndDateReadOnly: isReadOnly,
    };

    const createRoute = useMemo<routeEnum | undefined>(() => {
        return certificationBody && hasAccessRight("updateCertificationLevelOrganisation", [certificationBody.organisationStatus]) ? "certificationLicence" : undefined;
    }, [hasAccessRight, certificationBody]);

    return {
        detailViewProps,
        form,
        readOnlyProps,
        title,
        certificationBodyName: `${certificationBody?.businessName}`,
        recordNavigationProps,
        createRoute,
    };
}
